import { Component, Input } from '@angular/core';
import { ClubWithPulse } from '../../core/interfaces/club-with-activities.interface';
import { DateService } from '../../core/services/date.service';

@Component({
    selector: 'app-stats-month-km',
    templateUrl: './stats-month-km.component.html',
    styleUrls: ['./stats-month-km.component.scss'],
})
export class StatsMonthKmComponent {
    @Input() public monthList: number[];
    @Input() public club: ClubWithPulse;
    @Input() public goalKm: number;

    public constructor(private dateService: DateService) {}

    public getMonthName(month: number) {
        return this.dateService.getMonthName(month);
    }

    public getMonthDistance(month: number) {
        return this.club.activityPulse
            ? this.club.activityPulse
                  .filter(a => new Date(a.date).getMonth() === month)
                  .reduce((sum, a) => sum + a.totalDistance, 0)
            : 0;
    }

    public calculateBarWidth(month: number) {
        const distance = this.getMonthDistance(month) / 1000;
        return (distance / this.goalKm) * 100;
    }
}
