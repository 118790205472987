<div [ngClass]="{ 'is-open': mobileNavbarOpen }" class="header">
    <div class="header__logo">
        <a routerLink="/">
            <svg viewBox="0 0 45 42" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path
                        class="fill-accent"
                        d="M2.958 39.313h8.693l8.421-29.749 1.148-.027.979-.016 1.35-.014c.593-.005 1.223-.007 1.888-.008h.403c2.348 0 3.8.156 5.078.7 1.804.77 2.802 2.269 2.802 4.483 0 2.243-1.23 3.84-3.336 4.753-1.507.654-3.418.95-5.607.975l-.3.001v-2.186c4.455 0 7.056-1.129 7.056-3.543 0-2.362-1.488-2.997-5.693-2.997l-.792.001c-.516.001-1.01.004-1.48.008l-1.832.02L13.305 41.5H0L12.579.5h16.26c9.573 0 15.456 4.78 15.456 13.819 0 4.482-2.242 8.029-6.055 10.445-2.955 1.873-6.747 2.944-9.988 3.007l-.302.003H23.5l3.59 11.085h7.794l-2.295-4.563 1.953-.982 3.89 7.732H25.499l-5.007-15.458h7.458c2.901 0 6.432-.968 9.12-2.671 3.216-2.039 5.038-4.92 5.038-8.598 0-7.643-4.719-11.554-13.016-11.631l-.252-.001H14.195L2.958 39.312z"
                        fill="#02364D"
                        fill-rule="nonzero"
                    />
                    <path
                        class="fill-bg"
                        d="M36.008 33.65a2.908 2.908 0 11-5.486-1.933 2.908 2.908 0 015.486 1.932"
                        fill="#FFF"
                    />
                    <path
                        d="M29.49 31.352a4.002 4.002 0 107.55 2.66 4.002 4.002 0 00-7.55-2.66zm4.378-.382a1.815 1.815 0 11-1.206 3.424 1.815 1.815 0 011.206-3.424z"
                        fill="#5BDDB8"
                        fill-rule="nonzero"
                    />
                    <path
                        class="fill-bg"
                        d="M29.703 19.126a2.908 2.908 0 11-5.816 0 2.908 2.908 0 015.816 0"
                        fill="#FFF"
                    />
                    <path
                        d="M26.795 15.124a4.002 4.002 0 100 8.005 4.002 4.002 0 000-8.005zm0 2.187a1.815 1.815 0 110 3.63 1.815 1.815 0 010-3.63z"
                        fill="#5BDDB8"
                        fill-rule="nonzero"
                    />
                </g>
            </svg>
        </a>
    </div>

    <button (click)="openMobileNavbar()" [ngClass]="{ 'is-active': mobileNavbarOpen }" class="navbar__toggle">
        <svg height="30px" viewBox="0 0 24 20" width="30px" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd" stroke="none" stroke-linecap="round" stroke-width="1">
                <g stroke="#02364D" stroke-width="2" transform="translate(-8.000000, -10.000000)">
                    <line id="topline" x1="9.5" x2="30.5" y1="11.68201" y2="11.68201"></line>
                    <line id="middleline" x1="9.5" x2="30.5" y1="20" y2="20"></line>
                    <line id="bottomline" x1="9.5" x2="30.5" y1="28.31799" y2="28.31799"></line>
                </g>
            </g>
        </svg>
    </button>

    <ng-content></ng-content>
</div>
