import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { finalize } from 'rxjs';
import { LoaderService } from '../core/services/loader.service';
import { NotificationService } from '../core/services/notification.service';
import { MailService } from '../core/services/mail.service';
import { AnalyticsService } from '../core/services/analytics.service';
import { testimonials } from './testimonials';

@Component({
    selector: 'app-saas-lp',
    templateUrl: './saas-lp.component.html',
    styleUrls: ['./saas-lp.component.scss'],
})
export class SaasLpComponent implements AfterViewInit {
    public mobileNavIsOpen = false;
    public email: string;
    public optin = false;
    public isModalOpen = true;
    public testimonials = testimonials;

    @ViewChild('video') public videoElement: ElementRef;

    public constructor(
        private readonly mailService: MailService,
        private readonly loaderService: LoaderService,
        private readonly notificationService: NotificationService,
        @Inject('storage') private storage: Storage,
        @Inject('window') private window: any,
        private readonly analyticsService: AnalyticsService,
    ) {
        const didRedirectAlready: string | null = this.storage.getItem('rr_redirectCharity');
        this.isModalOpen = !didRedirectAlready;
    }

    public ngAfterViewInit() {
        this.videoElement.nativeElement.pause();
        this.videoElement.nativeElement.muted = true;
        this.videoElement.nativeElement.play();
    }

    public sendSalesForm() {
        if (this.email !== '' || !this.optin) {
            this.mailService
                .requestSalesPDF(this.email)
                .pipe(finalize(() => this.loaderService.hideLoader()))
                .subscribe({
                    next: () => {
                        this.notificationService.success('SaasLP.PDFRequestSuccessful');
                    },
                    error: () => {
                        this.notificationService.error('SaasLP.PDFRequestFailed');
                    },
                });
        }
    }

    public closeModal() {
        this.isModalOpen = false;
    }

    public redirectCharity() {
        this.isModalOpen = false;
        this.storage.setItem('rr_redirectCharity', 'true');
        this.analyticsService.trackEvent({
            type: 'EVENT',
            action: 'redirect-charity',
            category: 'saas-lp',
            label: 'Redirect from SaaS LP to Charity',
        });
    }
}
