import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-total-facts',
    templateUrl: './total-facts.component.html',
    styleUrls: ['./total-facts.component.scss'],
})
export class TotalFactsComponent {
    @Input() public amountAthletes: number;
    @Input() public amountActivities: number;
    @Input() public totalKm: number;
    @Input() public donation: number;
}
