import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TenantService } from '../core/services/tenant.service';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
    public constructor(
        private tenantService: TenantService,
        @Inject('env') private env: any,
    ) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.includes(this.env.api)) {
            const newRequest = request.clone({
                setHeaders: { 'tenant-id': this.tenantService.tenant.id.toString() },
            });
            return next.handle(newRequest);
        } else {
            return next.handle(request);
        }
    }
}
