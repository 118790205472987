<div class="global-goal">
    <ng-content></ng-content>

    <div class="goal">
        <div class="goal__chart">
            <div class="goal__bar" [ngStyle]="{ 'width.%': getPercentage() }">
                <div class="goal__status">{{ status / 1000 | number: '1.0-0' }} km</div>
            </div>
            <div
                class="goal__interim-goals"
                *ngFor="let goal of interimGoals"
                [ngStyle]="{ left: goal.left + '%' }"
                [ngClass]="{ checked: goal.reached }"
            >
                <div class="text">
                    <span translate>{{ 'AllClubs.Goals.' + goal.text }}</span>
                    <br />
                    <span class="distance">({{ goal.distance | number: '1.0-0' }} km)</span>
                </div>
                <div class="dot">
                    <span class="checkmark" *ngIf="goal.reached">
                        <app-icon name="checkmark"></app-icon>
                    </span>
                </div>
                <div class="needle"></div>
            </div>
        </div>

        <div class="goal__image">
            <img [src]="'/assets/images/' + imageName" alt="Bild" />
            <p>{{ goal | number: '1.0-0' }} km</p>
        </div>
    </div>
</div>
