<app-header [(mobileNavIsOpen)]="mobileNavIsOpen">
    <app-saas-menu [(mobileNavbarOpen)]="mobileNavIsOpen"></app-saas-menu>
</app-header>

<!--<app-modal (onCloseModal)="closeModal()" *ngIf="isModalOpen" [maxWidth]="'700px'">-->
<!--    <h1>{{ 'SaasLP.Modal.Headline' | translate }}</h1>-->
<!--    <h3>{{ 'SaasLP.Modal.Subline' | translate }}</h3>-->
<!--    <p [innerHTML]="'SaasLP.Modal.Text' | translate"></p>-->
<!--    <a (click)="redirectCharity()" class="button" href="https://charity.remoterun.de" target="_self">-->
<!--        {{ 'SaasLP.Modal.Button' | translate }}-->
<!--    </a>-->
<!--</app-modal>-->

<div class="stage">
    <video #video autoPlay loop muted playsInline poster="assets/images/luke-porter-NEqEC7qa9FM-unsplash.jpg">
        <source src="assets/video/remoterun-activities.mp4" type="video/mp4" />
    </video>
    <div class="wrapper">
        <div class="content row">
            <div class="col-5">
                <h2>{{ 'SaasLP.Stage.Headline' | translate }}</h2>
                <h3>
                    {{ 'SaasLP.Stage.Subline' | translate }}
                </h3>
            </div>
        </div>
    </div>

    <div class="form" id="hs_form">
        <h4>{{ 'SaasLP.HeadlineForm' | translate }}</h4>
        <form (submit)="sendSalesForm()">
            <div class="form__item">
                <label for="email">{{ 'Login.EMailAddress' | translate }}</label>
                <input [(ngModel)]="email" id="email" name="email" type="text" />
            </div>

            <div class="form__item form-element form-element--checkbox">
                <input [(ngModel)]="optin" id="accept" name="accept" type="checkbox" />
                <label for="accept">
                    {{ 'SaasLP.DataGuidelinesOptIn' | translate }}
                    <br />
                    <small>
                        <a routerLink="/legal/dataprivacy" target="_blank">
                            <span [innerHTML]="'SaasLP.DataGuidelines' | translate"></span>
                        </a>
                    </small>
                </label>
            </div>

            <button class="button" type="submit">
                {{ 'SaasLP.RequestPDF' | translate }}
            </button>
        </form>
    </div>
</div>

<section>
    <app-donut [width]="300" circleColor="#FFFFFF" class="donut-1 rellax" data-rellax-speed="-1"></app-donut>
    <app-donut [width]="120" circleColor="#F2F5F6" class="donut-2 rellax" data-rellax-speed="1"></app-donut>
    <div class="wrapper">
        <div class="content row">
            <div class="image-text">
                <div class="image-text__content">
                    <h2>{{ 'SaasLP.Advantage1.Headline' | translate }}</h2>
                    <p>{{ 'SaasLP.Advantage1.Text' | translate }}</p>
                </div>
                <div class="image-text__image">
                    <img
                        src="assets/images/remoterun-gesundheit-foerdern.png"
                        srcset="
                            assets/images/remoterun-gesundheit-foerdern.png,
                            assets/images/remoterun-gesundheit-foerdern@2x.png 2x
                        "
                    />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="claims">
    <div class="wrapper">
        <div class="content row">
            <div class="image-text">
                <div class="image-text__image">
                    <img
                        src="assets/images/remoterun-zusammenbewegen.png"
                        srcset="
                            assets/images/remoterun-zusammenbewegen.png,
                            assets/images/remoterun-zusammenbewegen@2x.png 2x
                        "
                    />
                </div>
                <div class="image-text__content">
                    <h2>{{ 'SaasLP.Advantage2.Headline' | translate }}</h2>
                    <p>{{ 'SaasLP.Advantage2.Text' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="bg-porcellain advantage-list">
    <app-donut [width]="512" circleColor="#FFFFFF" class="donut-3 rellax" data-rellax-speed="-1"></app-donut>
    <div class="wrapper">
        <div class="content row">
            <div class="image-text">
                <div class="image-text__image">
                    <img
                        src="assets/images/remoterun-screens.png"
                        srcset="
                            assets/images/remoterun-screens.png,
                            assets/images/remoterun-screens@2x.png 2x
                        "
                    />
                </div>
                <div class="image-text__content list">
                    <h2>{{ 'SaasLP.AdvantageList.Headline' | translate }}</h2>
                    <ul>
                        <li [innerHTML]="'SaasLP.AdvantageList.P2' | translate"></li>
                        <li [innerHTML]="'SaasLP.AdvantageList.P3' | translate"></li>
                        <li [innerHTML]="'SaasLP.AdvantageList.P4' | translate"></li>
                        <li [innerHTML]="'SaasLP.AdvantageList.P5' | translate"></li>
                        <li [innerHTML]="'SaasLP.AdvantageList.P1' | translate"></li>
                        <li [innerHTML]="'SaasLP.AdvantageList.P6' | translate"></li>
                        <li [innerHTML]="'SaasLP.AdvantageList.P7' | translate"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="testimonials" class="testimonials">
    <h2>{{ 'SaasLP.Testimonials.Headline' | translate }}</h2>
    <app-slider [showArrows]="true" [slidesL]="3" [slidesM]="2" [slidesS]="1">
        <app-slide *ngFor="let testimonial of testimonials">
            <app-testimonial [testimonial]="testimonial"></app-testimonial>
        </app-slide>
    </app-slider>
</section>

<app-footer></app-footer>
