<div class="stats__ranking">
    <div class="stats__ranking__legend-wrapper">
        <div class="stats__ranking__legend">
            <div class="legend__place">{{ 'Common.Rank' | translate }}</div>
            <div
                class="legend__distance legend--sortable"
                [ngClass]="{ 'legend--active': sort === 'distance' }"
                (click)="sortBy('distance')"
            >
                {{ 'Common.TotalDistance' | translate }}
                <span>
                    <app-icon name="caret"></app-icon>
                </span>
            </div>
            <div
                class="legend__team legend--sortable"
                [ngClass]="{ 'legend--active': sort === 'team' }"
                (click)="sortBy('team')"
            >
                {{ 'Common.Size' | translate }}
                <span>
                    <app-icon name="caret"></app-icon>
                </span>
            </div>
            <div
                class="legend__index legend--sortable"
                [ngClass]="{ 'legend--active': sort === 'index' }"
                (click)="sortBy('index')"
            >
                {{ 'Common.Index' | translate }}
                <span>
                    <app-icon name="caret"></app-icon>
                </span>
            </div>
            <div class="legend__donation">{{ 'Common.Amount' | translate }}</div>
        </div>
    </div>

    <div class="stats__ranking__row" *ngFor="let club of getSortedRanking(); let i = index">
        <span>{{ i + 1 }}</span>
        <app-ranking-row
            [club]="club"
            [barValue]="getBarValue(club)"
            [barMax]="getBarMax()"
        ></app-ranking-row>
    </div>
</div>
