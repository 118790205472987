import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-activity-filter',
    templateUrl: './activity-filter.component.html',
    styleUrls: ['./activity-filter.component.scss'],
})
export class ActivityFilterComponent {
    @Output() public filterChange = new EventEmitter<string[]>();

    public types = [
        { name: 'Run', key: 'Run', icon: 'run' },
        { name: 'Walk', key: 'Walk', icon: 'walk' },
        { name: 'Hike', key: 'Hike', icon: 'hike' },
        { name: 'Ride', key: 'Ride', icon: 'ride' },
        { name: 'Swim', key: 'Swim', icon: 'swim' },
    ];
    private filter = new Set<string>();

    public toggleType(type: string) {
        if (this.filter.has(type)) {
            this.filter.delete(type);
        } else {
            this.filter.add(type);
        }
        this.emit();
    }

    public emit() {
        this.filterChange.emit(Array.from(this.filter));
    }
}
