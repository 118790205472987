import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { UserFE } from '../interfaces/user.interface';
import { map, switchMap } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';
import { UserRegistrationInterface } from '../interfaces/user-registration.interface';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public loggedIn = new BehaviorSubject(false);
    public user = new BehaviorSubject<UserFE>({} as UserFE);

    public setLoggedIn(loggedIn: boolean) {
        this.loggedIn.next(loggedIn);
    }

    public setUser(user: UserFE) {
        this.user.next(user);
    }

    public currentUser() {
        return this.user.getValue();
    }

    public constructor(
        private httpClient: HttpClient,
        @Inject('env') private env: any,
    ) {}

    public getUser(): Observable<{ user: UserFE }> {
        return this.httpClient.get<any>(this.env.api + '/user', { observe: 'response' }).pipe(
            switchMap(res => {
                if (res.status === 204) {
                    return of({ user: 204 });
                } else {
                    this.setUser(res.body.user);
                    return of(res.body);
                }
            }),
        );
    }

    public getUserByCognitoId(): Observable<UserFE | number> {
        return this.getUser().pipe(
            switchMap((value: { user: UserFE }) => {
                if (value.user) {
                    this.setLoggedIn(true);
                }
                return of(value.user);
            }),
        );
    }

    public createUser(user: UserRegistrationInterface) {
        return this.httpClient.post(this.env.api + '/user', user);
    }

    public updateUser(user: Partial<UserFE>): Observable<any> {
        return this.httpClient.put(this.env.api + '/user', user).pipe(
            switchMap(() => this.getUser()),
            map((u: { user: UserFE }) => this.setUser(u.user)),
        );
    }

    public changePassword(oldPassword: string, newPassword: string) {
        return from(
            Auth.currentAuthenticatedUser().then(user =>
                Auth.changePassword(user, oldPassword, newPassword).catch(e =>
                    console.error('Fehler beim ändern des Passwortes.', e),
                ),
            ),
        );
    }

    public removeUser(): Observable<any> {
        return this.httpClient.delete(this.env.api + '/user').pipe(
            map(result => {
                this.setLoggedIn(false);
                return from(
                    Auth.signOut()
                        .catch(e => console.error('fehler beim signout:', e))
                        .then(() => result),
                );
            }),
        );
    }

    public updateGuidedTourForUser(version: number): Observable<any> {
        return this.httpClient.put(this.env.api + '/user/guided-tour', version).pipe(
            switchMap(() => this.getUser()),
            map(() => {
                const user = this.user.getValue();
                user.lastSeenGuidedTour = version;

                this.user.next(user);
                return version;
            }),
        );
    }

    public updatePrivacyConfirmation(): Observable<void> {
        return this.httpClient.put<void>(this.env.api + '/user/privacy-confirmation', {});
    }

    public getUserState(email: string): Observable<any> {
        return this.httpClient.post(this.env.api + '/user/code-status', {
            email: email,
        });
    }
}
