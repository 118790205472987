<div class="legal">
    <h1>Impressum</h1>

    <div class="whitebox whitebox-padding">
        <h2>Angaben gemäß § 5 TMG&nbsp;</h2>
        <p>
            mediaman GmbH<br />
            Taunusstr. 57<br />
            55118 Mainz

            <br /><br />
            Vertreten durch: Armin Bieser, Christoph Edelmann, Nina Schubert, Jan Born<br />
            Telefon: +49 6131 2120-0<br />
            E-Mail: <a href="mailto:hallo@mediaman.de" title="hallo@mediaman.de">hallo&#64;mediaman.de</a>
        </p>
        <p class="copy-small">Registergericht: Amtsgericht Mainz 14, Registernummer: HRB 6152&nbsp;</p>
        <p class="copy-small">
            Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 185195865&nbsp;
        </p>
        <p class="copy-small">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: Armin Bieser&nbsp;</p>
        <h2 class="headline-default">Haftungsausschluss (Disclaimer)&nbsp;</h2>
        <h3 class="headline-small">Haftung für Inhalte&nbsp;</h3>
        <p class="copy-small">
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
            nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
            Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
            Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
            hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
            konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
            wir diese Inhalte umgehend entfernen.&nbsp;
        </p>
        <h3 class="headline-small">Haftung für Links&nbsp;</h3>
        <p class="copy-small">
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
            haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte
            der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
            Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
            inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
            Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
            Links umgehend entfernen.&nbsp;
        </p>
        <h3 class="headline-small">Urheberrecht&nbsp;</h3>
        <p class="copy-small">
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
            deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
            außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
            Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
            Inhalte umgehend entfernen.&nbsp;
        </p>
    </div>
</div>
