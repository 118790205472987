<div class="intro__text">
    <h1>{{ club.name }}</h1>
    <p>{{ club.motivation }}</p>
</div>
<div class="intro__edit">
    <!-- button copy invite link -->
    <button (click)="copyInviteLink()" [title]="'InvitationBanner.CopyLink' | translate" *ngIf="userForClub">
        <svg viewBox="0 0 36 32" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(0.750000, 1.250000)" fill-rule="nonzero">
                    <g transform="translate(-0.500000, -0.500000)">
                        <path
                            d="M26,12 C20.7528576,12 16.5,16.2528576 16.5,21.5 C16.5,26.7461476 20.7533628,31 26,31 C31.2466372,31 35.5,26.7461476 35.5,21.5 C35.5,16.2528576 31.2471424,12 26,12 Z M26,13 C30.6948576,13 34.5,16.8051424 34.5,21.5 C34.5,26.1938886 30.6943267,30 26,30 C21.3056733,30 17.5,26.1938886 17.5,21.5 C17.5,16.8051424 21.3051424,13 26,13 Z"
                            id="Stroke-550"
                            fill="#5BDFB8"
                        ></path>
                        <path
                            d="M29.75,21 C30.0261424,21 30.25,21.2238576 30.25,21.5 C30.25,21.7454599 30.0731248,21.9496084 29.8398756,21.9919443 L29.75,22 L22.25,22 C21.9738576,22 21.75,21.7761424 21.75,21.5 C21.75,21.2545401 21.9268752,21.0503916 22.1601244,21.0080557 L22.25,21 L29.75,21 Z"
                            id="Stroke-551"
                            fill="#5BDFB8"
                        ></path>
                        <path
                            d="M26,17.25 C26.2454599,17.25 26.4496084,17.4268752 26.4919443,17.6601244 L26.5,17.75 L26.5,25.25 C26.5,25.5261424 26.2761424,25.75 26,25.75 C25.7545401,25.75 25.5503916,25.5731248 25.5080557,25.3398756 L25.5,25.25 L25.5,17.75 C25.5,17.4738576 25.7238576,17.25 26,17.25 Z"
                            id="Stroke-552"
                            fill="#5BDFB8"
                        ></path>
                        <path
                            d="M29.75,0 C31.211466,0 32.4083253,1.14325711 32.4949768,2.58265017 L32.5,2.75 L32.5,9.5 C32.5,9.77614237 32.2761424,10 32,10 C31.7545401,10 31.5503916,9.82312484 31.5080557,9.58987563 L31.5,9.5 L31.5,2.75 C31.5,1.83331927 30.7917978,1.07895693 29.8933634,1.00581239 L29.75,1 L2.75,1 C1.83297604,1 1.0789226,1.7085283 1.00580982,2.6066855 L1,2.75 L1,19.25 C1,20.1684487 1.70717432,20.9212199 2.6064824,20.9942009 L2.75,21 L14,21 C14.2761424,21 14.5,21.2238576 14.5,21.5 C14.5,21.7454599 14.3231248,21.9496084 14.0898756,21.9919443 L14,22 L2.75,22 C1.28763574,22 0.0916081345,20.8586598 0.00501948357,19.4175628 L0,19.25 L0,2.75 C0,1.28908039 1.14273099,0.0917151457 2.58259171,0.00502542863 L2.75,0 L29.75,0 Z"
                            id="Stroke-553"
                            fill="#02364D"
                        ></path>
                        <path
                            d="M30.9376525,0.859565595 C31.1532834,0.687060821 31.4679296,0.722021509 31.6404344,0.937652476 C31.793772,1.12932445 31.7831862,1.39923067 31.6274963,1.57799912 L31.5623475,1.6404344 L16.25,13.8903124 L0.937652476,1.6404344 C0.722021509,1.46792963 0.687060821,1.15328344 0.859565595,0.937652476 C1.01290317,0.745980505 1.27855001,0.69706036 1.48713411,0.809711154 L1.56234752,0.859565595 L16.25,12.609 L30.9376525,0.859565595 Z"
                            id="Stroke-554"
                            fill="#02364D"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    </button>
    <a
        *ngIf="admin"
        routerLink="/club-management/users"
        (click)="activateLoader()"
        [title]="'ClubManagement.ManageClubMembers' | translate"
    >
        <svg width="28px" height="28px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g
                transform="translate(1, -4)"
                stroke="currentColor"
                fill="none"
                fill-rule="evenodd"
                stroke-linejoin="round"
            >
                <path
                    d="M11,6.5 C11,7.881 9.881,9 8.5,9 C7.119,9 6,7.881 6,6.5 C6,5.119 7.119,4 8.5,4 C9.881,4 11,5.119 11,6.5 L11,6.5 Z"
                    id="Stroke-3978-Copy-3"
                ></path>
                <path
                    d="M17,7.5 C17,8.328 16.328,9 15.5,9 C14.672,9 14,8.328 14,7.5 C14,6.672 14.672,6 15.5,6 C16.328,6 17,6.672 17,7.5 L17,7.5 Z"
                    id="Stroke-3979-Copy-3"
                ></path>
                <path
                    d="M3,7.5 C3,8.328 2.328,9 1.5,9 C0.672,9 0,8.328 0,7.5 C0,6.672 0.672,6 1.5,6 C2.328,6 3,6.672 3,7.5 L3,7.5 Z"
                    id="Stroke-3980-Copy-3"
                ></path>
                <path
                    d="M11,20 L6,20 L6,13.5 C6,12.119 7.119,11 8.5,11 C9.881,11 11,12.119 11,13.5 L11,20 L11,20 Z"
                    id="Stroke-3982-Copy-3"
                ></path>
                <path
                    d="M17,18 L14,18 L14,12.5 C14,11.672 14.672,11 15.5,11 C16.328,11 17,11.672 17,12.5 L17,18 L17,18 Z"
                    id="Stroke-3983-Copy-3"
                ></path>
                <path
                    d="M3,18 L0,18 L0,12.5 C0,11.672 0.672,11 1.5,11 C2.328,11 3,11.672 3,12.5 L3,18 L3,18 Z"
                    id="Stroke-3984-Copy-3"
                ></path>
            </g>
        </svg>
    </a>
    <a
        *ngIf="admin"
        routerLink="/club-management"
        (click)="activateLoader()"
        [title]="'ClubManagement.ManageClub' | translate"
    >
        <app-icon name="settings"></app-icon>
    </a>
</div>
<div class="intro__base-stats">
    <div>
        <app-stats-facts4
            [value]="club.userCount || 0"
            [label]="'ClubIntro.Users' | translate"
        ></app-stats-facts4>
    </div>
    <div>
        <app-stats-facts3
            [value]="club.totalActivities"
            [label]="'Common.Activities' | translate"
        ></app-stats-facts3>
    </div>
    <div>
        <app-stats-facts3
            [value]="club.totalKm | distance"
            unit="KM"
            [label]="'Common.TotalDistance' | translate"
        ></app-stats-facts3>
    </div>
    <div>
        <app-stats-facts3
            [value]="club.totalDonation"
            unit="EUR"
            [label]="'Common.TotalDonationAmount' | translate"
            theme="mint"
        ></app-stats-facts3>
    </div>
</div>
