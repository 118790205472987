import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StravaAuthData } from '../interfaces/strava-auth-data.interface';
import { UserService } from './user.service';

@Injectable()
export class StravaService {
    private _stravaAuthData: StravaAuthData;

    public constructor(
        private httpClient: HttpClient,
        @Inject('env') private env: any,
        private userService: UserService,
    ) {}

    public get stravaAuthData() {
        return this._stravaAuthData;
    }

    public set stravaAuthData(stravaAuthData: StravaAuthData) {
        this._stravaAuthData = stravaAuthData;
    }

    public sendStravaCodeToBackend(): Observable<any> {
        const data = {
            code: this.stravaAuthData.token,
            scope: this.stravaAuthData.scope,
        };
        return this.httpClient
            .post(this.env.api + '/strava/connect-athlete', data)
            .pipe(switchMap(() => this.userService.getUser()));
    }

    public disconnectFromStrava(): Observable<any> {
        return this.httpClient
            .post(this.env.api + '/strava/disconnect-athlete', {
                deleteAllActivities: false,
            })
            .pipe(switchMap(() => this.userService.getUser()));
    }

    public importAllActivities(): Observable<any> {
        return this.httpClient.put(this.env.api + '/strava/activities', null);
    }

    public openStravaLogin(): void {
        window.open(
            `https://www.strava.com/oauth/authorize?client_id=${this.env.stravaClientId}&redirect_uri=${this.env.stravaOAuthRedirectUri}&response_type=code&scope=read,activity:read,activity:read_all&state=strava&approval_prompt=force`,
            '_self',
        );
    }
}
