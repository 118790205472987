import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-guided-tour-slide',
    templateUrl: './guided-tour-slide.component.html',
    styleUrls: ['./guided-tour-slide.component.scss'],
})
export class GuidedTourSlideComponent {
    @Input() public slide: any;
    @Input() public isLast: boolean;

    @Output() public tourVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

    public close() {
        this.tourVisible.emit(false);
    }
}
