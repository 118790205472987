<footer class="page-footer">
    <div class="page-footer__content">
        <p><a href="https://www.remoterun.de" target="_blank">remoterun.de</a> / mediaman GmbH / Mainz</p>
        <nav class="page-footer__links">
            <a class="page-footer__link" routerLink="/legal/imprint">{{ 'Layout.Imprint' | translate }}</a>
            <a class="page-footer__link" routerLink="/legal/dataprivacy">{{
                'Layout.DataPrivacy' | translate
            }}</a>
            <a class="page-footer__link" routerLink="/legal/terms-of-use">{{
                'Layout.TermsOfUse' | translate
            }}</a>
        </nav>
        <p>
            <img
                alt="Compatible with Strava"
                src="/assets/images/api_logo_cptblWith_strava_stack_light.png"
                width="100"
            />
        </p>
    </div>
</footer>
