import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivityFE } from '../interfaces/activity.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ActivityService {
    private _activityToEdit: ActivityFE;

    public set activityToEdit(activity: ActivityFE) {
        this._activityToEdit = activity;
    }

    public get activityToEdit(): ActivityFE {
        return this._activityToEdit;
    }

    public constructor(
        private httpClient: HttpClient,
        @Inject('env') private env: any,
    ) {}

    public getAllActivitiesForUser(): Observable<ActivityFE[]> {
        return this.httpClient
            .get<ActivityFE[]>(`${this.env.api}/user/activities`)
            .pipe(
                map((activities: ActivityFE[]) =>
                    activities.sort(
                        (a: ActivityFE, b: ActivityFE) =>
                            new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
                    ),
                ),
            );
    }

    public createManualActivity(activity: ActivityFE): Observable<any> {
        return this.httpClient.post(this.env.api + '/user/activities', activity);
    }

    public removeActivity(activityId: number): Observable<any> {
        return this.httpClient.delete(this.env.api + '/user/activities/' + activityId);
    }

    public updateManualActivity(activity: ActivityFE) {
        return this.httpClient.put(this.env.api + '/user/activities', activity);
    }
}
