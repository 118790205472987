import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActivityFE } from '../interfaces/activity.interface';
import { AllTimeTotals } from '../interfaces/all-time-totals.interface';
import { ClubBase } from '../interfaces/club-base.interface';
import { ClubHero } from '../interfaces/club-hero.interface';
import { ClubManagementData } from '../interfaces/club-management.interface';
import { ClubRegistration } from '../interfaces/club-registration.interface';
import {
    ClubDataActivitiesPulseTotals,
    ClubDataPulseTotals,
    ClubWithPulse,
} from '../interfaces/club-with-activities.interface';
import { Club } from '../interfaces/club.interface';
import { DBSuccessResponse } from '../interfaces/DB-success-response.interface';
import { Team } from '../interfaces/team.interface';
import { UserFE } from '../interfaces/user.interface';
import { UserRegistrationInterface } from '../interfaces/user-registration.interface';

@Injectable({
    providedIn: 'root',
})
export class ClubService {
    public constructor(
        private httpClient: HttpClient,
        @Inject('env') private env: any,
    ) {}

    public postClubRegistration(clubRegistration: {
        club: ClubRegistration;
        user: UserRegistrationInterface;
    }): Observable<{ clubId: number; userId: number }> {
        return this.httpClient.post<{ clubId: number; userId: number }>(
            this.env.api + '/clubs',
            clubRegistration,
        );
    }

    public getAllClubs(): Observable<Club[]> {
        return this.httpClient.get<Club[]>(this.env.api + '/clubs');
    }

    public getAllActivitiesForClub(): Observable<ClubDataActivitiesPulseTotals> {
        return this.httpClient.get<ClubDataActivitiesPulseTotals>(`${this.env.api}/club/activities`).pipe(
            map((club: ClubDataActivitiesPulseTotals) => {
                club.activities.sort(
                    (a: ActivityFE, b: ActivityFE) =>
                        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
                );
                return club;
            }),
        );
    }

    public getAllTimeTotals(): Observable<AllTimeTotals> {
        return this.httpClient.get<AllTimeTotals>(this.env.api + '/clubs/totals');
    }

    public getAllActivitiesForAllClubs(): Observable<ClubDataPulseTotals[]> {
        return this.httpClient.get<ClubDataPulseTotals[]>(this.env.api + '/clubs/activities');
    }

    public getClubManagementData(): Observable<ClubManagementData> {
        return this.httpClient.get<ClubManagementData>(this.env.api + '/club');
    }

    public updateClubData(clubData: Partial<Club>) {
        return this.httpClient
            .put(this.env.api + '/club/update', clubData)
            .pipe(switchMap(() => this.getClubManagementData()));
    }

    public getAllPublicActivitiesForClub(slug: string): Observable<ClubWithPulse> {
        return this.httpClient.get<ClubWithPulse>(this.env.api + '/club/activities/' + slug);
    }

    public confirmUserToClub(userId: number, confirm: boolean): Observable<any> {
        return this.httpClient.post(this.env.api + '/clubs/confirm-user/' + userId, {
            confirm: confirm,
        });
    }

    public getClubForInvitation(invitationLink: string): Observable<ClubBase> {
        return this.httpClient.get<ClubBase>(this.env.api + '/clubs/invitation/' + invitationLink, {});
    }

    public getUsersForClub(): Observable<UserFE[]> {
        return this.httpClient.get<UserFE[]>(this.env.api + '/club/users');
    }

    public updateDonationAmount(donation: { donationAmount: number }): Observable<ClubManagementData> {
        return this.httpClient
            .put(this.env.api + '/club/donation', donation)
            .pipe(switchMap(() => this.getClubManagementData()));
    }

    public updateMarketingOptIn(marketingOptIn: boolean) {
        return this.httpClient.put<unknown>(this.env.api + '/club/marketing-opt-in', { marketingOptIn });
    }

    public getClubHeroes() {
        return this.httpClient.get<ClubHero[]>(this.env.api + '/club/heroes').pipe(catchError(() => []));
    }

    public getTeamsOfClub(): Observable<Team[]> {
        return this.httpClient.get<Team[]>(this.env.api + '/club/teams');
    }

    public createTeamForClub(team: Partial<Team>): Observable<{ id: number }> {
        return this.httpClient
            .post<DBSuccessResponse>(this.env.api + '/club/teams', team)
            .pipe(map(dbResponse => ({ id: dbResponse.insertId })));
    }

    public updateTeamOfClub(team: Team): Observable<DBSuccessResponse> {
        return this.httpClient.put<DBSuccessResponse>(this.env.api + '/club/teams', team);
    }

    public deleteTeam(teamId: number): Observable<DBSuccessResponse> {
        return this.httpClient.delete<DBSuccessResponse>(this.env.api + '/club/teams/' + teamId);
    }

    public addUserToTeam(teamId: number, userId: number): Observable<any> {
        return this.httpClient.post(`${this.env.api}/club/teams/${teamId}/${userId}`, {});
    }

    public deleteUserFromTeam(teamId: number, userId: number): Observable<any> {
        return this.httpClient.delete(`${this.env.api}/club/teams/${teamId}/${userId}`, {});
    }

    public removeUserFromClub(userId: number): Observable<any> {
        return this.httpClient.delete(`${this.env.api}/club/user/${userId}`);
    }
}
