<div class="content row">
    <div class="col-o-1 col-5 stat">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77 67">
            <g fill="none" fill-rule="evenodd">
                <g stroke="#03384d" stroke-linejoin="round" stroke-width="2">
                    <path
                        d="M1.493 23.893v9.6c0 2.64.56 4.8 3.2 4.8v14.4h9.6v-14.4c2.637 0 3.197-2.163 3.2-4.8v-9.6h-16 0zM59.093 23.893v9.6c0 2.64.56 4.8 3.2 4.8v14.4h9.6v-14.4c2.637 0 3.197-2.163 3.2-4.8v-9.6h-16 0zM27.093 23.893h22.4v16c0 3.52-2.88 6.4-6.4 6.4v19.2h-9.6v-19.2c-3.523 0-6.4-2.88-6.4-6.4v-16h0zM14.293 12.693c0 2.65-2.15 4.8-4.8 4.8s-4.8-2.15-4.8-4.8 2.15-4.8 4.8-4.8 4.8 2.15 4.8 4.8h0zM71.893 12.693c0 2.65-2.15 4.8-4.8 4.8s-4.8-2.15-4.8-4.8 2.15-4.8 4.8-4.8 4.8 2.15 4.8 4.8h0zM46.293 9.493c0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8 8 3.58 8 8h0z"
                    />
                </g>
            </g>
        </svg>
        <span>{{ totalUsers | number: '1.0-0' }}</span>
        <strong>{{ 'Home.CurrentlyRegisteredUsers' | translate }}</strong>
    </div>
    <div class="col-o-1 col-5 stat">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 83">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(2 2.743)" stroke="#03384d">
                    <circle cx="39" cy="39" r="38.5" />
                    <circle cx="39" cy="39" r="27" />
                    <path
                        d="M0 39a38.856 38.856 0 0010 26.077C17.139 73.011 27.487 78 39 78a38.848 38.848 0 0025.504-9.495C72.77 61.355 78 50.788 78 39c0-11.45-4.935-21.748-12.793-28.883C58.283 3.831 49.089 0 39 0"
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                    <path
                        d="M39 66.5c7.07 0 12.793-2.017 18.388-7.051C62.982 54.415 66.5 47.119 66.5 39a27.393 27.393 0 00-6.695-17.984C54.763 15.188 47.312 11.5 39 11.5"
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                    <circle cx="39" cy="39" r="15.5" />
                    <path
                        d="M49.698 50.898A15.96 15.96 0 0055 39c0-4-1.468-7.658-3.895-10.463A15.963 15.963 0 0039 23"
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                </g>
            </g>
        </svg>
        <span>{{ activitiesTotal | number: '1.0-0' }}</span>
        <strong>{{ 'Home.AllActivities' | translate }}</strong>
    </div>
</div>

<div class="content row">
    <div class="col-o-1 col-5 stat">
        <svg width="99" height="101" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 101">
            <g fill="none" fill-rule="evenodd">
                <g stroke="#03384d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                    <path
                        d="M58.99 17.572c0 8.864-16.064 28.107-16.064 28.107S26.87 26.436 26.87 17.572c0-8.869 7.186-16.06 16.055-16.06 8.873 0 16.063 7.191 16.063 16.06h0z"
                    />
                    <path
                        d="M48.952 17.572a6.026 6.026 0 01-6.024 6.024 6.026 6.026 0 01-6.024-6.024 6.026 6.026 0 016.024-6.025 6.026 6.026 0 016.024 6.025h0zM97.135 46.882c0 7.99-14.456 25.299-14.456 25.299S68.223 54.87 68.223 46.882c0-7.98 6.466-14.451 14.456-14.451 7.98 0 14.456 6.47 14.456 14.451h0zM36.904 68.652c0 9.849-17.82 31.186-17.82 31.186S1.264 78.5 1.264 68.652c0-9.838 7.971-17.814 17.82-17.814 9.838 0 17.82 7.976 17.82 17.814h0z"
                    />
                    <path
                        d="M88.099 46.882a5.418 5.418 0 11-10.839 0 5.42 5.42 0 0110.839 0h0zM25.765 68.652a6.683 6.683 0 01-6.68 6.686 6.68 6.68 0 01-6.68-6.686 6.681 6.681 0 0113.36 0h0zM46.981 52.304l21.668 14.442M27.702 97.838L71.634 77.33"
                    />
                </g>
            </g>
        </svg>
        <span>{{ kmTotal | distance | number: '1.0-0' }} <small>KM</small></span>
        <strong>{{ 'Home.TotalDistanceAllTime' | translate }}</strong>
    </div>
    <div class="col-o-1 col-5 mint stat">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 90">
            <g fill="none" fill-rule="evenodd">
                <g stroke="#03384d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                    <path
                        d="M53.925 20.161a35.436 35.436 0 0123.696 7.024M110.796 36.665c0 5.232-4.247 9.479-9.479 9.479"
                    />
                    <path
                        d="M44.664 9.558c-4.502-7.966-14.64-10.867-22.73-6.199 4.313 2.498 7.403 6.877 8.128 12.024-9.928 6.004-16.848 15.312-18.44 26.023h-9.83v18.957h12.783c6.687 13.89 22.833 23.696 41.719 23.696 24.867 0 45.023-16.971 45.023-37.914 0-24.492-27.516-43.155-56.653-36.587h0z"
                    />
                    <path
                        d="M30.229 39.035a2.37 2.37 0 01-4.74 0 2.377 2.377 0 012.37-2.37 2.377 2.377 0 012.37 2.37h0zM34.968 79.536v9M77.62 79.536v9"
                    />
                </g>
            </g>
        </svg>
        <span>{{ donationTotal | number: '1.0-0' }} <small>EUR</small></span>
        <strong>{{ 'Common.TotalDonationAmount' | translate }}</strong>
    </div>
</div>
