<h2>{{ 'Home.HowDoesItWork' | translate }}</h2>
<h3>{{ 'Home.HowToRegister' | translate }}</h3>
<div class="explanation__list list">
    <div class="list__element">
        <div class="list__content">
            <h4><span class="list__number">01</span>{{ 'Home.Step1RegisterClub' | translate }}</h4>
            <p [innerHTML]="'Home.Step1RegisterClubText' | translate"></p>
        </div>
    </div>
    <div class="list__element">
        <div class="list__content">
            <h4><span class="list__number">02</span>{{ 'Home.Step2ManageTeam' | translate }}</h4>
            <p [innerHTML]="'Home.Step2ManageTeamText' | translate"></p>
        </div>
    </div>
    <div class="list__element">
        <div class="list__content">
            <h4><span class="list__number">03</span>{{ 'Home.Step3LetsGo' | translate }}</h4>
            <p [innerHTML]="'Home.Step3LetsGoText' | translate"></p>
        </div>
    </div>
</div>
