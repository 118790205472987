import { ActivityFE } from '../../core/interfaces/activity.interface';
import { CalendarActivity } from '../../core/interfaces/calendarActivity.interface';
import { getKmByType } from '../../core/helper/distance';

export const filterActivitiesForMonth = (
    activities: ActivityFE[],
    selectedMonth: number,
    selectedYear: number,
): CalendarActivity[] =>
    activities
        .map(activity => ({
            ...activity,
            dateObject: new Date(activity.startDate),
        }))
        .filter(
            activity =>
                activity.dateObject.getMonth() === selectedMonth &&
                activity.dateObject.getFullYear() === selectedYear,
        );

const getDistanceAndTimeForDate = (activities: CalendarActivity[], date: Date) =>
    activities
        .filter(activity => activity.dateObject.getDate() === date.getDate())
        .reduce(
            (totalDistanceAndTime, activity: ActivityFE) => ({
                distance: totalDistanceAndTime.distance + getKmByType(activity),
                time: totalDistanceAndTime.time + activity.time,
            }),
            { distance: 0, time: 0 },
        );

const totalDistancePerDay = (
    monthDays: any[],
    lastOfMonth: any,
    numberOfMonth: any,
    today: any,
    activities: any,
) => {
    for (let i = 0; i < lastOfMonth.getDate(); i++) {
        const day = new Date(new Date().getFullYear(), numberOfMonth, i + 1);
        const { time, distance } = getDistanceAndTimeForDate(activities, day);

        monthDays.push({
            dayOfWeek: day.getDay(),
            time,
            distance,
            isInMonth: true,
            isToday: day.toUTCString() === today.toUTCString(),
        });
    }
    return monthDays;
};

export const getMonthDayList = (
    activities: CalendarActivity[],
    numberOfMonth: number,
    year: number,
    calculateFn = totalDistancePerDay,
) => {
    const firstOfMonth = new Date(year, numberOfMonth, 1);
    const lastOfMonth = new Date(year, numberOfMonth + 1, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const monthDays = calculateFn([], lastOfMonth, numberOfMonth, today, activities);

    const numberOfWeekDaysBeforeMonth = (firstOfMonth.getDay() + 6) % 7;
    for (let i = numberOfWeekDaysBeforeMonth - 1; i >= 0; i--) {
        monthDays.unshift({
            dayOfWeek: i,
            distance: 0,
            time: 0,
            isInMonth: false,
        });
    }

    const numberOfWeekDaysAfterMonth = (7 - lastOfMonth.getDay()) % 7;
    for (let i = 7 - numberOfWeekDaysAfterMonth; i < 7; i++) {
        monthDays.push({
            dayOfWeek: i,
            distance: 0,
            time: 0,
            isInMonth: false,
        });
    }

    return monthDays;
};

export const getAverageDistanceForMonth = (activities: CalendarActivity[]) => {
    return activities
        .sort(activity => activity.dateObject.getTime())
        .reduce((days: { day: number; distance: number }[], activity: CalendarActivity) => {
            if (days.length && days[days.length - 1].day === activity.dateObject.getDay()) {
                days[days.length - 1].distance += getKmByType(activity);
            } else {
                days.push({
                    day: activity.dateObject.getDay(),
                    distance: getKmByType(activity),
                });
            }
            return days;
        }, [])
        .filter(day => day.distance)
        .reduce((average, day, index) => (average * index + day.distance) / (index + 1), 0);
};
