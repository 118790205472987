import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ClubDataActivitiesPulseTotals } from '../../core/interfaces/club-with-activities.interface';
import { TeamWithTotals } from '../../core/interfaces/team-with-totals.interface';
import { DateService } from '../../core/services/date.service';
import { DayTeamActivity } from '../../core/interfaces/club-activities-by-date.interface';
import { TenantService } from '../../core/services/tenant.service';
import { fillWeeks, generateMissingDays, groupDaysByWeek } from '../../core/helper/date.helper';

type CalendarDay = DayTeamActivity & {
    isInMonth: boolean;
    isInChallenge: boolean;
    isFuture?: boolean;
    teams: { totalKm: number; numberOfActivities: number; color: string | undefined }[];
};
type CalendarDayWithDummy = CalendarDay;

@Component({
    selector: 'app-tile-month-team-stats',
    templateUrl: './tile-month-team-stats.component.html',
    styleUrls: ['./tile-month-team-stats.component.scss'],
})
export class TileMonthTeamStatsComponent implements OnInit {
    @Input() public club: ClubDataActivitiesPulseTotals;

    public totalDistanceCurrentMonth: number;
    public teams: (TeamWithTotals & { monthDistance: number })[] = [];
    public totalAmountOfActivitiesForMonth: any;
    public date = new Date();
    public selectedMonth = this.date.getMonth();
    public selectedYear = this.date.getFullYear();
    public challengeStartMonth: number;
    public challengeEndMonth: number;
    public weeks: CalendarDayWithDummy[][];

    public constructor(
        private dateService: DateService,
        private changeDetector: ChangeDetectorRef,
        tenantService: TenantService,
    ) {
        this.challengeStartMonth = new Date(tenantService.tenant.period_start_date).getMonth();
        this.challengeEndMonth = new Date(tenantService.tenant.period_end_date).getMonth();
    }

    public getDayTranslation(index: number) {
        return this.dateService.getShortDayTranslateKeys()[index];
    }

    public getSelectedMonthName() {
        return this.dateService.getMonthTranslateKeys()[this.date.getMonth()];
    }

    public ngOnInit() {
        this.updateValues();
    }

    public prevMonth() {
        if (this.selectedMonth === 0) {
            this.selectedMonth = 11;
            this.selectedYear--;
        } else {
            this.selectedMonth--;
        }
        this.updateValues();
    }

    public nextMonth() {
        if (this.selectedMonth === 11) {
            this.selectedMonth = 0;
            this.selectedYear++;
        } else {
            this.selectedMonth++;
        }
        this.updateValues();
    }

    public getDasharrayForTeam(day: DayTeamActivity & { teams: { totalKm: number }[] }, index: number) {
        const totalDayDistance = day.totalKm;
        const prevTeamsDistance = day.teams.slice(0, index + 1).reduce((sum, team) => sum + team.totalKm, 0);
        return (1 - prevTeamsDistance / totalDayDistance) * 100;
    }

    private updateValues() {
        if (this.club && this.club.activities) {
            let monthDays = this.club.activitiesByDate.filter(
                day => day.month === this.selectedMonth && day.year === this.selectedYear,
            );
            this.totalDistanceCurrentMonth = monthDays.reduce((distance, day) => distance + day.totalKm, 0);

            this.totalAmountOfActivitiesForMonth = monthDays.reduce(
                (numberOfActivities, day) => numberOfActivities + day.totalActivities,
                0,
            );

            this.date = new Date(this.selectedYear, this.selectedMonth);

            this.teams = this.club.teams.map(team => ({
                ...team,
                monthDistance: monthDays.reduce(
                    (sum, day) => sum + (day.teamActivities[team.id]?.totalKm ?? 0),
                    0,
                ),
            }));

            if (monthDays[0]?.dayInMonth > 1) {
                const firstOfMonth = new Date(monthDays[0].year, monthDays[0].month, 1);
                monthDays = [
                    ...generateMissingDays(firstOfMonth, monthDays[0].dayInMonth - 1, 0).map(day => ({
                        ...day,
                        isInChallenge: false,
                    })),
                    ...monthDays,
                ];
            }
            if (monthDays.length === 0) {
                const firstOfMonth = new Date(Date.UTC(this.selectedYear, this.selectedMonth, 1, 0, 0, 0, 0));
                const lastOfMonth = new Date(
                    Date.UTC(this.selectedYear, this.selectedMonth + 1, 0, 0, 0, 0, 0),
                );
                monthDays = [
                    ...generateMissingDays(firstOfMonth, lastOfMonth.getDate(), 0).map(day => ({
                        ...day,
                        isInChallenge: false,
                    })),
                    ...monthDays,
                ];
            }

            // days of month
            const days: CalendarDay[] = monthDays.map(day => ({
                ...day,
                isInMonth: true,
                isInChallenge: (day as any).isInChallenge ?? true,
                teams: Object.entries(day.teamActivities ?? {})
                    .map(([teamId, team]) => ({
                        ...team,
                        color: this.club.teams.find(t => t.id === parseInt(teamId, 10))?.color,
                    }))
                    .sort((t1, t2) => t2.totalKm - t1.totalKm),
            }));

            this.weeks = groupDaysByWeek(days);

            fillWeeks(this.weeks, { isInMonth: false });

            this.changeDetector.detectChanges();
        }
    }
}
