import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-join-teaser',
    templateUrl: './join-teaser.component.html',
    styleUrls: ['./join-teaser.component.scss'],
})
export class JoinTeaserComponent {
    @Input() public headline: string;
}
