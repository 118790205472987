import * as d3 from 'd3';
import { addDayBeforeAndAfterDates } from './date.helper';

export const createContainer = (
    width: number,
    height: number,
    selector: string,
    classes: string,
    hasPadding = true,
) => {
    const padding = 30;
    const chartContainer = d3.select(selector);
    const svgContainer = chartContainer
        .append('svg')
        .attr('class', classes)
        .attr('width', hasPadding ? width - 2 * padding : width)
        .attr('height', height + 60);

    return { padding, svgContainer, chartContainer };
};

export const createScales = (
    maxValue: number,
    height: number,
    xValues: Date[],
    padding: number,
    xRange: number,
    xPaddingFactor = 1,
) => {
    const yScale = d3.scaleLinear().domain([0, maxValue]).nice().range([height, 0]);

    const xScale = d3
        .scaleUtc()
        .domain([xValues[0], xValues[xValues.length - 1]])
        .nice()
        .range([xPaddingFactor * padding, xRange]);

    return {
        xScale,
        yScale,
    };
};

export const drawChart = (
    selector: string,
    data: { startDate: string; activityAmount: number }[],
    dataKey: string,
    width: number,
    height: number,
    d3DateLocales: any,
    textFunction?: (value: number) => string,
) => {
    const barWidth = width / data.length - 2;

    const { padding, svgContainer, chartContainer } = createContainer(
        width,
        height,
        selector,
        'bar-chart d3chart',
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const maxValue = d3.max(data.map(d => d[dataKey]));

    const xValues: Date[] = addDayBeforeAndAfterDates(data);

    const { xScale, yScale } = createScales(maxValue + 5, height, xValues, padding, width - 2 * padding);

    const yAxis = d3.axisLeft(yScale);
    const xAxis = d3.axisBottom(xScale).ticks(d3.timeMonth.every(1)).tickFormat(d3DateLocales.format('%B'));

    svgContainer
        .append('g')
        .attr('id', 'x-axis')
        .attr('transform', 'translate(' + 10 + ', ' + (height + padding) + ')')
        .call(xAxis);

    svgContainer
        .append('g')
        .attr('id', 'y-axis')
        .attr('transform', 'translate(' + (padding + 10) + ', ' + padding + ')')
        .call(yAxis);

    svgContainer
        .selectAll('rect')
        .data(data)
        .enter()
        .append('rect')
        .attr('width', barWidth)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .attr('height', d => height - yScale(d[dataKey]))
        .attr('x', d => xScale(new Date(d.startDate)))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .attr('y', d => yScale(d[dataKey]) + padding - 1)
        .attr('class', 'bar')
        .style('transform', 'translateX(' + barWidth / 2 + 'px)')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .attr('data-value', d => d[dataKey])
        .on('mouseover', (_d: any, i) => {
            // @types/d3 here says, that d is LineGraphData and i is number (index) but
            // when logging these values, d is MouseEvent, i is LineGraphData.
            const date = d3.timeFormat('%d.%m.%Y');
            const startDate = new Date(i.startDate);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            showTooltip(date(startDate), i[dataKey], yScale(i[dataKey]) + padding, xScale(startDate));
        })
        .on('mouseout', () => {
            hideTooltip();
        });

    const tooltip = chartContainer.select('.tooltip').style('display', 'none');

    /* istanbul ignore next */
    const showTooltip = (date: string, value: number, topPx: number, leftPx: number) => {
        tooltip.select('.date').text(date);
        tooltip.select('.amount').text(textFunction ? textFunction(value) : '');
        tooltip
            .style('display', 'block')
            .style('top', topPx + 'px')
            .style('left', leftPx + barWidth + 'px');
    };

    const hideTooltip = () => {
        tooltip.style('display', 'none');
        tooltip.select('.date').text('');
        tooltip.select('.amount').text('');
    };
};
