import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, Optional } from '@angular/core';
import { IconService } from '../../core/services/icon.service';

@Component({
    selector: 'app-icon',
    template: '<ng-content></ng-content>',
    styles: [':host { display:flex; width: 100%; height: 100%; }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
    private svgIcon: SVGElement;

    public constructor(
        private element: ElementRef,
        private iconService: IconService,
        @Optional() @Inject('document') private document: Document,
    ) {}

    @Input()
    public set name(iconName: string) {
        if (this.svgIcon) {
            this.element.nativeElement.removeChild(this.svgIcon);
        }
        const svgData = this.iconService.getIcon(iconName);
        this.svgIcon = this.svgElementFromString(svgData ?? '');
        this.element.nativeElement.appendChild(this.svgIcon);
    }

    private svgElementFromString(svgContent: string): SVGElement {
        const div = this.document.createElement('DIV');
        div.innerHTML = svgContent;
        return (
            div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
        );
    }
}
