import { Component, Inject, Input, OnInit } from '@angular/core';
import { Team } from '../../core/interfaces/team.interface';
import { TeamWithTotals } from '../../core/interfaces/team-with-totals.interface';
import { UserService } from '../../core/services/user.service';
import { UserFE } from '../../core/interfaces/user.interface';
import { ClubActivity } from '../../core/interfaces/club-activity.interface';

@Component({
    selector: 'app-tile-teams',
    templateUrl: './tile-teams.component.html',
    styleUrls: ['./tile-teams.component.scss'],
})
export class TileTeamsComponent implements OnInit {
    @Input() public teams: TeamWithTotals[];
    @Input() public activities: ClubActivity[];

    public internalTeams: (TeamWithTotals & { activities: ClubActivity[] })[];

    public activityDifference: Team & { activitiesDifference: number };
    public distanceDifference: TeamWithTotals;
    public sortedTeams: (TeamWithTotals & { activities: ClubActivity[] })[];

    public user: UserFE;

    public constructor(
        private userService: UserService,
        @Inject('window') private window: Window,
    ) {}

    public ngOnInit() {
        this.internalTeams = this.teams.map(t => ({
            ...t,
            activities: this.activities.filter(a => a.teamId === t.id),
        }));

        this.sortedTeams = this.sortTeamsByKm();
        this.activityDifference = this.getTeamWithMostActivities();
        this.distanceDifference = this.getTeamWithMostDistance();
        this.userService.user.subscribe(u => (this.user = u));
    }

    private getTeamWithMostActivities(): Team & { activitiesDifference: number } {
        const sortedTeams = [...this.sortedTeams].sort((a, b) => b.activities.length - a.activities.length);

        return {
            ...sortedTeams[0],
            activitiesDifference: sortedTeams[1]
                ? sortedTeams[0].activities.length - sortedTeams[1].activities.length
                : 0,
        };
    }

    private getTeamWithMostDistance(): TeamWithTotals {
        return {
            ...this.sortedTeams[0],
            totalKm: this.sortedTeams[1] ? this.sortedTeams[0].totalKm - this.sortedTeams[1].totalKm : 0,
        };
    }

    private sortTeamsByKm(): (TeamWithTotals & { activities: ClubActivity[] })[] {
        return this.internalTeams.sort((a, b) => b.totalKm - a.totalKm);
    }

    public getWidthPercentage(totalKm: number) {
        // this.sortedTeams[0].totalKm = 50%;
        if (this.window.innerWidth < 768) {
            return (totalKm / this.sortedTeams[0].totalKm) * 100;
        }
        return (totalKm / (this.sortedTeams[0].totalKm * 2)) * 100;
    }
}
