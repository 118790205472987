import { Route } from '@angular/router';
import { SaasLpComponent } from './saas-lp/saas-lp.component';
import { EmptyRoutingComponent } from './empty-routing/empty-routing.component';
import { ImprintComponent } from './legal/imprint/imprint.component';
import { DataPrivacyComponent } from './legal/data-privacy/data-privacy.component';
import { AgbComponent } from './legal/agb/agb.component';
import { SocialComponent } from './social/social.component';

const subdomain = window.location.hostname.split('.')?.[0] ?? 'www';
const tenantSlug = ['remoterun', 'dev'].includes(subdomain) ? 'www' : subdomain;

const rootRoute =
    tenantSlug !== 'www'
        ? {
              path: '',
              loadChildren: () => import('./tenant/tenant.module').then(m => m.TenantModule),
          }
        : {
              path: '',
              component: SaasLpComponent,
          };

export const appRoutes: Route[] = [
    rootRoute,
    {
        path: 'legal',
        component: EmptyRoutingComponent,
        children: [
            {
                path: 'imprint',
                component: ImprintComponent,
            },
            {
                path: 'dataprivacy',
                component: DataPrivacyComponent,
            },
            {
                path: 'terms-of-use',
                component: AgbComponent,
            },
        ],
    },
    {
        path: 'social',
        component: SocialComponent,
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/',
    },
];
