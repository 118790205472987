import { Component, Input } from '@angular/core';
import { ClubDataPulseTotals } from '../../core/interfaces/club-with-activities.interface';

@Component({
    selector: 'app-stats-ranking',
    templateUrl: './stats-ranking.component.html',
    styleUrls: ['./stats-ranking.component.scss'],
})
export class StatsRankingComponent {
    @Input() public clubs: ClubDataPulseTotals[];
    private get activeClubs() {
        return this.clubs.filter(c => c.totalActivities > 0);
    }

    public barMax: number;
    public sort = 'index';

    public sortBy(property: string) {
        this.sort = property;
    }

    public getSortedRanking(): ClubDataPulseTotals[] {
        switch (this.sort) {
            case 'team':
                return this.activeClubs.sort((c1, c2) => c2.userCount - c1.userCount);
            case 'distance':
                return this.activeClubs.sort((c1, c2) => c2.totalKm - c1.totalKm);
            case 'index':
            default:
                return this.activeClubs.sort(
                    (c1, c2) => c2.totalKm / c2.userCount - c1.totalKm / c1.userCount,
                );
        }
    }

    public getBarMax() {
        let realMax: number;
        switch (this.sort) {
            case 'team':
                realMax = Math.max(...this.activeClubs.map(ac => ac.userCount));
                break;
            case 'distance':
                realMax = Math.max(...this.activeClubs.map(ac => ac.totalKm));
                break;
            case 'index':
            default:
                realMax = Math.max(
                    ...this.activeClubs.map(ac => (ac.userCount ? ac.totalKm / ac.userCount : 0)),
                );
        }
        return realMax * 1.25;
    }

    public getBarValue(club: ClubDataPulseTotals) {
        switch (this.sort) {
            case 'team':
                return club.userCount;
            case 'distance':
                return club.totalKm;
            case 'index':
            default:
                return club.totalKm / club.userCount;
        }
    }
}
