import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-stats-facts4',
    templateUrl: './stats-facts4.component.html',
    styleUrls: ['./stats-facts4.component.scss'],
})
export class StatsFacts4Component {
    @Input() public value: number | string;
    @Input() public label?: string;

    @Input() public theme = 'white';
}
