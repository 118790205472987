<div class="stats-weekly" *ngIf="club">
    <h4>{{ 'TileWeeksStats.Headline' | translate }}</h4>
    <header>
        <button (click)="prevWeek()" [disabled]="activeWeekIndex === 0">
            <svg width="11" height="22" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M11 .718v21l-11-10.5z" fill="#D8D8D8" />
                </g>
            </svg>
        </button>
        <h2>
            {{ 'DateTime.CalendarWeekAbbr' | translate: { week: getWeekNumber() } }}
            <small>{{
                'TileWeekStatsDateLine'
                    | translate
                        : {
                              startDay: activeWeek[0].startDate | date: 'dd',
                              startMonth: activeWeek[0].startDate | date: 'MMMM',
                              endDay: activeWeek[activeWeek.length - 1].startDate | date: 'dd',
                              endMonth: activeWeek[activeWeek.length - 1].startDate | date: 'MMMM'
                          }
            }}</small>
        </h2>
        <button (click)="nextWeek()" [disabled]="activeWeekIndex === activitiesPerCalendarWeek.length - 1">
            <svg width="11" height="22" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 .718v21l11-10.5z" fill="#D8D8D8" />
                </g>
            </svg>
        </button>
    </header>

    <section #chartWrapper>
        <div>
            <div class="stats-weekly__chart"></div>
        </div>
        <div class="stats-weekly__stats">
            <app-stats-facts3
                [value]="totalDistanceForActiveWeek | distance"
                [label]="'Common.TotalDistance' | translate"
                unit="KM"
            ></app-stats-facts3>
            <div class="stats-weekly__per-team" *ngIf="clubHasTeams && teams.length <= 3">
                <ng-container *ngFor="let team of teams">
                    <span [ngStyle]="{ backgroundColor: team.color }" class="team__color"></span>
                    <span class="team__distance">
                        {{ team.distance | distance | number: '1.0-2' }}
                    </span>
                    <small>KM</small>
                </ng-container>
            </div>
            <app-stats-facts3
                [value]="totalAmountOfActivitiesForWeek"
                [label]="'Common.Activities' | translate"
            ></app-stats-facts3>
        </div>
    </section>
</div>
