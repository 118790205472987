<app-navigation [mobileNavbarOpen]="mobileNavbarOpen">
    <ng-container mobileMenu>
        <div class="nav__links">
            <div class="link-group">
                <a href="//charity.remoterun.de">
                    {{ 'Navigation.ToCharity' | translate }}
                </a>
            </div>
        </div>
    </ng-container>

    <ng-container desktopMenu>
        <div class="nav__links">
            <div class="link-group">
                <a href="//charity.remoterun.de">
                    {{ 'Navigation.ToCharity' | translate }}
                </a>
            </div>

            <div class="link-group">
                <button
                    (click)="switchLanguage()"
                    [title]="'Navigation.SwitchLanguage' | translate"
                    class="langswitch"
                >
                    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path
                                d="M20.494 5.412s-.467 2.457-2.343 3.351c-4.348-1.037-2.704 1.31-2.344 2.685.47.448 1.406 0 1.406 0 2.095 3.939-1.406 4.922-1.406 4.922-2.551 2.434-1.72 3.13-4.218 3.13-.47 0-1.409-1.788-1.409-2.238 0-3.408-1.79-4.025-3.279-4.025-1.407 0-1.407-1.789-1.407-1.789s0-3.133 2.343-3.58c2.343-.446 2.343.447 2.343.447.969.925 4.404-.888 1.266-3.218-1.16-.863 4.233-2.598 4.233-2.598"
                                stroke="#5BDDB8"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M23.494 12.5c0 6.075-4.924 11-11 11-6.075 0-11-4.925-11-11 0-6.076 4.925-11 11-11 6.076 0 11 4.924 11 11h0z"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                    </svg>
                    <span>{{ 'Navigation.SwitchLanguage' | translate }}</span>
                </button>
            </div>
        </div>
    </ng-container>
</app-navigation>
