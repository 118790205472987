<input type="text" [value]="getInvitationPath()" id="invitationLink" readonly #invitationLink />
<button
    class="invitation__copy button"
    (click)="textToClipBoard()"
    [title]="'ClubManagement.InviteCopyText' | translate"
>
    <svg viewBox="0 0 18 25" xmlns="http://www.w3.org/2000/svg">
        <g
            stroke="currentColor"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path d="M9 15V2M4.5 5.5L9 1l4.5 4.5M12 9.5h5v15H1v-15h5" />
        </g>
    </svg>
    {{ 'InvitationBanner.CopyLink' | translate }}
</button>
