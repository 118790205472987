import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsEvent } from '../interfaces/analytics-event.interface';
import { UserService } from './user.service';
import { MatomoTracker } from 'ngx-matomo-client';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    public trackingEnabled$ = new BehaviorSubject<boolean>(!this.environment.production);

    public constructor(
        @Inject('window') private window: Window,
        @Inject('document') private document: Document,
        @Inject('env') private environment: any,
        @Inject('storage') private storage: any,
        private userService: UserService,
        private readonly tracker: MatomoTracker,
    ) {}

    public startTracking(): void {
        this.tracker.setCookieConsentGiven();
        this.tracker.rememberCookieConsentGiven();
        this.storage.setItem('RR_CONSENT_GIVEN', 1);
    }

    public forgetTracking(): void {
        this.tracker.forgetCookieConsentGiven();
        this.trackingEnabled$.next(false);
        this.storage.removeItem('RR_CONSENT_GIVEN');
    }

    public trackEvent(event: AnalyticsEvent) {
        if (
            this.trackingEnabled$.getValue() &&
            !(
                !this.userService.currentUser() ||
                this.environment.cognitoIdsToIgnore.includes(this.userService.currentUser().cognitoId)
            )
        ) {
            this.tracker.trackEvent(event.category, event.action, event.label);
        }
    }
}
