import * as d3 from 'd3';
import { createContainer, createScales } from '../../core/helper/bar-chart.helper';
import { TeamWithTotals } from '../../core/interfaces/team-with-totals.interface';
import {
    Day,
    DayTeamActivity,
    TeamActivities,
} from '../../core/interfaces/club-activities-by-date.interface';

const calculateMaxValueForYAxis = (allWeeks: (DayTeamActivity & { dayOfWeek: number })[][]): number[] =>
    allWeeks.map(week => d3.max(week.map(day => day.totalKm ?? 0)) ?? 0);

type d3UtcParse = (date: string) => Date;
type d3TimeFormat = (value: Date | { valueOf(): number }, i: number) => string;

export const drawChart = (
    selector: string,
    allWeeks: Day[][],
    activeWeek: number,
    teams: TeamWithTotals[],
    width: number,
    height: number,
) => {
    // TODO: Weirdly @types/d3 does not work reliably.
    const timeParser: (date: string) => Date = d3.utcParse('%Y-%m-%d') as d3UtcParse;
    const barWidth = 20;
    const maxValue = (d3.max(calculateMaxValueForYAxis(allWeeks)) ?? 0) / 1000;

    // Remove existing first
    const chartContainer = d3.select(selector);
    chartContainer.select('svg').remove();

    const { padding, svgContainer } = createContainer(width, height, selector, 'd3chart', false);

    const xValues: Date[] = allWeeks[activeWeek].map(d => timeParser(d.startDate));

    const { xScale, yScale } = createScales(maxValue + 2, height, xValues, padding, width - padding, 2);

    const yAxis = d3.axisLeft(yScale).tickSize(width).ticks(6);
    const xAxis = d3
        .axisBottom(xScale)
        .ticks(d3.utcDay.every(1))
        .tickFormat(d3.timeFormat('%a') as d3TimeFormat);

    svgContainer
        .append('g')
        .attr('id', 'x-axis')
        .attr('transform', 'translate(' + 0 + ', ' + (height + padding) + ')')
        .call(xAxis)
        .call(g => g.selectAll('.tick line, .domain').remove());

    svgContainer
        .append('g')
        .attr('id', 'y-axis')
        .attr('transform', 'translate(' + (width + padding) + ', ' + padding + ')')
        .call(yAxis)
        .call(g => g.select('.domain').remove());

    const barGroups = svgContainer
        .selectAll('.bar__group')
        .data(allWeeks[activeWeek])
        .enter()
        .append('g')
        .attr('class', 'bar__group');

    const getDistanceForSelectedTeams = (selected: TeamWithTotals[], activities: TeamActivities) =>
        selected.reduce((distance, t) => distance + (activities?.[t.id]?.totalKm ?? 0), 0) / 1000;

    const getTeamData = (team: TeamWithTotals, index: number) => {
        barGroups
            .append('rect')
            .attr('data-date', d => d.startDate)
            .attr('width', barWidth)
            .attr('height', d => height - yScale((d.teamActivities?.[team.id]?.totalKm ?? 0) / 1000))
            .attr('x', d => xScale(timeParser(d.startDate) as Date))
            .attr(
                'y',
                d =>
                    yScale(getDistanceForSelectedTeams(teams.slice(0, index), d.teamActivities)) -
                    (height - yScale((d.teamActivities?.[team.id]?.totalKm ?? 0) / 1000)) +
                    padding,
            )
            .attr('class', 'bar bar--' + index)
            .style('fill', team.color)
            .style('transform', 'translateX(-' + barWidth / 2 + 'px)');
    };

    const getDistancePerDayText = (element: d3.Selection<SVGGElement, Day, SVGSVGElement, unknown>) => {
        return element
            .append('text')
            .attr('x', d => xScale(timeParser(d.startDate) as Date))
            .append('tspan')
            .text(d => {
                const totalDayDistance = (d.totalKm ?? 0) / 1000;
                return totalDayDistance !== 0 ? totalDayDistance.toFixed(0) : '';
            })
            .style('font-size', '12px')
            .attr('text-anchor', 'middle');
    };

    if (teams.length > 0) {
        teams.forEach((team: TeamWithTotals, index: number) => getTeamData(team, index));

        getDistancePerDayText(barGroups).attr(
            'y',
            d =>
                yScale(getDistanceForSelectedTeams(teams.slice(0, teams.length - 1), d.teamActivities)) -
                (height - yScale((d.teamActivities?.[teams[teams.length - 1].id]?.totalKm ?? 0) / 1000)) +
                padding -
                5,
        );
    } else {
        barGroups
            .append('rect')
            .attr('data-date', d => d.startDate)
            .attr('width', barWidth)
            .attr('height', d => height - yScale((d.totalKm ?? 0) / 1000))
            .attr('x', d => xScale(timeParser(d.startDate) as Date))
            .attr('y', d => yScale((d.totalKm ?? 0) / 1000) + padding)
            .attr('class', 'bar')
            .style('transform', 'translateX(-' + barWidth / 2 + 'px)');

        getDistancePerDayText(barGroups).attr('y', d => yScale(d.totalKm / 1000) + padding - 5);
    }
};
