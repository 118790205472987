import { Component, OnInit } from '@angular/core';
import { ClubService } from '../../core/services/club.service';

@Component({
    selector: 'app-all-time-totals',
    templateUrl: './all-time-totals.component.html',
    styleUrls: ['./all-time-totals.component.scss'],
})
export class AllTimeTotalsComponent implements OnInit {
    public kmTotal = 0;
    public donationTotal = 0;
    public activitiesTotal = 0;
    public totalUsers = 0;

    public constructor(private clubService: ClubService) {}

    public ngOnInit(): void {
        this.clubService.getAllTimeTotals().subscribe(result => {
            this.kmTotal = result.distance;
            this.donationTotal = result.totalDonation;
            this.activitiesTotal = result.activityCount;
            this.totalUsers = result.userCount;
        });
    }
}
