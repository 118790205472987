import * as d3 from 'd3';
import { DayTeamActivity } from '../interfaces/club-activities-by-date.interface';

export const getCalendarWeek = (d: Date) => {
    // Create a copy of this date object
    const target = new Date(d.valueOf());

    // ISO week date weeks start on monday
    // so correct the day number
    const dayNr = (d.getDay() + 6) % 7;

    // Set the target to the thursday of this week so the
    // target date is in the right year
    target.setDate(target.getDate() - dayNr + 3);

    // ISO 8601 states that week 1 is the week
    // with january 4th in it
    const jan4 = new Date(target.getFullYear(), 0, 4);

    // Number of days between target date and january 4th
    const dayDiff = ((target as any) - (jan4 as any)) / 86400000;

    // Calculate week number: Week 1 (january 4th) plus the
    // number of weeks between target date and january 4th
    return Math.ceil(dayDiff / 7);
};

export const extendChallengeRangeToFullWeeks = (challengeStart: number, challengeEnd: number) => {
    const startDate = new Date(challengeStart);
    const endDate = new Date(challengeEnd);

    const firstWeek = startDate;
    // challenge start is not monday, add all days before.
    firstWeek.setDate(firstWeek.getDate() - firstWeek.getDay() + 1);

    const lastWeek = endDate;
    // challenge end is not sunday, add all days after.
    const daysToAdd = (7 - lastWeek.getDay()) % 7;
    lastWeek.setDate(lastWeek.getDate() + daysToAdd);

    return {
        weekStart: firstWeek,
        weekEnd: lastWeek,
    };
};

export const getDaysOfChallenge = (challengeStart: Date, challengeEnd: Date): { startDate: string }[] => {
    const datesArray = [];
    const date = challengeStart;

    while (date <= challengeEnd) {
        datesArray.push({
            startDate: date.toISOString(),
        });

        date.setUTCDate(date.getUTCDate() + 1);
    }

    return datesArray;
};

export const addDayBeforeAndAfterDates = (data: { startDate: string }[]) => {
    const timeParser = d3.utcParse('%Y-%m-%d');
    const sortedData = data
        .map(d => timeParser(d.startDate) as Date)
        .sort((a: Date, b: Date) => a.getTime() - b.getTime());
    const dayBefore = new Date(sortedData[0].setDate(sortedData[0].getDate() - 1));
    const dayAfter = new Date(
        sortedData[sortedData.length - 1].setDate(sortedData[sortedData.length - 1].getDate() + 1),
    );

    return [dayBefore, ...sortedData, dayAfter];
};

export const groupDaysByWeek = <T extends { week: number }>(days: T[]) =>
    days.reduce<T[][]>((weeks, day, index) => {
        const lastWeek = weeks.pop();
        if (lastWeek?.[0]?.week === day.week || (day.week === 0 && index !== 0)) {
            return [...weeks, [...(lastWeek ?? []), day]];
        } else {
            return [...weeks, ...(lastWeek ? [lastWeek] : []), [{ ...day }]];
        }
    }, []);

export const fillWeeks = <T, P>(weeks: (T | P)[][], payload: P) => {
    console.log(weeks);
    weeks[0] = [...Array.from(new Array(7 - (weeks[0].length ?? 0))).map(() => payload), ...weeks[0]];
    const last = weeks.length - 1;
    weeks[last] = [...weeks[last], ...Array.from(new Array(7 - weeks[last].length)).map(() => payload)];
};

export const addDaysToDate = (date: Date, offset: number) =>
    new Date(date.getTime() + 24 * 60 * 60 * 1000 * offset);

export const generateMissingDays = (referenceDate: Date, numberOfDays: number, dayOffsetFromIndex: number) =>
    Array.from(new Array(numberOfDays)).map((_, index) => {
        const date = addDaysToDate(referenceDate, index + dayOffsetFromIndex);

        return {
            week: getCalendarWeek(date),
            isoDate: date.toISOString(),
        } as DayTeamActivity;
    });

export const isDifferenceToChallengeEnd = (challengeEnd: number, differenceInHours: number) => {
    return Date.now() >= challengeEnd + differenceInHours * 60 * 60 * 1000;
};
