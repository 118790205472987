import { Component, Input } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { ClubDataPulseTotals } from '../../../core/interfaces/club-with-activities.interface';

@Component({
    selector: 'app-ranking-row',
    templateUrl: './ranking-row.component.html',
    styleUrls: ['./ranking-row.component.scss'],
})
export class RankingRowComponent {
    @Input() public club: ClubDataPulseTotals;
    @Input() public barMax: number;
    @Input() public barValue: number;

    public constructor(private userService: UserService) {}

    public getWidthPercentage(): number {
        return (this.barValue / this.barMax) * 100;
    }

    public getClubLink(clubId: number) {
        return this.userService.user.getValue() && this.userService.user.getValue().clubId === clubId
            ? '/dashboard'
            : this.club.slug;
    }
}
