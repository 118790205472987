<div class="process-indicator">
    <div
        *ngFor="let step of steps; let index = index; let first = first; let last = last"
        [ngClass]="{
            'is-active': index + 1 === activeStep,
            'is-previous': index + 1 < activeStep,
            'is-disabled': index + 1 > activeStep,
            'is-first': first,
            'is-last': last
        }"
        class="process-indicator__step has-{{ steps.length }}"
    >
        <div class="process-indicator__bubble">
            {{ index + 1 }}
        </div>
        <div class="process-indicator__name">{{ step }}</div>
    </div>
    <div class="process-indicator__active">
        {{ steps[activeStep - 1] }}
    </div>
</div>
