import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-donut',
    templateUrl: './donut.component.html',
    styleUrls: ['./donut.component.scss'],
})
export class DonutComponent {
    @Input() public width: number;
    @Input() public circleColor = '#5BDDB8';
}
