<div class="stats-pulse">
    <ng-content></ng-content>
    <app-legend [items]="pulseLegend"></app-legend>

    <div class="stats-pulse__list" *ngIf="club.activitiesByDate">
        <div class="month" *ngFor="let month of monthList">
            <span class="month__name">{{ getMonthName(month) }}</span>
            <div class="month__days">
                <div
                    class="month__day"
                    *ngFor="let day of getMonthPulse(month)"
                    [ngClass]="{
                        low: 0 < day.activities && day.activities < middleThreshold,
                        middle: middleThreshold <= day.activities && day.activities < upperThreshold,
                        high: day.activities >= upperThreshold
                    }"
                ></div>
            </div>
        </div>
    </div>
</div>
