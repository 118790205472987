<div class="tile-calendar">
    <div class="calendar-control">
        <div class="month-control">
            <button (click)="prevMonth()" class="month-control__prev">prev</button>
            <div>{{ getSelectedMonthName() }} {{ date | date: 'yyyy' }}</div>
            <button (click)="nextMonth()" class="month-control__next">next</button>
        </div>
        <div class="calendar-icon"></div>
    </div>
    <div class="calendar">
        <div class="header calendar-row">
            <span class="day">{{ 'DateTime.MondayAbbr' | translate }}</span>
            <span class="day">{{ 'DateTime.TuesdayAbbr' | translate }}</span>
            <span class="day">{{ 'DateTime.WednesdayAbbr' | translate }}</span>
            <span class="day">{{ 'DateTime.ThursdayAbbr' | translate }}</span>
            <span class="day">{{ 'DateTime.FridayAbbr' | translate }}</span>
            <span class="day">{{ 'DateTime.SaturdayAbbr' | translate }}</span>
            <span class="day">{{ 'DateTime.SundayAbbr' | translate }}</span>
            <span class="km-column">{{ 'Common.Km' | translate }}</span>
            <span class="time-column">{{ 'Common.Time' | translate }}</span>
        </div>
        <div class="body" *ngIf="activities">
            <div class="row calendar-row" *ngFor="let week of getWeeks()">
                <ng-container *ngFor="let day of week">
                    <span [class]="generateClassName(day)"></span>
                </ng-container>
                <span class="km-column">{{ getWeekDistance(week) | number: '1.2-2' }} km</span>
                <span class="time-column">{{ getWeekTime(week) | time }}</span>
            </div>
        </div>
        <div class="summary calendar-row">
            <span class="km-summary km-column">{{ getMonthDistance() | number: '1.2-2' }} km</span>
            <span class="time-summary time-column">{{ getMonthTime() | time }}</span>
        </div>
    </div>
</div>
