import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getMonthPulse } from '../../core/helper/day-pulse';
import { ClubDataActivitiesPulseTotals } from '../../core/interfaces/club-with-activities.interface';
import { DateService } from '../../core/services/date.service';

@Component({
    selector: 'app-stats-pulse',
    templateUrl: './stats-pulse.component.html',
    styleUrls: ['./stats-pulse.component.scss'],
})
export class StatsPulseComponent implements OnInit {
    @Input() public monthList: number[];
    @Input() public club: ClubDataActivitiesPulseTotals;

    public middleThreshold: number;
    public upperThreshold: number;
    public pulseLegend = [
        { name: 'keine Aktivitäten', color: 'var(--color-alto-gray)' },
        { name: 'wenige', color: '#7daa9d' },
        { name: 'mäßig', color: '#62ceaf' },
        { name: 'viele', color: '#5bddb8' },
    ];

    public constructor(
        private dateService: DateService,
        private translateService: TranslateService,
    ) {
        this.translateService
            .get([
                'StatsPulse.NoActivities',
                'StatsPulse.FewActivities',
                'StatsPulse.AvgActivities',
                'StatsPulse.LotsActivities',
            ])
            .subscribe(
                result =>
                    (this.pulseLegend = [
                        { name: result['StatsPulse.NoActivities'], color: 'var(--color-alto-gray)' },
                        { name: result['StatsPulse.FewActivities'], color: '#7daa9d' },
                        { name: result['StatsPulse.AvgActivities'], color: '#62ceaf' },
                        { name: result['StatsPulse.LotsActivities'], color: '#5bddb8' },
                    ]),
            );
    }

    public ngOnInit() {
        const max = this.club.activitiesByDate
            ? Math.max(...this.club.activitiesByDate.map(d => d.totalActivities))
            : 0;
        this.middleThreshold = max * (1 / 3);
        this.upperThreshold = max * (2 / 3);
    }

    public getMonthPulse(month: number) {
        return getMonthPulse(month, this.club);
    }

    public getMonthName(month: number) {
        return this.dateService.getMonthName(month);
    }
}
