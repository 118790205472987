<app-navigation [mobileNavbarOpen]="mobileNavbarOpen">
    <ng-container mobileMenu>
        <div class="nav__links">
            <div *ngIf="user" class="link-group">
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.MyClub' | translate"
                    routerLink="/dashboard"
                    routerLinkActive="link--active"
                >
                    {{ 'Navigation.MyClub' | translate }}
                </a>
                <a
                    *ngIf="user?.adminForClub"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.ManageMyClub' | translate"
                    routerLink="/club-management"
                    routerLinkActive="link--active"
                >
                    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="currentColor" fill-rule="nonzero">
                            <path
                                d="M10.5 0l-.09.008A.5.5 0 0010 .5v2.856l-.24.062c-.791.206-1.422.407-1.926.636l-.166.078L5.635 2.1a.5.5 0 00-.707 0L2.1 4.93l-.057.069a.5.5 0 00.058.638l2.032 2.031-.078.165c-.228.504-.43 1.134-.637 1.925L3.354 10H.5a.5.5 0 00-.5.5v3l.008.09A.5.5 0 00.5 14h2.854l.063.242c.207.791.409 1.421.637 1.925l.078.164-2.033 2.033a.5.5 0 000 .708L4.93 21.9l.069.057a.5.5 0 00.638-.058l2.031-2.033.166.08c.503.228 1.134.43 1.925.637l.242.062V23.5a.5.5 0 00.5.5h3l.09-.008A.5.5 0 0014 23.5v-2.855l.242-.062c.791-.207 1.421-.409 1.924-.637l.165-.078 2.034 2.032a.5.5 0 00.707 0l2.828-2.828.057-.07a.5.5 0 00-.057-.637l-2.033-2.034.08-.164c.227-.504.43-1.134.636-1.925l.062-.242H23.5a.5.5 0 00.5-.5v-3l-.008-.09A.5.5 0 0023.5 10h-2.856l-.06-.239c-.206-.788-.408-1.421-.637-1.927l-.08-.167 2.032-2.03a.5.5 0 000-.708L19.072 2.1 19 2.043a.5.5 0 00-.637.057L16.33 4.132l-.165-.078c-.504-.229-1.135-.43-1.925-.636L14 3.356V.5a.5.5 0 00-.5-.5h-3zm.5 1h2v2.748a.5.5 0 00.383.486l.295.073.549.143c.869.236 1.51.468 1.932.727l.088.042a.5.5 0 00.526-.115l1.943-1.944 2.122 2.122-1.941 1.942a.5.5 0 00-.074.614c.259.425.492 1.068.729 1.936l.143.549.072.294.027.081a.5.5 0 00.459.302H23v2h-2.746a.5.5 0 00-.486.382l-.073.296-.144.55c-.238.87-.47 1.51-.728 1.932l-.042.088a.5.5 0 00.115.526l1.943 1.943-2.122 2.122-1.944-1.943a.5.5 0 00-.614-.073c-.422.259-1.06.49-1.93.728l-.55.144-.297.073-.08.027a.5.5 0 00-.302.459V23h-2v-2.746a.5.5 0 00-.382-.486l-.296-.073-.55-.144-.495-.142c-.623-.19-1.1-.38-1.438-.587l-.088-.042a.5.5 0 00-.527.116l-1.943 1.942-2.121-2.121 1.944-1.942a.5.5 0 00.073-.615c-.259-.423-.49-1.061-.728-1.932l-.144-.55a36.81 36.81 0 01-.073-.296l-.027-.08A.5.5 0 003.746 13H1v-2h2.746a.5.5 0 00.486-.382l.073-.296.144-.55c.238-.87.47-1.51.728-1.932l.042-.088a.5.5 0 00-.116-.527L3.16 5.282 5.282 3.16l1.944 1.944a.5.5 0 00.614.073c.423-.259 1.063-.49 1.932-.727l.55-.143.295-.073.081-.027A.5.5 0 0011 3.748V1z"
                            />
                            <path d="M12 7a5 5 0 100 10 5 5 0 000-10zm0 1a4 4 0 110 8 4 4 0 010-8z" />
                        </g>
                    </svg>
                </a>
            </div>
            <div *ngIf="user" class="link-group">
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.MyProfile' | translate"
                    routerLink="/profile"
                    routerLinkActive="link--active"
                >
                    {{ 'Navigation.MyProfile' | translate }}
                </a>
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.EditMyProfile' | translate"
                    routerLink="/profile/edit"
                    routerLinkActive="link--active"
                >
                    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="currentColor" fill-rule="nonzero">
                            <path
                                d="M10.5 0l-.09.008A.5.5 0 0010 .5v2.856l-.24.062c-.791.206-1.422.407-1.926.636l-.166.078L5.635 2.1a.5.5 0 00-.707 0L2.1 4.93l-.057.069a.5.5 0 00.058.638l2.032 2.031-.078.165c-.228.504-.43 1.134-.637 1.925L3.354 10H.5a.5.5 0 00-.5.5v3l.008.09A.5.5 0 00.5 14h2.854l.063.242c.207.791.409 1.421.637 1.925l.078.164-2.033 2.033a.5.5 0 000 .708L4.93 21.9l.069.057a.5.5 0 00.638-.058l2.031-2.033.166.08c.503.228 1.134.43 1.925.637l.242.062V23.5a.5.5 0 00.5.5h3l.09-.008A.5.5 0 0014 23.5v-2.855l.242-.062c.791-.207 1.421-.409 1.924-.637l.165-.078 2.034 2.032a.5.5 0 00.707 0l2.828-2.828.057-.07a.5.5 0 00-.057-.637l-2.033-2.034.08-.164c.227-.504.43-1.134.636-1.925l.062-.242H23.5a.5.5 0 00.5-.5v-3l-.008-.09A.5.5 0 0023.5 10h-2.856l-.06-.239c-.206-.788-.408-1.421-.637-1.927l-.08-.167 2.032-2.03a.5.5 0 000-.708L19.072 2.1 19 2.043a.5.5 0 00-.637.057L16.33 4.132l-.165-.078c-.504-.229-1.135-.43-1.925-.636L14 3.356V.5a.5.5 0 00-.5-.5h-3zm.5 1h2v2.748a.5.5 0 00.383.486l.295.073.549.143c.869.236 1.51.468 1.932.727l.088.042a.5.5 0 00.526-.115l1.943-1.944 2.122 2.122-1.941 1.942a.5.5 0 00-.074.614c.259.425.492 1.068.729 1.936l.143.549.072.294.027.081a.5.5 0 00.459.302H23v2h-2.746a.5.5 0 00-.486.382l-.073.296-.144.55c-.238.87-.47 1.51-.728 1.932l-.042.088a.5.5 0 00.115.526l1.943 1.943-2.122 2.122-1.944-1.943a.5.5 0 00-.614-.073c-.422.259-1.06.49-1.93.728l-.55.144-.297.073-.08.027a.5.5 0 00-.302.459V23h-2v-2.746a.5.5 0 00-.382-.486l-.296-.073-.55-.144-.495-.142c-.623-.19-1.1-.38-1.438-.587l-.088-.042a.5.5 0 00-.527.116l-1.943 1.942-2.121-2.121 1.944-1.942a.5.5 0 00.073-.615c-.259-.423-.49-1.061-.728-1.932l-.144-.55a36.81 36.81 0 01-.073-.296l-.027-.08A.5.5 0 003.746 13H1v-2h2.746a.5.5 0 00.486-.382l.073-.296.144-.55c.238-.87.47-1.51.728-1.932l.042-.088a.5.5 0 00-.116-.527L3.16 5.282 5.282 3.16l1.944 1.944a.5.5 0 00.614.073c.423-.259 1.063-.49 1.932-.727l.55-.143.295-.073.081-.027A.5.5 0 0011 3.748V1z"
                            />
                            <path d="M12 7a5 5 0 100 10 5 5 0 000-10zm0 1a4 4 0 110 8 4 4 0 010-8z" />
                        </g>
                    </svg>
                </a>
            </div>

            <div class="link-group">
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.AllClubs' | translate"
                    routerLink="/clubs"
                    routerLinkActive="link--active"
                >
                    {{ 'Navigation.AllClubs' | translate }}
                </a>
            </div>
            <div *ngIf="tenant.slug === 'charity'" class="link-group">
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.Story' | translate"
                    routerLink="/about"
                    routerLinkActive="link--active"
                >
                    {{ 'Navigation.Story' | translate }}
                </a>
            </div>

            <a
                *ngIf="user?.isSuperAdmin"
                [routerLinkActiveOptions]="{ exact: true }"
                [title]="'Navigation.AdminDashboard' | translate"
                class="link--admin"
                routerLink="/admin"
                routerLinkActive="link--active"
                title="Admin Bereich"
            >
                {{ 'Navigation.AdminDashboard' | translate }}
            </a>
        </div>
        <button (click)="logoutFromCognito()" *ngIf="user" class="button">
            <svg height="23" width="21" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M-4-4h24v24H-4z" />
                    <g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                        <path
                            d="M14.154 3.92A9.507 9.507 0 0120 12.694c0 5.245-4.255 9.5-9.5 9.5-5.247 0-9.5-4.255-9.5-9.5A9.503 9.503 0 016.802 3.94M10.5 1.001v9.5"
                        />
                    </g>
                </g>
            </svg>
            <span>{{ 'Navigation.Logout' | translate }}</span>
        </button>
        <a *ngIf="!user" class="button" routerLink="/login">{{ 'Navigation.Login' | translate }}</a>
        <a *ngIf="!user" class="button button--invert" routerLink="/login">{{
            'Navigation.RegisterClub' | translate
        }}</a>
    </ng-container>

    <ng-container desktopMenu>
        <div class="nav__links">
            <div *ngIf="tenant.slug === 'charity'" class="link-group">
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.Story' | translate"
                    routerLink="/about"
                    routerLinkActive="link--active"
                >
                    {{ 'Navigation.Story' | translate }}
                </a>
            </div>
            <div class="link-group">
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.AllClubs' | translate"
                    routerLink="/clubs"
                    routerLinkActive="link--active"
                >
                    {{ 'Navigation.AllClubs' | translate }}
                </a>
            </div>
            <div *ngIf="user" class="link-group">
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.MyClub' | translate"
                    routerLink="/dashboard"
                    routerLinkActive="link--active"
                >
                    {{ 'Navigation.MyClub' | translate }}
                </a>
                <a
                    *ngIf="user?.adminForClub"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.ManageMyClub' | translate"
                    routerLink="/club-management"
                    routerLinkActive="link--active"
                >
                    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#02364D" fill-rule="nonzero">
                            <path
                                d="M10.5 0l-.09.008A.5.5 0 0010 .5v2.856l-.24.062c-.791.206-1.422.407-1.926.636l-.166.078L5.635 2.1a.5.5 0 00-.707 0L2.1 4.93l-.057.069a.5.5 0 00.058.638l2.032 2.031-.078.165c-.228.504-.43 1.134-.637 1.925L3.354 10H.5a.5.5 0 00-.5.5v3l.008.09A.5.5 0 00.5 14h2.854l.063.242c.207.791.409 1.421.637 1.925l.078.164-2.033 2.033a.5.5 0 000 .708L4.93 21.9l.069.057a.5.5 0 00.638-.058l2.031-2.033.166.08c.503.228 1.134.43 1.925.637l.242.062V23.5a.5.5 0 00.5.5h3l.09-.008A.5.5 0 0014 23.5v-2.855l.242-.062c.791-.207 1.421-.409 1.924-.637l.165-.078 2.034 2.032a.5.5 0 00.707 0l2.828-2.828.057-.07a.5.5 0 00-.057-.637l-2.033-2.034.08-.164c.227-.504.43-1.134.636-1.925l.062-.242H23.5a.5.5 0 00.5-.5v-3l-.008-.09A.5.5 0 0023.5 10h-2.856l-.06-.239c-.206-.788-.408-1.421-.637-1.927l-.08-.167 2.032-2.03a.5.5 0 000-.708L19.072 2.1 19 2.043a.5.5 0 00-.637.057L16.33 4.132l-.165-.078c-.504-.229-1.135-.43-1.925-.636L14 3.356V.5a.5.5 0 00-.5-.5h-3zm.5 1h2v2.748a.5.5 0 00.383.486l.295.073.549.143c.869.236 1.51.468 1.932.727l.088.042a.5.5 0 00.526-.115l1.943-1.944 2.122 2.122-1.941 1.942a.5.5 0 00-.074.614c.259.425.492 1.068.729 1.936l.143.549.072.294.027.081a.5.5 0 00.459.302H23v2h-2.746a.5.5 0 00-.486.382l-.073.296-.144.55c-.238.87-.47 1.51-.728 1.932l-.042.088a.5.5 0 00.115.526l1.943 1.943-2.122 2.122-1.944-1.943a.5.5 0 00-.614-.073c-.422.259-1.06.49-1.93.728l-.55.144-.297.073-.08.027a.5.5 0 00-.302.459V23h-2v-2.746a.5.5 0 00-.382-.486l-.296-.073-.55-.144-.495-.142c-.623-.19-1.1-.38-1.438-.587l-.088-.042a.5.5 0 00-.527.116l-1.943 1.942-2.121-2.121 1.944-1.942a.5.5 0 00.073-.615c-.259-.423-.49-1.061-.728-1.932l-.144-.55a36.81 36.81 0 01-.073-.296l-.027-.08A.5.5 0 003.746 13H1v-2h2.746a.5.5 0 00.486-.382l.073-.296.144-.55c.238-.87.47-1.51.728-1.932l.042-.088a.5.5 0 00-.116-.527L3.16 5.282 5.282 3.16l1.944 1.944a.5.5 0 00.614.073c.423-.259 1.063-.49 1.932-.727l.55-.143.295-.073.081-.027A.5.5 0 0011 3.748V1z"
                            />
                            <path d="M12 7a5 5 0 100 10 5 5 0 000-10zm0 1a4 4 0 110 8 4 4 0 010-8z" />
                        </g>
                    </svg>
                </a>
            </div>
            <div *ngIf="user" class="link-group">
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.EditMyProfile' | translate"
                    routerLink="/profile"
                    routerLinkActive="link--active"
                >
                    {{ 'Navigation.MyProfile' | translate }}
                </a>
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/profile/edit"
                    routerLinkActive="link--active"
                >
                    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#02364D" fill-rule="nonzero">
                            <path
                                d="M10.5 0l-.09.008A.5.5 0 0010 .5v2.856l-.24.062c-.791.206-1.422.407-1.926.636l-.166.078L5.635 2.1a.5.5 0 00-.707 0L2.1 4.93l-.057.069a.5.5 0 00.058.638l2.032 2.031-.078.165c-.228.504-.43 1.134-.637 1.925L3.354 10H.5a.5.5 0 00-.5.5v3l.008.09A.5.5 0 00.5 14h2.854l.063.242c.207.791.409 1.421.637 1.925l.078.164-2.033 2.033a.5.5 0 000 .708L4.93 21.9l.069.057a.5.5 0 00.638-.058l2.031-2.033.166.08c.503.228 1.134.43 1.925.637l.242.062V23.5a.5.5 0 00.5.5h3l.09-.008A.5.5 0 0014 23.5v-2.855l.242-.062c.791-.207 1.421-.409 1.924-.637l.165-.078 2.034 2.032a.5.5 0 00.707 0l2.828-2.828.057-.07a.5.5 0 00-.057-.637l-2.033-2.034.08-.164c.227-.504.43-1.134.636-1.925l.062-.242H23.5a.5.5 0 00.5-.5v-3l-.008-.09A.5.5 0 0023.5 10h-2.856l-.06-.239c-.206-.788-.408-1.421-.637-1.927l-.08-.167 2.032-2.03a.5.5 0 000-.708L19.072 2.1 19 2.043a.5.5 0 00-.637.057L16.33 4.132l-.165-.078c-.504-.229-1.135-.43-1.925-.636L14 3.356V.5a.5.5 0 00-.5-.5h-3zm.5 1h2v2.748a.5.5 0 00.383.486l.295.073.549.143c.869.236 1.51.468 1.932.727l.088.042a.5.5 0 00.526-.115l1.943-1.944 2.122 2.122-1.941 1.942a.5.5 0 00-.074.614c.259.425.492 1.068.729 1.936l.143.549.072.294.027.081a.5.5 0 00.459.302H23v2h-2.746a.5.5 0 00-.486.382l-.073.296-.144.55c-.238.87-.47 1.51-.728 1.932l-.042.088a.5.5 0 00.115.526l1.943 1.943-2.122 2.122-1.944-1.943a.5.5 0 00-.614-.073c-.422.259-1.06.49-1.93.728l-.55.144-.297.073-.08.027a.5.5 0 00-.302.459V23h-2v-2.746a.5.5 0 00-.382-.486l-.296-.073-.55-.144-.495-.142c-.623-.19-1.1-.38-1.438-.587l-.088-.042a.5.5 0 00-.527.116l-1.943 1.942-2.121-2.121 1.944-1.942a.5.5 0 00.073-.615c-.259-.423-.49-1.061-.728-1.932l-.144-.55a36.81 36.81 0 01-.073-.296l-.027-.08A.5.5 0 003.746 13H1v-2h2.746a.5.5 0 00.486-.382l.073-.296.144-.55c.238-.87.47-1.51.728-1.932l.042-.088a.5.5 0 00-.116-.527L3.16 5.282 5.282 3.16l1.944 1.944a.5.5 0 00.614.073c.423-.259 1.063-.49 1.932-.727l.55-.143.295-.073.081-.027A.5.5 0 0011 3.748V1z"
                            />
                            <path d="M12 7a5 5 0 100 10 5 5 0 000-10zm0 1a4 4 0 110 8 4 4 0 010-8z" />
                        </g>
                    </svg>
                </a>
            </div>

            <div *ngIf="user?.isSuperAdmin" class="link-group">
                <a
                    [routerLinkActiveOptions]="{ exact: true }"
                    [title]="'Navigation.AdminDashboard' | translate"
                    class="link--admin"
                    routerLink="/admin"
                    routerLinkActive="link--active"
                >
                    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <g stroke="#02364D" stroke-linejoin="round">
                                <path d="M20 20a.5.5 0 11-1 0 .5.5 0 011 0h0z" />
                                <path
                                    d="M15.5 23.5h8v-6h-8zM19.5 21.5v-1M16.5 17.5v-1a3 3 0 016 0v1M12.5 14.5v-2.804M7.5 11.696V14.5l-5.009 1.789A3.001 3.001 0 00.5 19.114V21.5h12"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M15 6.5c0 3.313-2.239 6-5 6-2.762 0-5-2.687-5-6s2.238-6 5-6c2.761 0 5 2.687 5 6h0z"
                                />
                                <path
                                    d="M14.953 5.953c-.168.021-.284.014-.453.047-1.703.328-2.797-.289-3.734-1.93C10.203 5.148 8.444 6 7 6a4.02 4.02 0 01-1.936-.466"
                                />
                            </g>
                        </g>
                    </svg>
                </a>
            </div>

            <div class="link-group">
                <button
                    (click)="switchLanguage()"
                    [title]="'Navigation.SwitchLanguage' | translate"
                    class="langswitch"
                >
                    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path
                                d="M20.494 5.412s-.467 2.457-2.343 3.351c-4.348-1.037-2.704 1.31-2.344 2.685.47.448 1.406 0 1.406 0 2.095 3.939-1.406 4.922-1.406 4.922-2.551 2.434-1.72 3.13-4.218 3.13-.47 0-1.409-1.788-1.409-2.238 0-3.408-1.79-4.025-3.279-4.025-1.407 0-1.407-1.789-1.407-1.789s0-3.133 2.343-3.58c2.343-.446 2.343.447 2.343.447.969.925 4.404-.888 1.266-3.218-1.16-.863 4.233-2.598 4.233-2.598"
                                stroke="#5BDDB8"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M23.494 12.5c0 6.075-4.924 11-11 11-6.075 0-11-4.925-11-11 0-6.076 4.925-11 11-11 6.076 0 11 4.924 11 11h0z"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                    </svg>
                    <span>{{ 'Navigation.SwitchLanguage' | translate }}</span>
                </button>
            </div>
        </div>
        <button (click)="logoutFromCognito()" *ngIf="user" [title]="'Navigation.Logout' | translate">
            <svg height="23" width="21" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M-4-4h24v24H-4z" />
                    <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
                        <path
                            d="M14.154 3.92A9.507 9.507 0 0120 12.694c0 5.245-4.255 9.5-9.5 9.5-5.247 0-9.5-4.255-9.5-9.5A9.503 9.503 0 016.802 3.94M10.5 1.001v9.5"
                        />
                    </g>
                </g>
            </svg>
        </button>
        <a *ngIf="!user" class="button button--invert" routerLink="/login">
            {{ 'Navigation.Login' | translate }}</a
        >
        <a *ngIf="!user" class="button" routerLink="/login">{{ 'Navigation.RegisterClub' | translate }}</a>
    </ng-container>
</app-navigation>
