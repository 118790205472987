import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { ClubHero } from '../../core/interfaces/club-hero.interface';

@Component({
    selector: 'app-heroes',
    templateUrl: './heroes.component.html',
    styleUrls: ['./heroes.component.scss'],
})
export class HeroesComponent implements OnInit {
    @Input() public heroes: ClubHero[];
    @Input() public sortKey: 'distance' | 'time';

    public heroUsers: ClubHero[];
    public showAll = false;
    public userId: number | undefined;

    public constructor(private readonly userService: UserService) {}

    public ngOnInit() {
        this.heroUsers = [...this.heroes.sort((a, b) => b[this.sortKey] - a[this.sortKey])];
        this.userId = this.userService.currentUser().id;
    }

    public toggleShowAll() {
        this.showAll = !this.showAll;
    }
}
