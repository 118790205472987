import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'distance',
})
export class DistancePipe implements PipeTransform {
    public transform(meters: number): number {
        return meters / 1000;
    }
}
