<div class="particulate-banner">
    <div class="icon">
        <svg width="144" height="189" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
                <g fill-rule="nonzero">
                    <g fill="#02364D">
                        <path
                            d="M69.06 89.632c11.538-.767 22.91 2.562 32.184 9.477.96.716 1.15 2.064.423 3.011a2.204 2.204 0 01-3.056.417c-8.434-6.289-18.767-9.313-29.257-8.616-1.202.08-2.243-.815-2.324-2-.08-1.184.828-2.21 2.03-2.29zM141.818 110.568c1.205 0 2.182.962 2.182 2.15 0 7.753-6.4 14.043-14.284 14.043-1.205 0-2.181-.962-2.181-2.15 0-1.187.976-2.15 2.181-2.15 5.477 0 9.92-4.367 9.92-9.744 0-1.187.977-2.15 2.182-2.15z"
                        />
                        <path
                            d="M26.905 68.837c10.78-6.105 24.278-2.889 31.092 6.985l.175.257.758-.148c36.973-6.947 72.241 16.086 72.957 47.6l.011.977c0 18.765-12.411 35.036-30.72 43.538v18.782c0 1.187-.977 2.15-2.182 2.15-1.162 0-2.111-.896-2.178-2.024l-.003-.126v-16.946c-7.56 2.863-15.957 4.456-24.793 4.456-8.78 0-17.211-1.59-24.836-4.486v16.976c0 1.187-.977 2.15-2.182 2.15-1.162 0-2.112-.896-2.178-2.024l-.004-.126V168.01c-11.027-5.134-20.009-13.134-25.369-23.026l-.238-.446H2.182c-1.162 0-2.112-.895-2.178-2.023L0 142.388v-23.84c0-1.187.977-2.15 2.182-2.15h10.757l.06-.3c2.55-12.435 10.7-23.5 22.632-31.103l.368-.232-.046-.205c-1.157-4.94-4.334-9.213-8.772-11.834l-.279-.161a2.134 2.134 0 01.003-3.726zm28.316 10.72c-4.588-7.968-14.185-11.382-22.723-8.519l-.139.048.203.167c4.213 3.525 7.078 8.439 7.953 13.917l.053.35a2.139 2.139 0 01-1.034 2.143c-12.459 7.394-20.699 18.66-22.599 31.202a2.172 2.172 0 01-2.158 1.833H4.364v19.54H18.56c.788 0 1.51.419 1.896 1.087l.068.128c8.386 17.095 28.61 28.586 51.497 28.586 30.738 0 55.513-20.474 55.513-45.53 0-29.694-34.312-51.797-69.938-43.915a2.194 2.194 0 01-2.376-1.037z"
                        />
                        <path
                            d="M38.953 110.568c-4.537 0-8.233 3.642-8.233 8.096 0 4.471 3.689 8.097 8.233 8.097 4.544 0 8.232-3.626 8.232-8.097 0-4.454-3.695-8.096-8.232-8.096zm0 4.299c2.126 0 3.869 1.717 3.869 3.797 0 2.095-1.733 3.798-3.87 3.798-2.136 0-3.868-1.703-3.868-3.798 0-2.08 1.742-3.797 3.869-3.797z"
                        />
                    </g>
                    <path
                        d="M83.273 37.074c-6.628 0-12 5.293-12 11.823s5.372 11.823 12 11.823 12-5.293 12-11.823-5.372-11.823-12-11.823zm0 2.867c5.021 0 9.09 4.009 9.09 8.956 0 4.947-4.069 8.957-9.09 8.957-5.022 0-9.091-4.01-9.091-8.957 0-4.947 4.07-8.956 9.09-8.956zM100 16.654c-4.017 0-7.273 3.207-7.273 7.165 0 3.957 3.256 7.165 7.273 7.165 4.017 0 7.273-3.208 7.273-7.165 0-3.958-3.256-7.165-7.273-7.165zm0 2.15c2.812 0 5.09 2.244 5.09 5.015 0 2.77-2.278 5.015-5.09 5.015s-5.09-2.245-5.09-5.015c0-2.77 2.278-5.016 5.09-5.016z"
                        fill="#5BDDB8"
                    />
                </g>
                <path
                    d="M79 .295a5 5 0 100 10 5 5 0 100-10zm0 1.429a3.57 3.57 0 110 7.143 3.57 3.57 0 110-7.143z"
                    fill="#5BDDB8"
                    fill-rule="nonzero"
                />
            </g>
        </svg>
    </div>
    <div class="text">
        <h1>{{ headline }}</h1>
        <p [innerHTML]="text"></p>
        <a
            class="button"
            href="https://www.socialfunders.org/remoterun"
            target="_blank"
            (click)="trackEvent()"
        >
            {{ 'ParticulateBanner.Button' | translate }}
        </a>
    </div>
</div>
