import { Component, Input } from '@angular/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { NotificationService } from '../../core/services/notification.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    animations: [
        trigger('heightAnimation', [
            state(
                'open',
                style({
                    height: AUTO_STYLE,
                }),
            ),
            state(
                'closed',
                style({
                    height: '0px',
                }),
            ),
            transition('open => closed', [animate('0.2s ease-in-out')]),
            transition('closed => open', [animate('0.2s ease-in-out')]),
        ]),
    ],
})
export class NavigationComponent {
    @Input() public mobileNavbarOpen = false;

    public constructor(private notificationService: NotificationService) {}

    public switchLanguage() {
        this.notificationService.switchLanguage();
    }
}
