import { Testimonial } from '../core/interfaces/testimonial.interface';

export const testimonials: Testimonial[] = [
    {
        name: '247GRAD',
        translateKey: 'testimonial.247grad',
        image: 'assets/testimonials/247grad-gerrit.png',
    },
    {
        name: 'Evident GmbH',
        translateKey: 'testimonial.evident',
    },
    {
        name: 'Dirico.io',
        translateKey: 'testimonial.dirico',
        image: 'assets/testimonials/philippscherber.jpeg',
    },
    {
        name: 'Thinkport',
        translateKey: 'testimonial.thinkport',
        image: 'assets/testimonials/thinkport-janinaheidel.png',
    },
    {
        name: 'Cocomore',
        translateKey: 'testimonial.cocomore',
        image: 'assets/testimonials/chip-cocomore.jpeg',
    },
    {
        name: 'Network WF',
        translateKey: 'testimonial.networkwf',
        image: 'assets/testimonials/timoberlies.jpeg',
    },
];
