import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GuidedTourService {
    public constructor(
        @Inject('env') private environment: any,
        private httpService: HttpClient,
        @Inject('document') private document: Document,
    ) {}

    public getUnseenTourVersion(lastSeenVersion: number): any {
        if (lastSeenVersion === 0) {
            return 1;
        }

        if (lastSeenVersion >= this.environment.guidedTourVersion) {
            return null;
        }

        // find latest version(s)
        // return lastSeenVersion + 1 if lastSeenVersion < environment.guideTourVersion
        return lastSeenVersion + 1;
    }

    public buildSlidesForTourVersion(tourVersion: number): any {
        return this.httpService
            .get<any>('/assets/guided-tour/' + tourVersion + '/' + tourVersion + '.json')
            .pipe(
                map((result: { version: number; slides: any[] }) => {
                    if (result.version !== tourVersion) {
                        throwError({
                            status: 404,
                            message: 'Something went terribly wrong fetching the current tour.',
                        });
                    }

                    return result.slides.map((slide: any, index: number) => ({
                        ...slide,
                        image: 'assets/guided-tour/' + tourVersion + '/' + (index + 1) + '.jpg',
                    }));
                }),
            );
    }

    public updateTourIsOpen(isOpen: boolean) {
        if (isOpen) {
            this.document.body.classList.add('modal-open');
        } else {
            this.document.body.classList.remove('modal-open');
        }
    }
}
