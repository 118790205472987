<div class="slider">
    <div class="glide">
        <div class="glide__track" data-glide-el="track">
            <ul #slides class="glide__slides">
                <ng-content></ng-content>
            </ul>
            <div
                [ngClass]="{ 'is-disabled': getAmountOfSliderItems() === childrenLength }"
                class="glide__bullets"
            >
                <button
                    (click)="goToSlide(i)"
                    *ngFor="let node of getDots(); let i = index"
                    [ngClass]="{ 'glide__bullet--active': glider.index === i }"
                    class="glide__bullet"
                ></button>
            </div>
        </div>
        <div *ngIf="showArrows" class="glide__arrows" data-glide-el="controls">
            <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 .504c6.351 0 11.5 5.15 11.5 11.5 0 6.352-5.149 11.5-11.5 11.5S.5 18.356.5 12.004 5.649.504 12 .504Zm2.5 4-9 7.5 9 7.5v-4l-4.5-3.5 4.5-3.5v-4Z"
                        fill="#03384D"
                        fill-rule="evenodd"
                    />
                </svg>
            </button>
            <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 .504c6.351 0 11.5 5.15 11.5 11.5 0 6.352-5.149 11.5-11.5 11.5S.5 18.356.5 12.004 5.649.504 12 .504Zm-2.5 4v4l4.5 3.5-4.5 3.5v4l9-7.5-9-7.5Z"
                        fill="#03384D"
                        fill-rule="evenodd"
                    />
                </svg>
            </button>
        </div>
    </div>
</div>
