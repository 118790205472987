import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-stats-category-fact',
    templateUrl: './stats-category-fact.component.html',
    styleUrls: ['./stats-category-fact.component.scss'],
})
export class StatsCategoryFactComponent implements OnInit {
    @Input() public iconPath: string;
    @Input() public distance: number;
    @Input() public name: string;
    @Input() public count: number;
    @Input() public unit = 'KM';
    @Input() public pulse: number[] = [];
    @Input() public factor = 1;
    public diagramData: number[];
    public maxData: number;

    public ngOnInit() {
        const data = Array.from(Array(46).keys());

        const getIndex = (index: number) => Math.trunc((this.pulse.length * index) / data.length);

        this.diagramData = data.map((_d, i) =>
            this.pulse.slice(getIndex(i), getIndex(i + 1)).reduce((sum, day) => sum + day, 0),
        );

        this.maxData = Math.max(...this.diagramData);
    }
}
