import { Component, Input, OnChanges } from '@angular/core';
import {
    filterActivitiesForMonth,
    getAverageDistanceForMonth,
    getMonthDayList,
} from './tile-calendar.helper';
import { DateService } from '../../core/services/date.service';
import { CalendarDayInterface } from '../../core/interfaces/calendar-day.interface';
import { ActivityFE } from '../../core/interfaces/activity.interface';

@Component({
    selector: 'app-tile-calendar',
    templateUrl: './tile-calendar.component.html',
    styleUrls: ['./tile-calendar.component.scss'],
})
export class TileCalendarComponent implements OnChanges {
    @Input() public activities: ActivityFE[] = [];

    public date = new Date();
    public selectedMonth = this.date.getMonth();
    public selectedYear = this.date.getFullYear();
    public filteredActivities: (ActivityFE & { dateObject: Date })[];
    public monthDays: any[] = [];
    private averageDistanceForMonth: number;

    public constructor(private dateService: DateService) {}

    public ngOnChanges(): void {
        this.updateValues();
    }

    private updateValues() {
        if (this.activities) {
            this.filteredActivities = filterActivitiesForMonth(
                this.activities,
                this.selectedMonth,
                this.selectedYear,
            );
            this.monthDays = getMonthDayList(this.filteredActivities, this.selectedMonth, this.selectedYear);
            this.averageDistanceForMonth = getAverageDistanceForMonth(this.filteredActivities);
            this.date = new Date(this.selectedYear, this.selectedMonth);
        }
    }

    public prevMonth() {
        if (this.selectedMonth === 0) {
            this.selectedMonth = 11;
            this.selectedYear--;
        } else {
            this.selectedMonth--;
        }
        this.updateValues();
    }

    public nextMonth() {
        if (this.selectedMonth === 11) {
            this.selectedMonth = 0;
            this.selectedYear++;
        } else {
            this.selectedMonth++;
        }
        this.updateValues();
    }

    public generateClassName = (day: CalendarDayInterface) => {
        let className = '';

        if (day.isToday) {
            className += 'is-today ';
        }

        if (!day.isInMonth) {
            return className + 'not-month';
        }
        if (day.distance === 0) {
            return className + 'low';
        }
        if (day.distance >= this.averageDistanceForMonth) {
            return className + 'high';
        }
        return className + 'middle';
    };

    public getWeeks = () =>
        this.monthDays.reduce<CalendarDayInterface[][]>((weeks, day, index) => {
            if (index % 7 === 0) {
                return [...weeks, [day]];
            } else {
                weeks[weeks.length - 1].push(day);
                return weeks;
            }
        }, []);

    public getWeekDistance = (week: CalendarDayInterface[]) =>
        week.reduce((distance, day) => distance + day.distance, 0) / 1000;

    public getWeekTime = (week: CalendarDayInterface[]) => week.reduce((time, day) => time + day.time, 0);

    public getMonthDistance = () => this.monthDays.reduce((sum, day) => sum + day.distance, 0) / 1000;

    public getMonthTime = () => this.monthDays.reduce((time, day) => time + day.time, 0);

    public getSelectedMonthName = () => {
        return this.dateService.longMonthNames[this.date.getMonth()];
    };
}
