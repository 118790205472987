<div class="total-facts">
    <div class="total-facts__fact">
        <app-stats-facts4
            [value]="amountAthletes"
            [label]="'TotalFacts.RegisteredUsers' | translate"
        ></app-stats-facts4>
    </div>
    <div class="total-facts__fact">
        <app-stats-facts4
            [value]="amountActivities"
            [label]="'Common.Activities' | translate"
        ></app-stats-facts4>
    </div>
    <div class="total-facts__fact">
        <app-stats-facts3
            [value]="totalKm | distance"
            [label]="'Common.TotalDistance' | translate"
            unit="KM"
        ></app-stats-facts3>
    </div>
    <div class="total-facts__fact">
        <app-stats-facts3
            [value]="donation"
            unit="EUR"
            [label]="'Common.TotalDonationAmount' | translate"
            theme="mint"
            [large]="true"
        ></app-stats-facts3>
    </div>
</div>
