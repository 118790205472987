import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    public showLoader = new BehaviorSubject(false);

    public displayLoader() {
        this.showLoader.next(true);
    }

    public hideLoader() {
        this.showLoader.next(false);
    }
}
