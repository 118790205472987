import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { DemoUser } from '../interfaces/demo-users';
import { TenantFE } from '../interfaces/tenant.interface';

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    private tenantData: TenantFE = {
        id: 1,
        name: '',
        period_end_date: 0,
        period_start_date: 0,
        slug: 'charity',
        cognitoDomain: '',
        cognitoAppId: '',
        userPoolId: '',
    };

    public constructor(
        private httpClient: HttpClient,
        @Inject('env') private env: any,
        @Inject(Window) private window: Window,
        @Inject('document') private document: Document,
    ) {}

    public verifyAndLoadTenant(): Observable<boolean> {
        const subdomain = this.window.location.hostname.split('.')?.[0] ?? 'www';
        const tenantSlug = ['remoterun', 'dev'].includes(subdomain) ? 'www' : subdomain;

        return this.httpClient.get<TenantFE>(`${this.env.api}/tenants/${tenantSlug}`).pipe(
            tap(tenantData => (this.tenantData = tenantData)),
            tap(tenantData => this.updateTenantStyles(tenantData)),
            map(tenantData => !!tenantData),
        );
    }

    public get tenant() {
        return this.tenantData;
    }

    private updateTenantStyles(tenant: TenantFE) {
        const style = this.document.createElement('link');
        style.rel = 'stylesheet';
        style.href = `assets/tenants/${tenant.slug}/styles.css`;
        this.document.head.appendChild(style);
    }

    public getAllTenants(): Observable<TenantFE[]> {
        return this.httpClient.get<TenantFE[]>(`${this.env.api}/tenants`);
    }

    public updateTenant(
        tenant: Omit<TenantFE, 'cognitoDomain' | 'cognitoAppId' | 'userPoolId'>,
    ): Observable<any> {
        return this.httpClient.patch(`${this.env.api}/tenants/${tenant.slug}`, tenant);
    }

    public createTenant(
        tenant: Omit<TenantFE, 'cognitoDomain' | 'cognitoAppId' | 'userPoolId'>,
    ): Observable<any> {
        return this.httpClient.post(`${this.env.api}/tenants`, tenant);
    }

    public removeTenantAdmin(email: string, tenantId: number, slug: string): Observable<any> {
        return this.httpClient.patch(`${this.env.api}/tenants/${slug}/admin`, { email, id: tenantId });
    }

    public getDemoUsers(): Observable<DemoUser[]> {
        return this.httpClient.get<DemoUser[]>(`${this.env.api}/admin/demo-tenant/users`);
    }
}
