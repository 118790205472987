<h2>{{ 'Home.FAQ' | translate }}</h2>
<h3>{{ 'Home.FAQIntro' | translate }}</h3>

<div class="faq__questions">
    <div class="faq__question">
        <h4>{{ 'Home.FAQCosts' | translate }}</h4>
        <p [innerHTML]="'Home.FAQCostsText' | translate"></p>
    </div>
    <div class="faq__question">
        <h4>{{ 'Home.FAQConfirmation' | translate }}</h4>
        <p [innerHTML]="'Home.FAQConfirmationText' | translate"></p>
    </div>
    <div class="faq__question">
        <h4>{{ 'Home.FAQStrava' | translate }}</h4>
        <p [innerHTML]="'Home.FAQStravaText' | translate"></p>
    </div>
    <div class="faq__question">
        <h4>{{ 'Home.FAQStrava2' | translate }}</h4>
        <p [innerHTML]="'Home.FAQStrava2Text' | translate"></p>
    </div>
    <div class="faq__question">
        <h4>{{ 'Home.FAQBikeSwim' | translate }}</h4>
        <p [innerHTML]="'Home.FAQBikeSwimText' | translate"></p>
    </div>
    <div class="faq__question">
        <h4>{{ 'Home.FAQRegisterDuringIteration' | translate }}</h4>
        <p [innerHTML]="'Home.FAQRegisterDuringIterationText' | translate"></p>
    </div>
    <div class="faq__question">
        <h4>{{ 'Home.FAQDonationRequired' | translate }}</h4>
        <p [innerHTML]="'Home.FAQDonationRequiredText' | translate"></p>
    </div>
    <div class="faq__question">
        <h4>{{ 'Home.FAQPrivateActivities' | translate }}</h4>
        <p [innerHTML]="'Home.FAQPrivateActivitiesText' | translate"></p>
    </div>
</div>
