import { Component, Inject, Input, ViewChild } from '@angular/core';
import { AnalyticsService } from '../../core/services/analytics.service';
import { NotificationService } from '../../core/services/notification.service';

@Component({
    selector: 'app-invitation-banner',
    templateUrl: './invitation-banner.component.html',
    styleUrls: ['./invitation-banner.component.scss'],
})
export class InvitationBannerComponent {
    @Input() public inviteHash: string;
    @ViewChild('invitationLink') private invitationLinkElement: any;

    public constructor(
        private analyticsService: AnalyticsService,
        private notificationService: NotificationService,
        @Inject('document') private document: Document,
    ) {}

    public getInvitationPath(): string {
        return `${location.protocol}//${location.host}/invitation/${this.inviteHash}`;
    }

    public textToClipBoard() {
        this.analyticsService.trackEvent({
            type: 'EVENT',
            action: 'copy_invitation_link',
            category: 'club-management',
            label: 'Einladungslink kopieren',
        });
        this.invitationLinkElement.nativeElement.select();
        this.document.execCommand('copy');
        this.notificationService.info('Notify.InvitationLinkInClipboard');
    }
}
