<div class="legal">
    <h1>Nutzungs&shy;bedingungen für die Teilnahme bei RemoteRun</h1>

    <h2>1. Geltungsbereich der Nutzungsbedingungen</h2>
    <p>
        Diese Nutzungsbedingungen gelten für die Teilnahme an dem Online-Angebot „RemoteRun“, das unter
        https://www.[SUBDOMAIN].remoterun.de.) abrufbar ist (nachfolgend auch „Portal“ genannt). RemoteRun ist
        eine Plattformlösung der mediaman GmbH, Taunusstraße 57, 55118 Mainz (nachfolgend „Betreiber“).
        <br />
        Die nachfolgenden Nutzungsbedingungen gelten sowohl für Endnutzer als auch für Unternehmen, welche
        RemoteRun ihren Mitarbeiter*innen zur Verfügung stellen.
    </p>

    <h2>2. Vertragsschluss und Benutzerkonto</h2>
    <ol>
        <li>
            Durch Abschluss des Online-Registrierungsvorgangs und Erstellung eines Profils kommt ein
            Nutzungsvertrag mit dem Betreiber zustande. Gegenstand des Nutzungsvertrages ist die kostenlose
            Nutzung des Profils.
        </li>
        <li>
            Für die Erstellung eines Profils ist die Erstellung eines Benutzerkontos erforderlich. Dieses
            besteht aus einem Benutzernamen und einem Kennwort („Log-in-Daten“).
        </li>
        <li>
            Die Erstellung eines Benutzerkontos ist nur unter Angabe einer aktuellen E-Mail-Adresse des
            Nutzers möglich. Diese E-Mail-Adresse dient zugleich der Kommunikation mit dem Betreiber.
        </li>
        <li>
            Der Nutzer sichert zu, dass die bei Erstellung seines Profils verwendeten Daten („Profil-Daten“)
            zutreffend und vollständig sind.
        </li>
        <li>Vertragssprache ist ausschließlich deutsch.</li>
    </ol>

    <h2>3. Nutzung des Profils</h2>
    <ol>
        <li>
            Der Nutzer kann entweder einem bereits existierenden Club beitreten oder einen neuen Club gründen.
            Es obliegt dabei dem jeweiligen Club-Admin, über die Aufnahme in den Club zu entscheiden.
            Ansprüche in Bezug auf die Aufnahme oder Ablehnung eines Clubs gegenüber dem Betreiber und/oder
            dem Partner sind ausgeschlossen.
        </li>
        <li>
            Bei der Nutzung des Profils kann der Nutzer verschiedene Dienste in Anspruch nehmen und besitzt
            die Möglichkeit, eigene Aktivitäten innerhalb des Portals zu veröffentlichen.
        </li>
        <li>
            Der Betreiber ist jederzeit berechtigt, den Zugang zu einzelnen Inhalten zu sperren, z.B. wenn der
            Verdacht besteht, dass diese gegen geltenden Recht oder Rechte Dritter verstoßen. Es besteht kein
            Anspruch des Nutzers auf Aufrechterhaltung einzelner Funktionalitäten des Portals.
        </li>
        <li>
            Der Betreiber ist um einen störungsfreien Betrieb des Portals bemüht. Dies beschränkt sich
            naturgemäß auf Leistungen, auf die der Betreiber einen Einfluss hat. Dem Betreiber ist es
            unbenommen, den Zugang zu dem Portal aufgrund von Wartungsarbeiten, Kapazitätsbelangen und
            aufgrund anderer Ereignisse, die nicht in seinem Machtbereich stehen, ganz oder teilweise,
            zeitweise oder auf Dauer, einzuschränken.
        </li>
    </ol>

    <h2>4. Datenschutz / Kontaktaufnahme / Bestandskundenwerbung</h2>
    <ol>
        <li>
            Der Nutzer wird Rahmen der Nutzung der RemoteRun Plattform vom Betreiber über seinen sportlichen
            Erfolg informiert.
        </li>
        <li>
            Darüber hinaus darf der Betreiber, die vom Nutzer im Rahmen der Registrierung übermittelten
            personenbezogenen Daten (z.B. Name, Anschrift und E-Mail-Adresse) nutzen, um im Rahmen der
            zulässigen Bestandskundenwerbung per E-Mail und Post auf vergleichbare Angebote hinzuweisen.
        </li>
        <li>
            Der Nutzung der personenbezogenen Daten zu diesem Zwecke kann jederzeit mit Wirkung für die
            Zukunft widersprochen werden, zum Beispiel per E-Mail an wasgeht&#64;remoterun.de.
        </li>
        <li>
            Weitere Informationen über Art, Umfang, Ort und Zweck der Erhebung, Verarbeitung und Nutzung der
            erforderlichen personenbezogenen Daten finden sie in unserer Datenschutzerklärung, abrufbar unter
            https://www.remoterun.de/legal/dataprivacy.
        </li>
    </ol>

    <h2>5. Mitwirkungspflicht des Nutzers: Einstellen von Inhalten</h2>
    <ol>
        <li>
            Der Nutzer verpflichtet sich, bei der Erstellung und Verwendung eigener Inhalte geltendes Recht
            (z.B. Straf-, Wettbewerbs- und Jugendschutzrecht) zu beachten und keine Rechte Dritter (z.B.
            Namens-, Marken-, Urheber- und Datenschutzrechte) zu verletzen.
        </li>
        <li>
            Der Nutzer verpflichtet sich gegenüber dem Betreiber, dass jedwede Inhalte, die in das Portal
            eingestellt werden, weder durch ihren Inhalt oder die Form gegen geltendes Recht oder die guten
            Sitten verstoßen. Das Gleiche gilt für das Setzen von externen Links. Nicht erlaubt ist
            insbesondere das Verbreiten von Inhalten, die
            <ul>
                <li>Rassismus</li>
                <li>Gewaltverherrlichung und Extremismus irgendwelcher Art</li>
                <li>
                    Aufrufe und Anstiftung zu Straftaten und Gesetzesverstößen, Drohungen gegen Leib, Leben
                    oder Eigentum
                </li>
                <li>Hetzen gegen Personen oder Unternehmen</li>
                <li>
                    persönlichkeitsverletzende Äußerungen, Verleumdung, Ehrverletzung und üble Nachrede von
                    Nutzern und Dritten sowie Verstöße gegen das Lauterkeitsrecht
                </li>
                <li>urheberrechtsverletzende Inhalte oder andere Verletzungen von Immaterialgüterrechten</li>
                <li>sexuelle Belästigung von Nutzerinnen und Nutzern und Dritten</li>
                <li>Pornografie</li>
                <li>
                    anstößige, sexistische, obszöne, vulgäre, abscheuliche oder ekelerregende Materialien und
                    Ausdrucksweisen
                </li>
            </ul>
            darstellen, betreffen oder beinhalten.
        </li>
        <li>
            Urheberrechtlich geschützte Inhalte dürfen ohne Zustimmung des jeweiligen Rechteinhabers nur im
            Rahmen des anwendbaren Zitatrechts wörtlich in Beiträge aufgenommen werden. Zitate sind durch
            Hervorheben mittels Zitatfunktion und Quellenangabe zu kennzeichnen. Fremdsprachige Zitate sind
            zusätzlich soweit auf Deutsch zu übersetzen, dass der Inhalt grob ersichtlich ist. Insbesondere
            nicht korrekt zitierte Beiträge können von den Moderatoren entfernt oder berichtigt werden. Die
            Verbreitung und/oder öffentliche Wiedergabe von jedweden Inhalten des Portals ohne Einwilligung
            des Betreibers ist untersagt.
        </li>
    </ol>

    <h2>6. Weitere Mitwirkungspflichten des Nutzers</h2>
    <ol>
        <li>
            Der Nutzer darf, ohne ausdrückliche Gestattung des Betreibers, das Portal nur für private Zwecke
            nutzen und keine Werbung für sich oder Dritte machen. Das bedeutet insbesondere, dass der Nutzer
            keine Nachrichten werbenden Inhalts ohne eine Einwilligung des Betreibers und des Empfängers
            verwenden darf (insbesondere: Spam-Nachrichten).
        </li>
        <li>
            Für den Fall, dass der Nutzer die Möglichkeit nutzt, Dritte über die Existenz des Portals über die
            von dem Betreiber bereitgestellte Empfehlungsfunktion zu informieren, hat er sicherzustellen, dass
            der Dritte mit der Übersendung der werbenden Empfehlungs-E-Mail einverstanden ist.
        </li>
        <li>
            Für den Fall, dass die Inhalte Hyperlinks auf Seiten Dritter enthalten, sichert der Nutzer zu,
            dass er die Berechtigung zur Nutzung des Hyperlinks hat und die Website, auf die verweisen wird
            („Landingpage“), mit geltendem Recht und Rechten Dritter vereinbar ist.
        </li>
        <li>
            Der Nutzer ist verpflichtet, mit den Log-in-Daten sorgfältig umzugehen. Dem Nutzer ist es
            ausnahmslos untersagt, die Log-in-Daten Dritten mitzuteilen und/oder Dritten den Zugang zu dem
            Profil unter Umgehung der Log-in-Daten zu ermöglichen.
        </li>
        <li>
            Der Nutzer muss jedwede Tätigkeit unterlassen, die geeignet ist, den Betrieb des Portals oder der
            dahinterstehenden technischen Infrastruktur zu beeinträchtigen und/oder übermäßig zu belasten.
            Dazu zählen insbesondere:
            <ul>
                <li>
                    die Verwendung von Software, Skripten oder Datenbanken in Verbindung mit der Nutzung des
                    Portals; sowie
                </li>
                <li>
                    das automatische Auslesen, Blockieren, Überschreiben, Modifizieren, Kopieren von Daten
                    und/oder sonstigen Inhalten, soweit dies nicht für die ordnungsgemäße Nutzung des Portals
                    erforderlich ist;
                </li>
            </ul>
        </li>
        <li>
            Persönlichkeitsverletzend und deshalb nicht zulässig ist es zudem, die Anonymität anderer Nutzer
            aufzuheben oder Informationen von anderen Nutzern aus privaten Nachrichten, E-Mails oder Chats
            bekanntzugeben, die nicht für die Öffentlichkeit bestimmt sind. Nutzer dürfen keine Informationen
            in ihre Beiträge aufnehmen oder sonst wie bekannt machen, die Aufschluss über die Identität eines
            anderen Nutzers geben könnten oder die der Nutzer von anderen Nutzern ausschließlich in privaten
            Nachrichten, E-Mails oder Chats erhalten hat.
        </li>
        <li>
            Sollte es bei der Nutzung des Portals oder seiner Funktionalitäten zu Störungen kommen, wird der
            Nutzer den Betreiber von dieser Störung unverzüglich in Kenntnis setzen. Gleiches gilt, wenn der
            Nutzer Informationen über von Dritten veröffentlichte Inhalte erlangt, die offensichtlich gegen
            geltendes Recht oder die Rechte Dritter verstoßen.
        </li>
    </ol>

    <h2>7. Nutzungsrechte</h2>
    <ol>
        <li>
            Der Nutzer räumt dem Betreiber ein räumlich und zeitlich unbeschränktes, unwiderrufliches auf
            Dritte übertragbares, nicht exklusives, unentgeltliches Recht ein, die eingestellten Inhalte im
            Rahmen des Betriebes des Portals zu verwerten. Das schließt insbesondere das
            Vervielfältigungsrecht, das Verbreitungsrecht und das Recht der öffentlichen Wiedergabe,
            insbesondere das Recht der öffentlichen Zugänglichmachung mit ein. Der Nutzer verzichtet auf das
            Recht zur Urhebernennung.
        </li>
        <li>
            Sämtliche Rechte an den Inhalten des Portals liegen bei dem Betreiber. Dem Nutzer ist die
            Vervielfältigung, Verbreitung und/oder Veröffentlichung von Inhalten untersagt, die der Betreiber,
            andere Nutzer oder Dritte in das Portal eingestellt haben.
        </li>
    </ol>

    <h2>8. Haftung</h2>
    <ol>
        <li>
            Für Ansprüche aufgrund mangelhafter Leistungen an der kostenlos zu Nutzung überlassenen Anwendung
            „RemoteRun“ gelten die gesetzlichen Bestimmungen. Aufgrund der unentgeltlichen Nutzung ist gemäß §
            523, 524 BGB die Sach- und Rechtsmängelhaftung ausgeschlossen, es sei denn, es liegt eine
            vorsätzliche oder grob fahrlässige Pflichtverletzung oder Arglist vor.
        </li>
        <li>
            Die vorstehenden Haftungsbeschränkungen gelten auch für die persönliche Haftung unserer
            Mitarbeiter, Vertreter, Organe sowie aller unserer Erfüllungs- und Verrichtungsgehilfen.
        </li>
        <li>
            Weiterhin bestehen hinsichtlich der Funktionen der Anwendung Remote Run keine Garantie-,
            Entschädigungs-, Wartungs-, Support- oder Verfügbarkeitsverpflichtungen.
        </li>
        <li>
            Der Betreiber behält sich das Recht vor, die Dienste jederzeit und ohne Vorankündigung zu
            überarbeiten oder den Zugriff darauf zu verweigern.
        </li>
        <li>
            Die Plattform motiviert die Nutzer dazu, körperliche Aktivitäten durch Laufen, Spazieren, Gehen,
            Schwimmen, Fahrrad fahren oder Wandern auszuüben. Der Betreiber der Plattform haftet nicht für
            körperliche Verletzungen oder andere Schäden durch die Aktivitäten. Jeder Nutzer nimmt auf seine
            eigene körperliche Verantwortung teil.
        </li>
        <li>
            Die Plattform steht in keinem Zusammenhang mit STRAVA. Die Nutzung des STRAVA Accounts erfolgt
            ausschließlich auf eigene Verantwortung des Nutzers und auf Grundlage der STRAVA
            Nutzungsbedingungen. Die Geltendmachung von Ansprüchen, die aus der Nutzung des STRAVA Accounts
            resultieren, gegenüber dem Betreiber und/oder dem Partner ist ausgeschlossen.
        </li>
    </ol>

    <h2>9. Freistellungsanspruch</h2>
    <p>
        Der Nutzer stellt den Betreiber und seine Mitarbeiter bzw. Beauftragten für den Fall der
        Inanspruchnahme wegen vermeintlicher oder tatsächlicher Rechtsverletzung und/oder Verletzung von
        Rechten Dritter durch von dem Nutzer im Zusammenhang mit der Nutzung des Portals vorgenommenen
        Handlungen von sämtlichen sich daraus ergebenen Ansprüchen Dritter frei. Darüber hinaus verpflichtet
        sich der Nutzer, alle Kosten zu ersetzen, die dem Betreiber durch die Inanspruchnahme durch Dritte
        entstehen. Zu den erstattungsfähigen Kosten zählen auch die Kosten einer angemessenen
        Rechtsverteidigung.Der Nutzer stellt den Betreiber und seine Mitarbeiter bzw. Beauftragten für den
        Fall der Inanspruchnahme wegen vermeintlicher oder tatsächlicher Rechtsverletzung und/oder Verletzung
        von Rechten Dritter durch von dem Nutzer im Zusammenhang mit der Nutzung des Portals vorgenommenen
        Handlungen von sämtlichen sich daraus ergebenen Ansprüchen Dritter frei. Darüber hinaus verpflichtet
        sich der Nutzer, alle Kosten zu ersetzen, die dem Betreiber durch die Inanspruchnahme durch Dritte
        entstehen. Zu den erstattungsfähigen Kosten zählen auch die Kosten einer angemessenen
        Rechtsverteidigung.
    </p>

    <h2>10. Vertragsdauer/Kündigung</h2>
    <ol>
        <li>
            Der Vertrag läuft auf unbestimmte Zeit und kann von beiden Seiten jederzeit ohne Einhaltung einer
            Kündigungsfrist und Angabe von Gründen gekündigt werden.
        </li>
        <li>
            Daneben und darüber hinaus bleibt das Recht der Parteien, das Vertragsverhältnis durch
            außerordentliche Kündigung aus wichtigem Grund zu beenden, unbenommen.
        </li>
        <li>
            Für den Betreiber liegt ein wichtiger Grund zur Kündigung dieses Vertrages vor, wenn der Kunde
            seine Verpflichtungen nach Ziff. 4 oder 5 dieses Vertrages nachhaltig verletzt.
        </li>
    </ol>

    <h2>11. Schlussbestimmungen</h2>
    <ol>
        <li>
            Sofern einzelne Klauseln der vorstehenden Allgemeinen Geschäftsbedingungen ganz oder teilweise
            unwirksam sein sollten, bleibt die Wirksamkeit der übrigen Klauseln hiervon unberührt. Die
            unwirksame Bestimmung gilt durch eine solche ersetzt, die dem Sinn und Zweck der unwirksamen
            Bestimmung in rechtswirksamer Weise wirtschaftlich und rechtlich am nächsten kommt.
        </li>
        <li>
            Abweichende Bedingungen des Kunden können keine Anwendung beanspruchen, es sei denn, der Betreiber
            hat ihrer Geltung vor Vertragsschluss dem Nutzer gegenüber schriftlich oder in Textform
            zugestimmt.
        </li>
        <li>
            Es gilt deutsches Recht. Für Verbraucher gehen dabei günstigere Normen des an ihrem gewöhnlichen
            Aufenthaltsort geltenden Rechts dem deutschen Recht vor.
        </li>
        <li>
            Sofern der Nutzer Kaufmann, eine Person des öffentlichen Rechts oder öffentlich-rechtliches
            Sondervermögen ist, wird als Gerichtsstand Frankfurt am Main vereinbart.
        </li>
    </ol>
</div>
