<div class="stats-month-km">
    <ng-content></ng-content>

    <div class="stats-month-km__chart">
        <div class="month__legend">{{ goalKm | number: '1.0-2' }} km</div>
        <div class="month" *ngFor="let month of monthList">
            <span class="month__name">{{ getMonthName(month) }}</span>
            <div class="month__bar">
                <div class="month__bar__status" [ngStyle]="{ 'width.%': calculateBarWidth(month) }"></div>
            </div>
            <div class="month__summary">
                <div
                    [innerHTML]="
                        'StatsMonthKm.DistanceWithUsers'
                            | translate
                                : {
                                      distance: getMonthDistance(month) / 1000 | number: '1.2-2',
                                      count: club.userCount
                                  }
                    "
                ></div>
                <div class="summary__highlight">
                    {{ 'Common.ActivityIndex' | translate }}
                    <strong>{{ getMonthDistance(month) / 1000 / club.userCount | number: '1.2-2' }}</strong>
                </div>
            </div>
        </div>
    </div>
</div>
