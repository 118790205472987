<div class="tile-teams">
    <h2>
        {{ 'TileTeams.TeamChallenge' | translate }}
    </h2>
    <div class="tile-teams__differences" *ngIf="teams.length > 1">
        <svg
            width="11"
            height="15"
            xmlns="http://www.w3.org/2000/svg"
            *ngIf="activityDifference.activitiesDifference !== 0"
        >
            <g
                [attr.stroke]="activityDifference.color"
                stroke-width="2"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path d="M5.495 14V1M1 5.5L5.5 1 10 5.5" />
            </g>
        </svg>
        <div>
            <strong>{{ activityDifference.activitiesDifference | number: '1.0-0' }}</strong>
            <small [innerHTML]="'TileTeams.ActivitiesAhead' | translate"></small>
        </div>
    </div>
    <div class="tile-teams__differences" *ngIf="teams.length > 1">
        <svg
            width="11"
            height="15"
            xmlns="http://www.w3.org/2000/svg"
            *ngIf="distanceDifference.totalKm !== 0"
        >
            <g
                [attr.stroke]="distanceDifference.color"
                stroke-width="2"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path d="M5.495 14V1M1 5.5L5.5 1 10 5.5" />
            </g>
        </svg>
        <div class="has-unit">
            <strong>{{ distanceDifference.totalKm | distance | number: '1.0-2' }}</strong> km
            <small>{{ 'TileTeams.Difference' | translate }}</small>
        </div>
    </div>
    <a routerLink="/club-management/teams" *ngIf="user && user.adminForClub === user.clubId">
        <svg width="51" height="51" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
                <rect width="50.139" height="50.139" rx="3" />
                <path
                    d="M20.209 34.879l-6.466 1.517 1.517-6.465 15.599-15.602a2.002 2.002 0 012.83 0l2.122 2.12c.78.783.78 2.048 0 2.83l-15.602 15.6h0zM35.105 19.988l-4.953-4.953M33.679 21.415l-4.941-4.965M20.461 34.628l-4.95-4.95"
                    stroke="#02364D"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
        </svg>
    </a>

    <div class="tile-teams__totals">
        <ng-container *ngFor="let team of sortedTeams">
            <div class="tile-teams__compare">
                <div
                    class="tile-teams__name"
                    [ngStyle]="{
                        backgroundColor: team.color,
                        flexBasis: getWidthPercentage(team.totalKm) + '%'
                    }"
                >
                    <svg
                        width="17"
                        height="32"
                        xmlns="http://www.w3.org/2000/svg"
                        *ngIf="user.teamId === team.id"
                    >
                        <path
                            d="M17 0v32l-8.5-7.652L0 32V0h17zM8.5 9.5l-1.5 4H3L6 16l-1 4 3.5-2.5L12 20l-1-4 3-2.5h-4l-1.5-4z"
                            fill="#FFF"
                            fill-rule="evenodd"
                        />
                    </svg>
                    {{ team.name }}
                </div>
                <div class="tile-teams__distance" [ngStyle]="{ borderColor: team.color }">
                    {{ team.totalKm | distance | number: '1.0-2' }} <small>km</small>
                </div>
            </div>
            <div class="tile-teams__members" style="">
                <h4>{{ 'Dashboard.TeamsTile.HeadlineTeamMembers' | translate }}</h4>
                <p *ngFor="let user of team.users">{{ user.name }}</p>
            </div>
        </ng-container>

        <app-stacked-chart [teams]="sortedTeams"></app-stacked-chart>
    </div>
</div>
