import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public lang$ = new BehaviorSubject<'de' | 'en'>('de');

    public constructor(
        private toastr: ToastrService,
        private translateService: TranslateService,
        @Inject('storage') private storage: any,
    ) {
        const preferredLang = this.storage.getItem('RR_LANG');
        if (preferredLang) {
            this.lang$.next(preferredLang);
        }
    }

    public success = (translateKey: string, params?: any) =>
        this.translateService.get(translateKey, params).subscribe((m: string) => this.toastr.success(m));
    public error = (translateKey: string, params?: any) =>
        this.translateService.get(translateKey, params).subscribe((m: string) => this.toastr.error(m));
    public info = (translateKey: string, params?: any) =>
        this.translateService.get(translateKey, params).subscribe((m: string) => this.toastr.info(m));
    public warning = (translateKey: string, params?: any) =>
        this.translateService.get(translateKey, params).subscribe((m: string) => this.toastr.warning(m));

    public switchLanguage() {
        const currentLang = this.lang$.getValue();
        const lang = currentLang === 'de' ? 'en' : 'de';
        this.lang$.next(lang);
        this.storage.setItem('RR_LANG', lang);
    }
}
