import { Component } from '@angular/core';
import { AnalyticsService } from '../../core/services/analytics.service';

@Component({
    selector: 'app-data-privacy',
    templateUrl: './data-privacy.component.html',
    styleUrls: ['../legal.scss'],
})
export class DataPrivacyComponent {
    public constructor(private analyticsService: AnalyticsService) {}

    public optOutTracking() {
        this.analyticsService.forgetTracking();
    }
}
