import { Component, OnInit } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';
import { MenuComponent } from '../menu.component';
import { UserFE } from '../../../core/interfaces/user.interface';
import { UserService } from '../../../core/services/user.service';
import { NotificationService } from '../../../core/services/notification.service';
import { TenantService } from '../../../core/services/tenant.service';

@Component({
    selector: 'app-tenant-menu',
    templateUrl: './tenant-menu.component.html',
    styleUrls: ['./tenant-menu.component.scss'],
})
export class TenantMenuComponent extends MenuComponent implements OnInit {
    public user: UserFE;
    public tenant = this.tenantService.tenant;

    public constructor(
        notificationService: NotificationService,
        router: Router,
        private userService: UserService,
        private tenantService: TenantService,
    ) {
        super(notificationService, router);
    }

    public ngOnInit() {
        this.userService.user.subscribe((user: UserFE) => (this.user = user));
    }

    public logoutFromCognito(): void {
        Auth.signOut({ global: true })
            .then(() => {
                this.userService.setLoggedIn(false);
                this.userService.setUser(null as any);
                this.notificationService.success('Notify.LogoutSuccessful');
                this.router.navigate(['']);
            })
            .catch(() => this.notificationService.error('Notify.ErrorLogout'));
    }
}
