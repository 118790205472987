import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClubWithAllUsers } from '../interfaces/club-with-users.interface';
import { ClubWithCreator } from '../interfaces/club-with-creator.interface';
import { TenantFE } from '../interfaces/tenant.interface';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    public constructor(
        private httpClient: HttpClient,
        @Inject('env') private env: any,
    ) {}

    public addUserToClubAdminsAsClubAdmin(userId: number): Observable<any> {
        return this.httpClient.post(this.env.api + '/admin/user/' + userId, {});
    }

    public superAddUserToClubAdmin(userId: number): Observable<any> {
        return this.httpClient.post(this.env.api + '/admin/user/', { userId });
    }

    public removeUserFromClubAdmins(userId: number): Observable<any> {
        return this.httpClient.delete(this.env.api + '/admin/user/' + userId);
    }

    public getAllUsersForAllClubs() {
        return this.httpClient.get<ClubWithAllUsers[]>(this.env.api + '/admin/users');
    }

    public getAllClubsWithoutAdmin() {
        return this.httpClient.get<ClubWithCreator[]>(this.env.api + '/admin/clubs');
    }

    public superGetAmountOfActivitiesPerDay(): Observable<
        { startDate: string; distance: number; activityAmount: number }[]
    > {
        return this.httpClient.get<{ startDate: string; distance: number; activityAmount: number }[]>(
            this.env.api + '/admin/activities/total',
        );
    }

    public pruneAndGenerateDataForTenant(
        tenant: Omit<TenantFE, 'cognitoDomain' | 'cognitoAppId' | 'userPoolId'>,
    ): Observable<any> {
        return this.httpClient.patch(this.env.api + '/admin/demo-tenant/prune', { tenant });
    }
}
