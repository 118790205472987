import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    private _username: string;
    private _password: string;
    private _userObject: any;

    public set username(username: string) {
        this._username = username;
    }

    public get username(): string {
        return this._username;
    }

    public set password(password: string) {
        this._password = password;
    }

    public get password(): string {
        return this._password;
    }

    public login(username = this._username, password = this._password) {
        try {
            return Auth.signIn(username.toLowerCase(), password);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    public set userObject(object: any) {
        this._userObject = object;
    }

    public get userObject() {
        return this._userObject;
    }
}
