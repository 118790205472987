import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '../../core/services/notification.service';
import { filter } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
    @Input() public mobileNavbarOpen = false;
    @Output() public mobileNavbarOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(
        public readonly notificationService: NotificationService,
        public readonly router: Router,
    ) {
        const navStartEvent = this.router.events.pipe(filter(e => e instanceof NavigationStart));
        navStartEvent.subscribe({
            next: () => {
                this.mobileNavbarOpen = false;
                this.mobileNavbarOpenChange.emit(false);
            },
        });
    }

    public switchLanguage() {
        this.notificationService.switchLanguage();
    }
}
