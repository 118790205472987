<div class="stats-category">
    <div class="icon">
        <app-icon [name]="iconPath"></app-icon>
    </div>
    <span class="name">{{ name }}</span>
    <div class="diagram" *ngIf="pulse.length > 0">
        <div
            [attr.data-value]="step"
            *ngFor="let step of diagramData"
            [style.height]="(step / maxData) * 30 + 'px'"
        ></div>
    </div>
    <div class="count">
        <span class="value">{{ count | number: '1.0-0' }}</span>
        <span class="unit">{{ 'Common.Activities' | translate }}</span>
    </div>
    <div class="distance">
        <span class="value">
            {{ distance | number: '1.0-0' }}
            <span class="converted" *ngIf="factor !== 1" [title]="'Common.ConvertedDistanceHint' | translate">
                ({{ distance * factor | number: '1.0-0' }})
            </span>
        </span>
        <span class="unit">{{ unit }}</span>
    </div>
</div>
