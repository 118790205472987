import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import localeDe from '@angular/common/locales/de';
import { appRoutes } from './app.routes';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { SocialComponent } from './social/social.component';
import { EmptyRoutingComponent } from './empty-routing/empty-routing.component';
import { SaasLpComponent } from './saas-lp/saas-lp.component';
import { AgbComponent } from './legal/agb/agb.component';
import { DataPrivacyComponent } from './legal/data-privacy/data-privacy.component';
import { ImprintComponent } from './legal/imprint/imprint.component';
import { SharedModule } from './shared/shared.module';
import { StravaService } from './core/services/strava.service';
import { AnalyticsService } from './core/services/analytics.service';
import { environment } from '../environments/environment';
import { Overlay, OverlayContainer, ToastrModule, ToastrService } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatomoModule } from 'ngx-matomo-client';

registerLocaleData(localeDe);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        SocialComponent,
        SaasLpComponent,
        EmptyRoutingComponent,
        AgbComponent,
        DataPrivacyComponent,
        ImprintComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(appRoutes, {
            initialNavigation: 'enabledBlocking',
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
        }),
        BrowserModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        SharedModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MatomoModule.forRoot({
            trackers: [
                {
                    trackerUrl: environment.matomoServer,
                    siteId: environment.matomoSiteId,
                },
            ],
            requireConsent: 2,
        }),
        FormsModule,
    ],
    providers: [
        StravaService,
        Overlay,
        OverlayContainer,
        ToastrService,
        AnalyticsService,
        {
            provide: 'env',
            useValue: environment,
        },
        {
            provide: 'document',
            useValue: document,
        },
        {
            provide: 'window',
            useValue: window,
        },
        {
            provide: 'storage',
            useValue: localStorage,
        },
        {
            provide: LOCALE_ID,
            useValue: 'de-DE',
        },
        {
            provide: Window,
            useValue: window,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
