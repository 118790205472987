<div class="modal">
    <div [style.max-width]="maxWidth" class="modal__inner">
        <button (click)="closeModal()">
            <svg height="36" width="38" xmlns="http://www.w3.org/2000/svg">
                <g stroke="#979797" stroke-linecap="square">
                    <path d="M1.176.469L36.53 35.824M35.117 1.176L1.176 35.117" />
                </g>
            </svg>
        </button>
        <div class="modal__content">
            <ng-content></ng-content>
        </div>
    </div>
</div>
