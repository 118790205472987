import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() public onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public maxWidth = '80%';

    public constructor(@Inject('document') private document: Document) {}

    public ngOnInit() {
        this.document.body.classList.add('modal-open');
    }

    public closeModal() {
        this.onCloseModal.emit(true);
        this.document.body.classList.remove('modal-open');
    }
}
