import { Component, Input } from '@angular/core';
import { LoaderService } from '../../core/services/loader.service';
import { ClubDataActivitiesPulseTotals } from '../../core/interfaces/club-with-activities.interface';
import { NotificationService } from '../../core/services/notification.service';

@Component({
    selector: 'app-club-intro',
    templateUrl: './club-intro.component.html',
    styleUrls: ['./club-intro.component.scss'],
})
export class ClubIntroComponent {
    @Input() public club: ClubDataActivitiesPulseTotals;
    @Input() public admin: boolean;
    @Input() public userForClub? = false;

    public constructor(
        private loaderService: LoaderService,
        private notificationService: NotificationService,
    ) {}

    public activateLoader() {
        this.loaderService.displayLoader();
    }

    private getInvitationPath(): string {
        return `${location.protocol}//${location.host}/invitation/${this.club.invitationLink}`;
    }

    public copyInviteLink() {
        navigator.clipboard.writeText(this.getInvitationPath()).then(() => {
            this.notificationService.info('Notify.InvitationLinkInClipboard');
        });
    }
}
