import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class MailService {
    public constructor(
        private httpClient: HttpClient,
        @Inject('env') private env: any,
    ) {}

    public requestSalesPDF(email: string) {
        return this.httpClient.post(this.env.api + '/mails/saas/lead', { email });
    }
}
