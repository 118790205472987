import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import * as d3 from 'd3';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    // ts lint wants these ugly declarations.
    public longMonthNames: [
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
    ] = [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ];
    private shortMonthNames: [
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
    ] = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
    private longDayNames: [string, string, string, string, string, string, string] = [
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
        'Sonntag',
    ];
    public shortDayNames: [string, string, string, string, string, string, string] = [
        'Mo',
        'Di',
        'Mi',
        'Do',
        'Fr',
        'Sa',
        'So',
    ];

    public locale$ = new BehaviorSubject<d3.TimeLocaleObject>({} as d3.TimeLocaleObject);

    public constructor(private translateService: TranslateService) {
        this.getTranslations();
    }

    public getShortDayTranslateKeys() {
        return [
            'DateTime.MondayAbbr',
            'DateTime.TuesdayAbbr',
            'DateTime.WednesdayAbbr',
            'DateTime.ThursdayAbbr',
            'DateTime.FridayAbbr',
            'DateTime.SaturdayAbbr',
            'DateTime.SundayAbbr',
        ];
    }

    public getMonthTranslateKeys() {
        return [
            'DateTime.January',
            'DateTime.February',
            'DateTime.March',
            'DateTime.April',
            'DateTime.May',
            'DateTime.June',
            'DateTime.July',
            'DateTime.August',
            'DateTime.September',
            'DateTime.October',
            'DateTime.November',
            'DateTime.December',
        ];
    }

    public getHoursMinutesSecondsOfSeconds(seconds: number): [number, number, number] {
        const hours = Math.floor(seconds / 60 / 60);
        const minutes: number | string = Math.floor(seconds / 60) - hours * 60;
        const remainingSeconds: number | string = seconds - hours * 60 * 60 - minutes * 60;
        return [hours, minutes, remainingSeconds];
    }

    public getYearMonthDayOfDate(date: Date): [string | number, string | number, string | number] {
        const year = date.getFullYear();
        const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

        return [year, month, day];
    }

    public getHourMinutesOfDate(date: Date): [string | number, string | number] {
        const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

        return [hour, minutes];
    }

    public getMonthName(month: number) {
        return new Date(Date.UTC(2020, month, 1)).toLocaleString('default', { month: 'long' });
    }

    private getTranslations(): void {
        this.translateService
            .get([
                ...this.getMonthTranslateKeys(),
                'DateTime.Jan',
                'DateTime.Feb',
                'DateTime.Mar',
                'DateTime.Apr',
                'DateTime.May',
                'DateTime.Jun',
                'DateTime.Jul',
                'DateTime.Aug',
                'DateTime.Sep',
                'DateTime.Oct',
                'DateTime.Nov',
                'DateTime.Dec',
                'DateTime.Monday',
                'DateTime.Tuesday',
                'DateTime.Wednesday',
                'DateTime.Thursday',
                'DateTime.Friday',
                'DateTime.Saturday',
                'DateTime.Sunday',
                ...this.getShortDayTranslateKeys(),
            ])
            .subscribe(result => {
                this.longMonthNames = [
                    result['DateTime.January'],
                    result['DateTime.February'],
                    result['DateTime.March'],
                    result['DateTime.April'],
                    result['DateTime.May'],
                    result['DateTime.June'],
                    result['DateTime.July'],
                    result['DateTime.August'],
                    result['DateTime.September'],
                    result['DateTime.October'],
                    result['DateTime.November'],
                    result['DateTime.December'],
                ];
                this.shortMonthNames = [
                    result['DateTime.Jan'],
                    result['DateTime.Feb'],
                    result['DateTime.Mar'],
                    result['DateTime.Apr'],
                    result['DateTime.May'],
                    result['DateTime.Jun'],
                    result['DateTime.Jul'],
                    result['DateTime.Aug'],
                    result['DateTime.Sep'],
                    result['DateTime.Oct'],
                    result['DateTime.Nov'],
                    result['DateTime.Dec'],
                ];
                this.longDayNames = [
                    result['DateTime.Sunday'],
                    result['DateTime.Monday'],
                    result['DateTime.Tuesday'],
                    result['DateTime.Wednesday'],
                    result['DateTime.Thursday'],
                    result['DateTime.Friday'],
                    result['DateTime.Saturday'],
                ];
                this.shortDayNames = [
                    result['DateTime.SundayAbbr'],
                    result['DateTime.MondayAbbr'],
                    result['DateTime.TuesdayAbbr'],
                    result['DateTime.WednesdayAbbr'],
                    result['DateTime.ThursdayAbbr'],
                    result['DateTime.FridayAbbr'],
                    result['DateTime.SaturdayAbbr'],
                ];

                this.locale$.next(
                    d3.timeFormatLocale({
                        dateTime: '%A, %e %B %Y',
                        date: '%d.%m.%Y',
                        time: '%H:%M:%S',
                        periods: ['AM', 'PM'],
                        days: this.longDayNames,
                        shortDays: this.shortDayNames,
                        months: this.longMonthNames,
                        shortMonths: this.shortMonthNames,
                    }),
                );
            });
    }

    public updateDateTranslations() {
        this.getTranslations();
    }
}
