import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { generateMissingDays, getCalendarWeek, groupDaysByWeek } from '../../core/helper/date.helper';
import { ClubDataActivitiesPulseTotals } from '../../core/interfaces/club-with-activities.interface';
import { Team } from '../../core/interfaces/team.interface';
import { DateService } from '../../core/services/date.service';
import { drawChart } from './chart.helper';
import { Day, DayTeamActivity } from '../../core/interfaces/club-activities-by-date.interface';

@Component({
    selector: 'app-tile-weekly-team-statistics',
    templateUrl: './tile-weekly-team-statistics.component.html',
    styleUrls: ['./tile-weekly-team-statistics.component.scss'],
})
export class TileWeeklyTeamStatisticsComponent implements OnInit, AfterViewInit {
    @Input() public club: ClubDataActivitiesPulseTotals;
    public activitiesPerCalendarWeek: Day[][];
    public activeWeekIndex: number;
    public clubHasTeams: boolean;
    public totalAmountOfActivitiesForWeek: number;
    public totalDistanceForActiveWeek: number;
    public totalDistancePerTeam: number[];
    public teams: (Team & { distance: number })[] = [];
    @ViewChild('chartWrapper') public chartWrapper: ElementRef;
    private todaysWeek = getCalendarWeek(new Date());

    public constructor(
        @Inject('env') private env: any,
        private dateService: DateService,
        @Inject('window') private window: Window,
    ) {}

    public get activeWeek(): Day[] {
        return this.activitiesPerCalendarWeek[this.activeWeekIndex];
    }

    public getWeekNumber(): number {
        return getCalendarWeek(new Date(this.activeWeek[0].startDate));
    }

    public ngOnInit() {
        this.dateService.locale$.subscribe(() => {
            this.updateChart();
        });

        this.clubHasTeams = this.club.teams.length > 0;
        this.teams = this.club.teams.map(team => ({ ...team, distance: 0 }));

        const weeks = this.fillWeeksOutsideOfPeriode(groupDaysByWeek(this.club.activitiesByDate));

        this.activitiesPerCalendarWeek = weeks.map(week =>
            week.map((day, index) => ({
                ...day,
                dayOfWeek: index + 1,
                startDate: day.isoDate.substring(0, 10),
            })),
        );

        this.activeWeekIndex =
            this.activitiesPerCalendarWeek.findIndex(days => days[0].week === this.todaysWeek) - 1;

        if (this.activeWeekIndex < 0) {
            this.activeWeekIndex = 0;
        } else if (this.activeWeekIndex > this.activitiesPerCalendarWeek.length - 1) {
            this.activeWeekIndex = this.activitiesPerCalendarWeek.length - 1;
        }

        this.updateStats();

        let timer: any;
        this.window.addEventListener('resize', () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                this.updateStats();
                this.updateChart();
            }, 250);
        });
    }

    public ngAfterViewInit() {
        this.updateChart();
    }

    public prevWeek() {
        if (this.activeWeekIndex > 0) {
            this.activeWeekIndex--;
            this.updateStats();
            this.updateChart();
        }
    }

    public nextWeek() {
        if (this.activeWeekIndex < this.activitiesPerCalendarWeek.length - 1) {
            this.activeWeekIndex++;
            this.updateStats();
            this.updateChart();
        }
    }

    private fillFirstWeek = (weeks: DayTeamActivity[][]) => {
        const firstDate = new Date(weeks[0][0].isoDate);
        const missingDays = 7 - weeks[0].length;

        return [...generateMissingDays(firstDate, missingDays, -missingDays), ...weeks[0]];
    };

    private fillLastWeek = (weeks: DayTeamActivity[][]) => {
        const lastWeek = weeks[weeks.length - 1];
        const lastDate = new Date(lastWeek[lastWeek.length - 1].isoDate);
        const missingDays = 7 - lastWeek.length;

        return [...lastWeek, ...generateMissingDays(lastDate, missingDays, 1)];
    };

    private fillWeeksOutsideOfPeriode = (weeks: DayTeamActivity[][]) => {
        weeks[0] = this.fillFirstWeek(weeks);
        weeks[weeks.length - 1] = this.fillLastWeek(weeks);

        return weeks;
    };

    private updateChart(): void {
        if (!this.chartWrapper) {
            return;
        }
        let chartWidth = this.chartWrapper.nativeElement.getBoundingClientRect().width;
        if (this.window.innerWidth > 1024) {
            chartWidth = chartWidth * 0.6; // flex-basis.
        }
        drawChart(
            '.stats-weekly__chart',
            this.activitiesPerCalendarWeek,
            this.activeWeekIndex,
            this.club.teams,
            chartWidth,
            230,
        );
    }

    private updateStats() {
        this.totalDistanceForActiveWeek = this.getTotalDistanceForActiveWeek();
        this.totalAmountOfActivitiesForWeek = this.getTotalAmountOfActivitiesForWeek();

        if (this.teams.length > 0) {
            // group activities per week by teams
            this.teams = this.teams.map(team => ({
                ...team,
                distance: this.activeWeek?.reduce(
                    (distance, day) => distance + (day.teamActivities?.[team.id as number]?.totalKm ?? 0),
                    0,
                ),
            }));
        }
    }

    private getTotalDistanceForActiveWeek(): number {
        return this.activeWeek?.reduce<number>((distance, day) => distance + (day.totalKm ?? 0), 0) ?? 0;
    }

    private getTotalAmountOfActivitiesForWeek(): number {
        return this.activeWeek?.reduce<number>((amount, day) => amount + (day.totalActivities ?? 0), 0) ?? 0;
    }
}
