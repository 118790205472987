import { DayTeamActivity } from '../interfaces/club-activities-by-date.interface';
import { ClubDataActivitiesPulseTotals } from '../interfaces/club-with-activities.interface';

export const getMonthPulse = (month: number, club: ClubDataActivitiesPulseTotals) => {
    return club.activitiesByDate.reduce<{ activities: number; date: number }[]>(
        (dayPulse, day) =>
            day.month === month
                ? [...dayPulse, { activities: day.totalActivities, date: day.dayInMonth }]
                : dayPulse,
        [],
    );
};

export const getMonthPulsePerType = (days: DayTeamActivity[], type: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return days.map(day => day[type]);
};
