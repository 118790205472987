<section class="wrapper bg-navy">
    <div class="content footer">
        <a
            class="instagram"
            href="https://www.instagram.com/remote_run/"
            target="_blank"
            [title]="'Layout.RemoteRunOnInstagram' | translate"
        >
            <svg height="64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                <g transform="translate(1 1)" stroke="currentColor" fill="none" fill-rule="evenodd">
                    <rect x=".055" y=".034" width="23.013" height="22.878" rx="6" />
                    <circle cx="11.595" cy="11.573" r="5.502" />
                    <circle cx="18.338" cy="4.73" r="1.61" />
                </g>
            </svg>
            <span>Instagram</span>
        </a>
        <a href="https://www.facebook.com/remoterun" target="_blank">
            <svg height="64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 64">
                <g fill="none" fill-rule="evenodd">
                    <path
                        d="M34.836 19.41h-12.06v-5.309c0-2.497 1.679-3.079 2.86-3.079h8.443V.036L21.846 0C10.752 0 8.647 8.19 8.647 13.433v5.977H.17v11.148h8.477V64h14.129V30.558h10.882l1.178-11.148z"
                        fill="currentColor"
                    />
                </g>
            </svg>
            <span>Facebook</span>
        </a>
        <a href="https://www.facebook.com/groups/remoteruncommunity" target="_blank">
            <svg height="64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 64">
                <g fill="none" fill-rule="evenodd">
                    <path
                        d="M34.836 19.41h-12.06v-5.309c0-2.497 1.679-3.079 2.86-3.079h8.443V.036L21.846 0C10.752 0 8.647 8.19 8.647 13.433v5.977H.17v11.148h8.477V64h14.129V30.558h10.882l1.178-11.148z"
                        fill="currentColor"
                    />
                </g>
            </svg>
            <span>Facebook Community</span>
        </a>
    </div>
</section>
