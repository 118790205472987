import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'time',
})
export class TimePipe implements PipeTransform {
    public transform(seconds: number, format: 'short' | 'long' = 'long'): string {
        try {
            const hours = Math.floor(seconds / 60 / 60);
            let minutes: number | string = Math.floor(seconds / 60) - hours * 60;
            let restSeconds: number | string = seconds - hours * 60 * 60 - minutes * 60;
            if (minutes < 10) {
                minutes = '0' + minutes.toString();
            }
            if (restSeconds < 10) {
                restSeconds = '0' + restSeconds.toString();
            }
            if (format === 'short') {
                return hours + ':' + minutes;
            }
            return hours + ':' + minutes + ':' + restSeconds;
        } catch (e) {
            return seconds.toString();
        }
    }
}
