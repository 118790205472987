import { Component, Input, NgZone, OnChanges } from '@angular/core';
import { ActivityFE } from '../../core/interfaces/activity.interface';
import { getKmByType } from '../../core/helper/distance';

@Component({
    selector: 'app-tile-facts',
    templateUrl: './tile-facts.component.html',
    styleUrls: ['./tile-facts.component.scss'],
})
export class TileFactsComponent implements OnChanges {
    @Input() public activities: ActivityFE[];

    public total = 0;
    public donation = 0;
    public time = 0;

    public constructor(private ngZone: NgZone) {}

    public ngOnChanges() {
        if (this.activities) {
            this.total = 0;
            this.donation = 0;
            this.time = 0;
            this.activities.forEach((activity: ActivityFE) => {
                this.total += getKmByType(activity);
                this.donation += (getKmByType(activity) / 1000) * (activity.donationAmount ?? 0);
                this.time += activity.time;
            });
        }
    }

    public save() {
        this.ngZone.runOutsideAngular(() => {
            const stringobJ = new XMLSerializer();
            const element = document.getElementById('shareSVG') as Node;
            const svgString = stringobJ.serializeToString(element);

            const image = new Image(1080, 1080);
            image.src = 'data:image/svg+xml;utf-8,' + encodeURIComponent(svgString);

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            canvas.width = 1080;
            canvas.height = 1080;

            const isiOS = !!navigator.platform.match(/iPhone|iPod|iPad/);
            let w: any;
            if (isiOS) {
                w = window.open();
            }

            image.addEventListener('load', () => {
                context!.drawImage(image, 0, 0);

                if (isiOS) {
                    setTimeout(function () {
                        w.document.write(
                            '<img alt="Meine RemoteRun Ergebnisse" src="' +
                                canvas.toDataURL('image/png') +
                                '" />',
                        );
                    });
                } else {
                    const a = document.createElement('a');
                    a.download = 'my-remoterun-results.png'; // Saving in PNG
                    a.href = canvas.toDataURL('image/png'); // Saving in PNG
                    a.style.display = 'none';
                    a.click();
                }
            });
        });
    }
}
