import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Input() public mobileNavIsOpen = false;
    @Output() public mobileNavIsOpenChange = new EventEmitter<boolean>();
    public mobileNavbarOpen = false;

    public constructor(
        private readonly router: Router,
        @Inject('document') private document: Document,
        @Inject('window') private window: Window,
    ) {
        this.window.addEventListener('scroll', () => {
            this.document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });

        const navStartEvent = this.router.events.pipe(filter(e => e instanceof NavigationStart));
        navStartEvent.subscribe({
            next: () => {
                this.mobileNavbarOpen = false;
                this.closeDialog();
            },
        });
    }

    public openMobileNavbar() {
        const newNavbarState = !this.mobileNavbarOpen;
        this.mobileNavIsOpenChange.emit(newNavbarState);
        this.mobileNavbarOpen = newNavbarState;

        if (this.mobileNavbarOpen) {
            this.showDialog();
        } else {
            this.closeDialog();
        }
    }

    public showDialog(): void {
        const scrollY = this.document.documentElement.style.getPropertyValue('--scroll-y');
        const body = this.document.body;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}`;
    }

    public closeDialog(): void {
        const body = this.document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        this.window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
}
