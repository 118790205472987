import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

interface Goal {
    distance: number;
    text: string;
    left?: string;
    reached?: boolean;
}

@Component({
    selector: 'app-global-goal',
    templateUrl: './global-goal.component.html',
    styleUrls: ['./global-goal.component.scss'],
})
export class GlobalGoalComponent implements OnChanges {
    @Input() public goal = 384200;
    @Input() public imageName = 'moon.png';
    @Input() public status: number;

    public interimGoals: Goal[] = [
        { distance: 40075, text: 'AroundTheWorld' },
        { distance: 114000, text: 'AsteroidES4' },
        { distance: 230548, text: 'RailNetworkEU' },
    ];

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['status'].currentValue) {
            this.interimGoals = this.interimGoals.map(g => ({
                ...g,
                left: ((g.distance / this.goal) * 100).toFixed(2),
                reached: g.distance * 1000 <= this.status,
            }));
        }
    }

    public getPercentage() {
        return (this.status / 1000 / this.goal) * 100;
    }
}
