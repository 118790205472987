import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-stats-facts3',
    templateUrl: './stats-facts3.component.html',
    styleUrls: ['./stats-facts3.component.scss'],
})
export class StatsFacts3Component {
    @Input() public value: number;
    @Input() public unit?: string;
    @Input() public label?: string;

    @Input() public theme = 'white';
    @Input() public large?: boolean;
}
