import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorInterceptorService } from '../interceptors/error-interceptor.service';
import { JwtInterceptorService } from '../interceptors/jwt-interceptor.service';
import { TenantInterceptor } from '../interceptors/tenant.interceptor';
import { DistancePipe } from './pipes/distance.pipe';
import { FilterUsersPipe } from './pipes/filter-users.pipe';
import { TimePipe } from './pipes/time.pipe';
import { ActivityService } from './services/activity.service';
import { AdminService } from './services/admin.service';
import { ClubService } from './services/club.service';
import { GuidedTourService } from './services/guided-tour.service';
import { LoginService } from './services/login.service';

@NgModule({
    declarations: [TimePipe, DistancePipe, FilterUsersPipe],
    imports: [CommonModule, HttpClientModule, TranslateModule],
    exports: [TimePipe, DistancePipe, FilterUsersPipe, TranslateModule],
    providers: [
        ClubService,
        ActivityService,
        LoginService,
        GuidedTourService,
        AdminService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TenantInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true,
        },
    ],
})
export class CoreModule {}
