// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    cognitoAppId: '2fmijtb5mkhcg44r5oh7hsqmb3',
    userPoolId: 'eu-central-1_uBFQ7KslN',
    region: 'eu-central-1',
    cognitoDomain: 'remoterun-develop.auth.eu-central-1.amazoncognito.com',
    callbackUri: window.location.protocol + '//' + window.location.host,
    api: 'https://dev-api.remoterun.de',
    redirectCognitoSignIn: window.location.protocol + '//' + window.location.host + '/dashboard',
    redirectCognitoSignOut: window.location.protocol + '//' + window.location.host + '/login',
    stravaClientId: '50852',
    stravaOAuthRedirectUri: window.location.protocol + '//' + window.location.host + '/strava',
    GA_ID: null,
    GA_ACCEPTED_COOKIE: 'cookie_consent',
    guidedTourVersion: 5,
    cognitoIdsToIgnore: [],
    matomoSiteId: 1,
    matomoServer: '//analytics.mediaman-cloud.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
