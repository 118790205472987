<div class="guided-tour" *ngIf="tourIsVisible">
    <div class="glide">
        <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides">
                <li *ngFor="let slide of slides; let last = last" class="glide__slide">
                    <app-guided-tour-slide
                        [slide]="slide"
                        [isLast]="last"
                        (tourVisible)="updateTour($event)"
                    ></app-guided-tour-slide>
                </li>
            </ul>
            <div class="glide__arrows" data-glide-el="controls" *ngIf="slides.length > 1">
                <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                    <svg width="30" height="27" viewBox="0 0 45 27" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 13.5c0-.7.3-1.3.8-1.8L11.3 1.1c1-.9 2.5-.9 3.5 0 1 1 1 2.5.1 3.5l-6.3 6.3h34c1.3.1 2.4 1.2 2.4 2.6 0 1.4-1.1 2.5-2.5 2.5h-34l6.3 6.3c1 1 1 2.6 0 3.5-1 1-2.5 1-3.5 0L.8 15.3c-.5-.4-.8-1-.8-1.8z"
                            fill="#02364D"
                            opacity="0.3"
                            fill-rule="nonzero"
                        />
                    </svg>
                </button>
                <p *ngIf="glider">{{ glider.index + 1 }} / {{ slides.length }}</p>
                <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                    <svg width="30" height="27" viewBox="0 0 47 27" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m46 13.5c0-.7-.3-1.3-.8-1.8l-10.5-10.6c-1-.9-2.5-.9-3.5 0-1 1-1 2.5-.1 3.5l6.3 6.3h-34c-1.3.1-2.4 1.2-2.4 2.6s1.1 2.5 2.5 2.5h17.4 16.6l-6.3 6.3c-1 1-1 2.6 0 3.5 1 1 2.5 1 3.5 0l10.5-10.5c.5-.4.8-1 .8-1.8z"
                            fill="#02364D"
                            opacity="0.3"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</div>
