import { Component, Input, OnInit } from '@angular/core';
import { ActivityPulse } from '../../core/interfaces/activity-pulse.interface';

@Component({
    selector: 'app-stats-distance-graph1',
    templateUrl: './stats-distance-graph1.component.html',
    styleUrls: ['./stats-distance-graph1.component.scss'],
})
export class StatsDistanceGraph1Component implements OnInit {
    @Input() public value: number;
    @Input() public unit?: string;
    @Input() public label?: string;
    @Input() public activities: ActivityPulse[];

    public activityValues: number[];

    public ngOnInit() {
        const stepAmount = ~~(this.activities.length / 10) + 1;

        // calculate all values in distance to 9 or 10 values.
        this.activityValues = this.activities.reduce(
            (previousValue: number[], activity: ActivityPulse, currentIndex: number) => {
                if (currentIndex % stepAmount === 0) {
                    previousValue.push(activity.totalDistance + previousValue[previousValue.length - 1] || 0);
                } else {
                    previousValue[previousValue.length - 1] += activity.totalDistance;
                }

                return previousValue;
            },
            [],
        );
    }
}
