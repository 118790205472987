import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';
import { NotificationService } from '../core/services/notification.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
    public constructor(
        private router: Router,
        private injector: Injector,
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const notificationService = this.injector.get(NotificationService);
        return next.handle(request).pipe(
            catchError(err => {
                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                    Auth.signOut({ global: true })
                        .then(
                            () => (
                                notificationService.error('Notify.ErrorNotPermitted'),
                                this.router.navigate(['/login'])
                            ),
                        )
                        .catch(e => console.log(e));
                }
                return throwError(() => err.error);
            }),
        );
    }
}
