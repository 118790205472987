import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { TeamWithTotals } from '../../../core/interfaces/team-with-totals.interface';
import * as d3 from 'd3';
import { TeamWithPulse } from '../../../core/interfaces/team-with-pulse.interface';
import { DateService } from '../../../core/services/date.service';
import { ClubActivity } from '../../../core/interfaces/club-activity.interface';
import { TenantService } from '../../../core/services/tenant.service';
import { getKmByType } from '../../../core/helper/distance';

@Component({
    selector: 'app-stacked-chart',
    templateUrl: './stacked-chart.component.html',
    styleUrls: ['./stacked-chart.component.scss'],
})
export class StackedChartComponent implements AfterViewInit {
    @Input() public teams: (TeamWithTotals & { activities: ClubActivity[] })[];

    private width = 700; // width is calculated later by the component width
    private margin = 25;
    private height = 220 + this.margin;

    private totalsPerDay: TeamWithPulse[];

    public svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
    public svgInner: d3.Selection<SVGGElement, unknown, null, undefined>;
    public yScale: d3.ScaleLinear<number, number>;
    public xScale: d3.ScaleTime<number, number>;
    public xAxis: d3.Selection<SVGGElement, unknown, null, undefined>;
    public xAxis2: d3.Selection<SVGGElement, unknown, null, undefined>;
    public yAxis: d3.Selection<SVGGElement, unknown, null, undefined>;
    public lines: any;
    public teamsLines: d3.Selection<SVGElement, object, HTMLElement, any>[];

    private dateLocale: any;

    public monthRange = [
        new Date(this.tenantService.tenant.period_start_date).toISOString(),
        new Date(this.tenantService.tenant.period_end_date).toISOString(),
    ];
    public chartIsVisible = false;

    @HostListener('window:scroll', ['$event'])
    public isScrolledIntoView(_event: any) {
        if (this.chart) {
            const rect = this.chart.nativeElement.getBoundingClientRect();
            const topShown = rect.top >= 0;
            const bottomShown = rect.bottom - rect.height / 4 <= this.window.innerHeight;
            this.chartIsVisible = bottomShown && topShown;
        }
    }

    public constructor(
        public chart: ElementRef,
        @Inject('window') private window: Window,
        private dateService: DateService,
        private tenantService: TenantService,
    ) {}

    public ngAfterViewInit() {
        this.dateService.locale$.subscribe(result => {
            this.dateLocale = result;
            if (this.svg) {
                this.drawChartOnInitAndResize();
            }
        });

        this.totalsPerDay = this.calculateTotalsPerDayPerTeam(this.teams);

        this.svg = d3
            .select(this.chart.nativeElement)
            .select('.chart')
            .append('svg')
            .attr('height', this.height);

        this.svgInner = this.svg.append('g');

        this.yScale = d3
            .scaleLinear()
            .domain([(d3.max(this.totalsPerDay, d => d.totalKm) ?? 0) / 1000 + 50, 0])
            .nice()
            .range([this.margin, this.height - this.margin]);

        this.yAxis = this.svgInner.append('g').attr('id', 'y-axis');

        // set start Date for x-axis to challenge start - 1 day for correct rendering of x axes.
        const startDate = new Date(this.monthRange[0]);
        startDate.setDate(startDate.getDate() - 1);
        this.xScale = d3.scaleUtc().domain([startDate, new Date(this.monthRange[1])]);

        this.xAxis2 = this.svgInner
            .append('g')
            .attr('id', 'x-axis')
            .style('transform', 'translate(' + this.margin + 'px, ' + (this.height - this.margin) + 'px)');

        this.xAxis = this.svgInner
            .append('g')
            .attr('id', 'x-axis')
            .style('transform', 'translate(' + this.margin + 'px, ' + (this.height - this.margin) + 'px)');

        this.lines = this.svgInner
            .append('g')
            .attr('id', 'lines')
            .style('transform', 'translate(' + this.margin + 'px, 0)');

        this.teamsLines = this.teams.map((_, i) => this.lines.append('path').attr('id', 'team' + i));

        this.drawChartOnInitAndResize();
        this.window.addEventListener('resize', () => this.drawChartOnInitAndResize());
    }

    private drawChartOnInitAndResize() {
        this.width = this.chart.nativeElement.getBoundingClientRect().width;
        this.svg.attr('width', this.width);

        this.xScale.range([this.margin, this.width - this.margin]);

        const xAxis = d3
            .axisTop(this.xScale)
            .ticks(d3.timeMonth.every(1))
            .tickSize(this.height - 2 * this.margin)
            .tickFormat(this.dateLocale.format('%B'));

        const xAxis2 = d3
            .axisTop(this.xScale)
            .ticks(d3.timeDay.every(2))
            .tickSize(this.height - 2 * this.margin);

        this.xAxis2
            .call(xAxis2 as any)
            .call(g => g.select('.domain').remove())
            .call(g => g.selectAll('.tick text').remove())
            .call(g =>
                g
                    .selectAll('.tick line')
                    .attr('stroke-linecap', 'square')
                    .attr('stroke-width', 1)
                    .attr('stroke', '#F0F0F0'),
            );

        this.xAxis
            .call(xAxis as any)
            .call(g => g.select('.domain').remove())
            .call(g =>
                g
                    .selectAll('.tick text')
                    .attr('y', '20')
                    .attr(
                        'x',
                        (this.svgInner.node()?.getBoundingClientRect().width ?? 50) /
                            (this.monthDiff() === 0 ? 1 : this.monthDiff()) /
                            2,
                    ),
            )
            .call(g =>
                g
                    .selectAll('.tick line')
                    .attr('stroke-linecap', 'square')
                    .attr('stroke-width', 1)
                    .attr('stroke', '#BEBEBE'),
            );

        this.yAxis.style('transform', 'translate(' + this.width + 'px, 0)');

        const yAxis = d3
            .axisLeft(this.yScale)
            .tickSize(this.width - 2 * this.margin)
            .tickFormat(v => v.toLocaleString());

        this.yAxis
            .call(yAxis as any)
            .call(g => g.select('.domain').remove())
            .call(g =>
                g
                    .selectAll('.tick line')
                    .attr('stroke-linecap', 'square')
                    .attr('stroke-width', 1)
                    .attr('stroke', '#F0F0F0'),
            );

        this.createLinesForTeams();
    }

    private createLinesForTeams(): void {
        const line = d3
            .line()
            .x(d => d[0])
            .y(d => d[1])
            .curve(d3.curveStepAfter);

        const teamPaths = this.teams.map((_, i) =>
            this.reduceActivityPulseToLinePath(this.totalsPerDay[i].activityPulse),
        );

        this.teamsLines.forEach((team, i) =>
            team.attr('d', line(teamPaths[i])).attr('stroke', this.totalsPerDay[i].color),
        );
    }

    private reduceActivityPulseToLinePath(data: { date: string; distance: number }[]): [number, number][] {
        return data.reduce(
            (al, curr) => {
                if (this.xScale(new Date(curr.date)) < 0 || this.yScale(curr.distance) < 0) {
                    return al;
                }
                return [...al, [this.xScale(new Date(curr.date)), this.yScale(curr.distance)]];
            },
            [[this.xScale(new Date(this.monthRange[0])), this.yScale(0)]],
        );
    }

    private calculateTotalsPerDayPerTeam(
        teams: (TeamWithTotals & { activities: ClubActivity[] })[],
    ): TeamWithPulse[] {
        return teams.map(team => ({
            ...team,
            activityPulse: team.activities
                .reduce((d: { date: string; distance: number }[], activity) => {
                    if (!activity.startDate) {
                        return d;
                    }

                    const activityDate = activity.startDate.substring(0, 10);
                    const activityDateInD = d.find(item => item.date === activityDate);
                    const distance = getKmByType(activity) / 1000;
                    if (activityDateInD) {
                        activityDateInD.distance += distance;
                    } else {
                        d.push({
                            date: activityDate,
                            distance: distance,
                        });
                    }

                    return d;
                }, [])
                .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                .reduce(
                    (prevDays: { date: string; distance: number }[], entry, index) => [
                        ...prevDays,
                        {
                            ...entry,
                            distance: entry.distance + (index > 0 ? prevDays[index - 1].distance : 0),
                        },
                    ],
                    [],
                ),
        }));
    }

    private monthDiff() {
        let months;
        months =
            (parseInt(this.monthRange[1].substring(0, 4), 10) -
                parseInt(this.monthRange[0].substring(0, 4), 10)) *
            12;
        months -= parseInt(this.monthRange[0].substr(5, 2), 10);
        months += parseInt(this.monthRange[1].substr(5, 2), 10);
        return months <= 0 ? 0 : months + 1;
    }
}
