import { Component, Input } from '@angular/core';
import { AnalyticsService } from '../../core/services/analytics.service';

@Component({
    selector: 'app-particulate-banner',
    templateUrl: './particulate-banner.component.html',
    styleUrls: ['./particulate-banner.component.scss'],
})
export class ParticulateBannerComponent {
    @Input() public headline = '';
    @Input() public text = '';

    public constructor(private analyticsService: AnalyticsService) {}

    public trackEvent() {
        this.analyticsService.trackEvent({
            type: 'EVENT',
            action: 'click_particulate_btn',
            category: 'particulate',
            label: 'Förderprojekte aufrufen',
        });
    }
}
