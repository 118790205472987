import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-process-indicator',
    templateUrl: './process-indicator.component.html',
    styleUrls: ['./process-indicator.component.scss'],
})
export class ProcessIndicatorComponent {
    @Input() public steps: string[];
    @Input() public activeStep: number;
}
