<div class="legal">
    <h1>Datenschutzhinweise gemäß Art. 13 / 14 DSGVO</h1>

    <div class="whitebox whitebox-padding">
        <h3>I. Allgemeine Hinweise und Pflichtangabe zur verantwortlichen Stelle</h3>
        <p>
            Wir, die mediaman GmbH („mediaman“) <br />
            Anschrift: Taunusstr. 57, 55118 Mainz, Deutschland<br />
            Telefon: +49 6131 2120-0<br />
            E-Mail: hallo&#64;mediaman.de<br />
            Registergericht: Amtsgericht Mainz 14, Registernummer: HRB 6152<br />
            Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 185195865<br />
            Kontaktdaten des Datenschutzbeauftragten: Tim Roller, datenschutz&#64;mediaman.de<br />
            sind Betreiber dieser Plattform. Weitere Kontaktmöglichkeiten zu uns findest du im Impressum.<br />
        </p>
        <p>
            Wir behandeln deine personenbezogenen Daten vertraulich entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            <br />
            Wenn du unseren Webauftritt besuchst oder unsere App verwendest, werden von uns verschiedene
            personenbezogene Daten erhoben.

            <br />
            Gemäß Artikel 4 DSGVO sind personenbezogene Daten alle Informationen, die sich auf eine
            identifizierte oder identifizierbare natürliche Person beziehen; als identifizierbar wird eine
            natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer
            Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu
            einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen,
            physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
            dieser natürlichen Person ist.
            <br />
            Wir erheben, speichern und verwenden die auf deine Person bezogenen Daten ausschließlich zum
            Zweck, dir die Angebote bzw. Leistungen unserer Plattform oder unserer App zur Verfügung zu
            stellen und dies nur im dafür notwendigen Umfang, um den Anforderungen der DSGVO in Hinsicht auf
            „Privacy by Default“ und „Privacy by Design“ zu genügen.
        </p>
        <p>Die folgenden Zwecke werden auf unserer Plattform verfolgt:</p>
        <ul>
            <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
            <li>Content Delivery Network (CDN).</li>
            <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
            <li>Feedback (z.B. Sammeln von Feedback via Online-Formular).</li>
            <li>Marketing.</li>
            <li>Kontaktanfragen und Kommunikation.</li>
            <li>Profile mit nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).</li>
            <li>Sicherheitsmaßnahmen.</li>
            <li>Erbringung vertragliche Leistungen und Kundenservice.</li>
            <li>Verwaltung und Beantwortung von Anfragen.</li>
        </ul>

        <h3>II. Pflichtangaben zur Verarbeitung und genaue Beschreibung</h3>
        <h4>A. Pflichtangaben und generelle Beschreibung</h4>
        <h5>1. Art der Daten und Personenkreis</h5>
        <h6>a. Arten der verarbeiteten Daten</h6>
        <ul>
            <li>Meta-/Kommunikationsdaten (z.B. IP-Adressen)</li>
            <li>Bestandsdaten (z.B. Namen, Adressen)</li>
            <li>Kontaktdaten (z.B. E-Mailadressen)</li>
            <li>Inhaltsdaten (z.B. Text- und Zeiteingaben)</li>
        </ul>
        <h6>b. Kategorien der betroffenen Personen</h6>
        <ul>
            <li>Besucher unserer Plattform</li>
        </ul>
        <h5>2. Rechtsgrundlage unserer Datenerhebung</h5>
        <h6>a. Besuch der Plattform ohne Eingabe</h6>
        <p>
            Verschiedene Daten, die zum Teil technische Daten und zum Teil personenbezogenen Daten sind,
            werden automatisch beim Besuch der Plattform ggfls. durch unseren Hoster erfasst. Es handelt vor
            allem technische Daten (z.B. Browsertyp und Browserversion, verwendetes Betriebssystem, Referer
            URL, Hostname des zugreifenden Rechners, Uhrzeit der Serveranfrage, IP-Adresse), allerdings ist
            auch die Verarbeitung der IP-Adresse als personenbezogenes Datum notwendig.
        </p>
        <p>
            Die Erfassung dieser Daten erfolgt automatisch, sobald du unsere Website betrittst und ist
            notwendig, um die Verbindung mit dir herzustellen und um die Erreichbarkeit unserer Webseite zu
            verbessern. Die Erhebung dieser Daten erfolgt auf der Grundlage des berechtigten Interesses (Art.
            6 Abs. 1 f DSGVO) oder hinsichtlich der Erbringung unserer Dienstleistung auf der Homepage (Art. 6
            Abs. 1 b DSGVO).
        </p>
        <p>
            Weiterhin verwenden wir ein Cookie Consent Tool, dessen Erlaubnistatbestand in Art. 6 Abs. 1 c
            DSGVO i.V.m. Art. 5 DSGVO, § 25 TTDSG liegt.
        </p>

        <h6>b. Verwenden des Kontaktformulars</h6>
        <p>
            Personenbezogene Daten werden weiterhin erhoben, wenn du unser Kontaktformular nutzt und dort
            Angaben machst.
        </p>
        <p>
            Diese Datenerhebung erfolgt dann hinsichtlich der Pflichtfelder für die vorvertragliche oder
            vertragliche Kontaktierung (Art. 6 Abs. 1 b DSGVO) und bei den optionalen Feldern aufgrund deiner
            Einwilligung (Art. 6 Abs. 1 a DSGVO). Daten, deren Angabe im Kontaktformular oder an anderer
            Stelle freiwillig ist, sind immer als solche gekennzeichnet.
        </p>
        <p>
            Personenbezogene Daten über der Nutzung des Kontaktformulars (Nutzungsdaten) erheben, verarbeiten
            und nutzen wir ausschließlich, soweit dies erforderlich ist, um dir die Inanspruchnahme des
            Kontaktformulars zu ermöglichen.
        </p>
        <p>
            Nach der Nutzung des Kontaktformulars erheben, verarbeiten und nutzen wir deine in das
            Kontaktformular eingegebenen und dann an unsere Server übermittelten personenbezogenen Daten nur,
            soweit sie für die Begründung, inhaltliche Ausgestaltung oder Änderung unseres Vertrages mit dir
            erforderlich sind (Bestandsdaten).
        </p>
        <p>
            Die Verarbeitung der Bestands- und Nutzungsdaten erfolgt auf Grundlage zur Erfüllung unseres
            Vertrags oder vorvertraglicher Maßnahmen (Art. 6 Abs. 1 b DSGVO).
        </p>

        <h6>c. Nutzung des registrierte-Teilnehmer-Bereichs auf der Webseite</h6>
        <p>
            Für die Nutzung des Bereichs für unsere registrierten Nutzer sind bei der Registrierung
            verschiedene personenbezogene Daten einzugeben.
        </p>
        <p>
            Im Rahmen des Anmeldeprozesses erheben wir bei der Registrierung die Daten zur zukünftigen
            Erbringung unserer Dienstleistung (Art. 6 Abs. 1 l b DSGVO) sowie hinsichtlich der Prüfung, ob es
            sich um einen rechtmäßigen Registrierungsversuch handelt. Dies erfolgt zur Sicherstellung der
            IT-Sicherheit als Grundlage des berechtigten Interesses (Art. 6 Abs. 1 f. DSGVO).
        </p>
        <p>
            Bei der nach der Registrierung möglichen Nutzung des geschützten Bereichs auf unserer Webseite
            erheben wir die Daten zur Erbringung unserer Dienstleistung (Art. 6 Abs. 1 b DSGVO) sowie
            hinsichtlich der erfolgten Login-Versuche zur Sicherstellung der IT-Sicherheit als Grundlage des
            berechtigten Interesses (Art. 6 Abs. 1 f DSGVO).
        </p>

        <h6>d. Newsletter</h6>

        <p>Wir versenden auch Newsletter, um dich über Neuigkeiten zu informieren.</p>
        <p>
            Der Versand erfolgt entweder, wenn du dich für unseren Newsletter angemeldet hast oder wenn du bei
            uns als aktuell registrierter Nutzer ein sogenannter Bestandskunde bist.
        </p>
        <p>
            Der Versand unseres Newsletters erfolgt über die Software HubSpot bei einer Anmeldung im Rahmen
            einer Einwilligung (Art. 6 Abs. 1 lit. a, Art. 7 DSGVO i.V.m § 7 Abs. 2 Nr. 3 UWG) ober bei
            Bestandskunden auf Grundlage der gesetzlichen Erlaubnis (§ 7 Abs. 3 UWG i.V.m. Art. 6 f DSGVO) in
            Verbindung mit unserem berechtigten Interesse. Auf den Versand nach der Anmeldung als
            Bestandskunde weisen wir bereits an dieser Stelle hin. Selbstverständlich kannst du dem Versand
            eines Newsletters jederzeit für die Zukunft widersprechen.
        </p>
        <p>
            Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage der gesetzlichen Anforderungen aus
            § 25 TTDSG (Art. 6 Abs. 1 lit. c DSGVO).
        </p>

        <h6>e. Matomo</h6>
        <p>
            Wir verwenden den Webanalysedienst Matomo, eine Open Source Lösung in der Cookie Variante, die von
            InnoCraft Ltd. (150 Willis St, 6011 Wellington, New Zealand, NZBN 6106769) angeboten wird. Bevor
            wir das Cookie setzen fragen wir nach deiner Einwilligung.
        </p>
        <p>
            Wir nutzen Matomo, mit dem Zweck die Benutzung unserer Website zu analysieren, um unsere Website
            zu optimieren und nutzerfreundlicher gestalten.
        </p>
        <p>
            Die Datenverarbeitung erfolgt auf Grundlage Deiner Einwilligung gem. Art. 6 Abs. 1 lit. a DS-GVO,
            sofern Du Deine Einwilligung über unser Cookie Consent Tool abgegeben hast. Du kannst Deine
            Einwilligung jederzeit widerrufen.
        </p>

        <h6>f. Übermittlung an Dritte</h6>
        <p>
            In der Regel werden personenbezogene Daten, die die Webseitenbesucher oder Nutzer unserer App uns
            übermitteln, ausschließlich durch uns verarbeitet. Zur Erfüllung unserer Aufgaben und Pflichten
            kann es aber erforderlich sein, dass wir die von den Webseitenbesuchern oder Nutzer der App
            gespeicherten personenbezogenen Daten gegenüber natürlichen und juristischen Personen, Behörden,
            Einrichtungen oder anderen Stellen gegenüber offenlegen.
        </p>
        <p>
            Dies sind technische Dienstleister wie IT- Dienstleister, Programmierer, IT-Wartungsunternehmen
            oder Webhoster sein.
        </p>
        <p>
            Wir weisen insbesondere darauf hin, dass wir die Dienste von Fitnesstrackern wie Strava einbinden,
            allerdings wirst du vor der Einbindung gefragt, ob wir den Dienst einbinden sollen.
        </p>
        <p>
            Daneben hat auch der Admin deiner Gruppe Zugriff auf verschiedene Daten. Dies ist notwendig, um
            den Ablauf der Spendensammlung und die Administration der internen Gruppe zu koordinieren.
        </p>
        <p>
            In allen Fällen, in denen wir personenbezogene Daten an Dritte übermitteln, fragen wir – außer
            hinsichtlich des Admins Deiner Gruppe - zuvor selbstverständlich nach deiner Einwilligung (Art. 6
            Abs. 1 a DSGVO) oder haben hinsichtlich unserer Dienstleister einen den Anforderungen des Art. 28
            DSGVO entsprechenden Auftragsdatenverarbeitungsvertrag geschlossen – siehe Nummer 6 der
            Datenschutzerklärung.
        </p>

        <h5>3. Dauer der Speicherung</h5>
        <p>
            Wir werden die personenbezogenen Daten grundsätzlich nur so lange speichern, wie dies zur
            Erfüllung der Zwecke erforderlich ist, für die wir sie erhoben haben. Dazu gehören die Erfüllung
            von vertraglichen, gesetzlichen, buchhalterischen oder Berichtspflichten und, soweit dies uns
            erforderlich ist Rechtsansprüche geltend zu machen oder abzuwehren bis zum Ablauf der
            entsprechenden Aufbewahrungsfrist oder bis die betreffenden Ansprüche beglichen sind.
        </p>

        <h5>4. Betroffenenrechte</h5>
        <p>
            Du hast jederzeit das Recht, unentgeltlich Auskunft über Umfang, Herkunft, Empfänger und Zweck der
            über dich gespeicherten personenbezogenen Daten zu erhalten. Du hast weiter das Recht, die
            Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Weiterhin kannst du bei Angaben,
            die aufgrund deiner Einwilligung erhoben werden, diese Einwilligung jederzeit widerrufen. Du hast
            das Recht auf Datenportabilität. Eine genauere Information zu den einzelnen Rechten findest du
            unten unter B.
        </p>

        <h5>5. Kontakt für Datenschutzanfragen</h5>
        <p>
            Zu allen Fragen zum Datenschutz kannst du dich auch jederzeit an direkt an unseren Support wenden
            unter datenschutz&#64;mediaman.de.
        </p>
        <p>
            Des Weiteren steht dir ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu (Art. 13 Abs. 2
            d DSGVO).
        </p>

        <h5>6. Zusammenarbeit mit Auftragsverarbeitern und Dritten</h5>
        <p>
            Wenn wir Daten gegenüber anderen Personen und/oder Unternehmen z.B. Auftragsverarbeitern oder
            Dritten offenbaren, übermitteln oder sonst Zugriff auf die Daten gewähren, erfolgt dies
            ausschließlich
        </p>
        <ul>
            <li>auf Grundlage gesetzlicher Erlaubnis</li>
            <li>wenn du eingewilligt hast,</li>
            <li>eine rechtliche Verpflichtung dies vorsieht oder</li>
            <li>auf Grundlage unserer berechtigten Interessen.</li>
        </ul>
        <p>
            Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog.
            „Auftragsverarbeitungsvertrages“ oder auch im Rahmen eines „Joint Controllership“ beauftragen,
            erfolgt die Übermittlung beim Auftragsverarbeitungsvertrag auf Grundlage des Art. 28 DSGVO, bei
            einem Joint Controllership gemäß Art. 26 DSGVO.
        </p>

        <h5>7. Schutz der Daten von Besuchern der Webseite</h5>
        <p>
            Wir nutzen Sicherheitsschutzmaßnahmen wie HTTPS sowie besondere Sicherungen unserer Server, um
            deine personenbezogenen Daten zu schützen. Wir prüfen unsere Systeme regelmäßig auf mögliche
            Schwachstellen um zu jedem Zeitpunkt gemäß Art. 32 DSGVO ein dem Risiko der Datenverarbeitung wie
            auch dem Stand der Technik angemessenes Schutzniveau zu gewährleisten.
        </p>

        <h4>B. Genaue Beschreibung</h4>
        <h5>1. Besuch unserer Seite ohne eigene Eingabe</h5>
        <p>
            Verschiedene Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das
            sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
            Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald du unsere Website
            betrittst. Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
            gewährleisten. Andere Daten können zur Analyse deines Nutzerverhaltens verwendet werden. Diese
            Daten führen wir nicht mit auf deine Person bezogenen Daten zusammen. Die Daten bleiben für uns
            technische Daten ohne Personenbezug.
        </p>
        <p>
            Weiterhin verwenden wir ein Cookie-Consent-Tool, dessen Erlaubnistatbestand in Art. 6 Abs. 1 c
            DSGVO i.V.m. Art. 5 DSGVO, § 25 TTDSG liegt. § 25 TTDSG schreibt in Verbindung mit Art. 6 DSGVO
            vor, dass wir Dich nicht nur nach Deiner Einwilligung fragen, sondern Deine Einwilligung auch
            nachweisen müssen (Accountability). Daher speichern wir Deine Entscheidung aufgrund einer
            gesetzlichen Vorgabe.
        </p>

        <h5>2. Daten bei Nutzung des Bereichs für registrierte Nutzer</h5>
        <p>
            Teilnehmer an unseren Programmen, welche sich bei uns registriert haben, bieten wir über unsere
            Homepage die Möglichkeit, unseren geschützten Teilnehmerbereich zu nutzen. Dies erfordert die
            Angabe personenbezogener Daten wie Anzeigename, E-Mail-Adresse und Passwort sowie in der Folge die
            Eingabe, der für das Projekt an dem du teilnimmst notwendigen Daten.
        </p>
        <p>
            Diese servicebezogenen Daten werden von uns erhoben und verarbeitet, um den vereinbarten Service
            zu erbringen und dich so in die Lage zu versetzen, die von uns angebotenen Features zu nutzen.
        </p>
        <p>
            Da diese Daten von deinen eigenen Angaben abhängen, ist die Erstellung einer Liste der Daten für
            uns nicht möglich. Alle Eingaben innerhalb des Loginbereichs erfolgen freiwillig.
        </p>
        <p>
            Um sicher zu stellen, dass der Account nicht von Dritten unrechtmäßig genutzt wird, werden die
            letzten Login-Versuche von uns gespeichert.
        </p>
        <p>
            Aufgrund der Möglichkeit, dass du im Mitgliederbereich auch personenbezogene Daten eingeben
            kannst, bitten wir dich dringend zu beachten, dass du immer selbst dafür sorgst, dass kein Dritter
            Zugriff auf deine Login Daten zum Nutzerbereich bekommen kann. Selbstverständlich werden wir –
            über die im Rahmen der Dienstleistung zu erbringender Datenverarbeitung hinaus – ohne deine
            vorherige Einwilligung oder zur Aufrechterhaltung unseres Dienstes z.B. bei technischen Problemen
            auf die eingegebenen Daten weder Einsicht noch Zugriff nehmen und auf keine wie auch immer
            geartete Weise für uns verwenden.
        </p>

        <h5>3. Daten bei Nutzung des Kontaktformulars</h5>
        <p>
            Besuchern unseres Webauftrittes, die mit unserem Unternehmen Kontakt aufnehmen wollen, bieten wir
            die Möglichkeit unser Kontaktformular zu verwenden. Dies erfordert die Eingabe personenbezogener
            Daten, Vor- und Zuname sowie deiner E-Mailadresse. Diese Eingaben werden nicht veröffentlicht und
            dienen nur der Kommunikation zwischen dir und uns zum Zwecke der Kontaktaufnahme bzw. zum Zwecke
            der weiteren Verarbeitung in Hinsicht auf die Übersendung der gewünschten Informationen.
        </p>

        <h5>4. Newsletter</h5>
        <p>
            Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen mit werblichen
            Informationen (nachfolgend „Newsletter“) nur mit deiner Einwilligung oder aufgrund der Möglichkeit
            der Bestandskundenwerbung oder auch zur Durchführung des Spendenprogramms, d.h. wir stellen dir
            Informationen zur Verfügung, die dich als Nutzer interessieren können oder zur
            Programmdurchführung notwendig sind, mit dem Ziel, dir die bestmögliche Nutzung unserer Angebote
            zu ermöglichen. Die Anmeldung zu unserem Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren.
            Das heißt, dass wir nach Ihrer Anmeldung eine E-Mail an die angegebene E-Mail-Adresse senden, in
            welcher wir um Bestätigung bitten, dass der Versand des Newsletters gewünscht ist. Wenn die
            Anmeldung nicht innerhalb von 24 Stunden bestätigt wird, dann werden die Informationen gesperrt
            und nach einem Monat automatisch gelöscht. Die Anmeldungen zum Newsletter werden protokolliert, um
            den Anmeldeprozess entsprechend der rechtlichen Anforderungen nachweisen zu können.
        </p>
        <p>
            Für den Newsletter Versand verwenden die Dienste des Software-Herstellers HubSpot aus den USA mit
            einer Niederlassung HubSpot European Headquarters, Ground Floor, Two Dockland Central, Guild
            Street, Dublin 1, in Irland. Wir haben mit Hubspot Verträge gemäß den EU-Standardvertragsklauseln
            geschlossen. Hierdurch wird ein angemessenes Schutzniveau der Datenverarbeitungen durch Hubspot
            sichergestellt.
        </p>
        <p>
            Mehr Informationen zu den Datenschutzbestimmungen von HubSpot findest Du unter:
            https://legal.hubspot.com/privacy-policy
        </p>
        <p>
            Weitere Informationen von HubSpot hinsichtlich der EU-Datenschutzbestimmungen findest Du unter:
            https://legal.hubspot.com/security
        </p>
        <p>
            Der Versand unseres Newsletters erfolgt bei einer Anmeldung im Rahmen einer Einwilligung ober bei
            Bestandskunden auf Grundlage der gesetzlichen Erlaubnis in Verbindung mit unserem berechtigten
            Interesse.
        </p>
        <p>
            Auf den Versand nach der Anmeldung als Bestandskunde weisen wir bereits an dieser Stelle hin.
            Selbstverständlich kannst du dem Versand eines Newsletters jederzeit für die Zukunft
            widersprechen.
        </p>

        <h5>5. Matomo</h5>
        <p>
            Wir verwenden den Webanalysedienst Matomo, eine Open Source Lösung in der Cookie Variante, welche
            von der InnoCraft Ltd. (150 Willis St, 6011 Wellington, New Zealand, NZBN 6106769) angeboten wird,
            sofern Du Deine Einwilligung über unser Cookie Consent Tool abgegeben hast. Du kannst Deine
            Einwilligung jederzeit widerrufen.
        </p>
        <p>
            Cookies sind kleine Textdateien, die auf deinem Endgerät gespeichert werden und eine Analyse der
            Benutzung unserer Webseite ermöglichen. Die mittels des Cookies auf Deinem Gerät erzeugten
            Informationen über die Benutzung unserer Website werden anonymisiert auf unserem Server
            gespeichert. Das Cookie selbst verbleibt auf Deinem Rechner, bis zu dem Zeitpunkt am dem Du das
            Cookie löscht. Bevor wir das Cookie setzen fragen wir nach Deiner Einwilligung.
        </p>
        <p>
            Dementsprechend erfolgt das Setzen von Matomo-Cookies auf der Grundlage von Art. 6 Abs. 1 lit. a
            DSGVO im Rahmen Deiner Einwilligung.
        </p>
        <p>
            Das Setzen von Cookies im Webbrowser ist verhinderbar. Einige Funktionen unserer Website könnten
            dadurch jedoch eingeschränkt werden. Eine Weitergabe erfolgt nicht.
        </p>
        <p>
            Es ist möglich unsere Verwendung von Matomo auch nach der Einwilligung zu deaktivieren. Dazu ist
            es notwendig, dass der Browser ein Opt-Out-Cookie setzt, welches unser Nutzen von Matomo bei
            deinem Webseitenbesuch unterbindet.
        </p>
        <p>
            Weitere Informationen über die Funktionsweise vonMatomo, sind über
            https://matomo.org/matomo-cloud-privacy-policy/ abrufbar.
        </p>
        <p>
            <button (click)="optOutTracking()">Matomo deaktivieren (opt-out)</button>
        </p>

        <h5>6.Anfrage per E-Mail, Telefon oder Telefax</h5>
        <p>
            Bei einer Kontaktaufnahme per E-Mail, Telefon oder Telefax, wird die Anfrage inklusive aller
            daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung des
            Anliegens bei uns gespeichert und verarbeitet.
        </p>
        <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern die
            Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
            Maßnahmen erforderlich ist.
        </p>

        <h5>7. Daten bei der Teilnahme an unseren Spendenprogrammen</h5>
        <p>
            Die folgenden personenbezogenen Daten erheben, speichern und verarbeiten wir dann, wenn du an
            einem unserer Spendenprogramme teilnimmst: Anzeigename, E-Mail-Adresse und Passwort sowie in der
            Folge die Eingabe der für die Plattform notwendigen Daten. Durch die Verknüpfung mit deinem
            Nutzer-Account sind diese Daten ebenfalls personenbezogene Daten.
        </p>
        <p>
            Alle innerhalb der Plattform eingegebenen Daten werden von uns ausschließlich erhoben und
            verarbeitet, um unsere Leistungen im Programm zu erbringen und dich so in die Lage zu versetzen,
            auf deinen Wunsch die jeweiligen Daten in das Programm einzubringen und entsprechend der für dich
            notwendigen Nutzungen zu verarbeiten.
        </p>
        <p>
            Da die Daten von deinen eigenen Angaben abhängen, ist die Erstellung einer Liste der Daten für uns
            nicht möglich.
        </p>
        <p>
            Selbstverständlich werden wir – über die im Rahmen der Dienstleistung zu erbringenden
            Datenverarbeitung oder zur Aufrechterhaltung unseres Dienstes z.B. bei technischen Problemen
            hinaus – ohne deine vorherige Einwilligung auf die eingegebenen Daten weder Einsicht noch Zugriff
            nehmen und auf keine wie auch immer geartete Weise für uns verwenden.
        </p>

        <h5>8. Betroffenenrechte</h5>
        <h6>a. Recht auf Auskunft</h6>
        <p>
            Jeder Webseitenbesucher oder Teilnehmer an unseren Spendenprogrammen kann Auskunft gem. Art. 15
            DS-GVO über seine von uns verarbeiteten personenbezogenen Daten verlangen. Im Auskunftsantrag
            sollte das Anliegen präzisiert werden, um uns das Zusammenstellen der erforderlichen Daten zu
            erleichtern.
        </p>
        <h6>b. Recht auf Berichtigung</h6>
        <p>
            Sollten die einen Webseitenbesucher oder Teilnehmer an unseren Spendenprogrammen betreffenden
            Angaben nicht (mehr) zutreffend sein, kann nach Art. 16 DS-GVO eine Berichtigung verlangt werden.
            Sollten die Daten unvollständig sein, so kann eine Vervollständigung verlangt werden.
        </p>
        <h6>c. Recht auf Löschung</h6>
        <p>
            Jeder Webseitenbesucher oder Teilnehmer an unseren Spendenprogrammen kann unter den Bedingungen
            des Art. 17 DSGVO die Löschung der ihn betreffenden personenbezogenen Daten verlangen. Der
            Anspruch auf Löschung hängt u. a. davon ab, ob die den Webseitenbesucher oder Teilnehmer an
            unseren Programmen betreffenden Daten von uns zur Erfüllung unserer gesetzlichen Aufgaben noch
            benötigt werden.
        </p>
        <h6>d. Recht auf Einschränkung der Verarbeitung</h6>
        <p>
            Jeder Webseitenbesucher oder Teilnehmer an unseren Spendenprogrammen hat im Rahmen der Vorgaben
            des Art. 18 DSGVO das Recht, eine Einschränkung der Verarbeitung der den Webseitenbesucher oder
            Teilnehmer an unseren Programmen betreffenden Daten zu verlangen.
        </p>
        <h6>e. Recht auf Widerspruch</h6>
        <p>
            Jeder Webseitenbesucher oder Teilnehmer an unseren Spendenprogrammen hat nach Art. 21 DSGVO
            jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, der
            Verarbeitung der ihn betreffenden Daten zu widersprechen.
        </p>
        <h6>f. Recht auf Datenportabilität</h6>
        <p>
            Jeder Webseitenbesucher oder Teilnehmer an unseren Spendenprogrammen hat gemäß Art. 20 DSGVO das
            Recht, Daten, die wir auf Grundlage seiner Einwilligung oder in Erfüllung eines Vertrags
            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren
            Format aushändigen zu lassen. Sofern die direkte Übertragung der Daten an einen anderen
            Verantwortlichen verlangt wird, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <h6>g. Recht auf Beschwerde</h6>
        <p>
            Wenn ein Webseitenbesucher oder Teilnehmer an unseren Programmen der Auffassung ist, dass wir bei
            der Verarbeitung seiner personenbezogenen Daten datenschutzrechtliche Vorschriften nicht beachtet
            haben, kann sich der Webseitenbesucher oder Teilnehmer an unseren Programmen mit einer Beschwerde
            an die für uns zuständige Datenschutzaufsichtsbehörde wenden. Dies ist der hessische Beauftragte
            für Datenschutz und Informationsfreiheit, Gustav-Stresemann-Ring 1, 65189 Wiesbaden.
        </p>

        <h3>III. Änderung der Datenschutzrichtlinie</h3>
        <p>
            Unsere Dienste auf unserem Webauftritt wie auch unserer Programme sind dynamisch und wir führen
            von Zeit zu Zeit neue Funktionen oder neue Formen der Programmgestaltung ein. Daher kann es sein,
            dass neue Informationen erfasst werden müssen. Wenn wir neue persönliche Daten erfassen oder
            wesentlich ändern, wie wir deine Daten verwenden, benachrichtigen wir dich und modifizieren –
            sofern notwendig – auch diese Datenschutzrichtlinie.
        </p>
    </div>
</div>
