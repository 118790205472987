<div class="stats-monthly">
    <h4>{{ 'TileMonthTeamStats.Headline' | translate }}</h4>
    <header>
        <button (click)="prevMonth()" [disabled]="selectedMonth === challengeStartMonth">
            <svg width="11" height="22" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M11 .718v21l-11-10.5z" fill="#D8D8D8" />
                </g>
            </svg>
        </button>
        <h2>{{ getSelectedMonthName() | translate }} {{ date | date: 'yyyy' }}</h2>
        <button (click)="nextMonth()" [disabled]="selectedMonth === challengeEndMonth">
            <svg width="11" height="22" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 .718v21l11-10.5z" fill="#D8D8D8" />
                </g>
            </svg>
        </button>
    </header>

    <section>
        <div class="calendar">
            <div class="header calendar-row">
                <span class="day" *ngFor="let day of [0, 1, 2, 3, 4, 5, 6]">
                    {{ getDayTranslation(day) | translate }}
                </span>
            </div>
            <div class="body" *ngIf="club.activities">
                <div class="row calendar-row" *ngFor="let week of weeks">
                    <ng-container *ngFor="let day of week">
                        <div [ngClass]="{ 'not-month': !day.isInMonth, 'not-challenge': !day.isInChallenge }">
                            <div class="graph" *ngIf="day.isInMonth">
                                <svg viewBox="0 0 36 36" width="90" class="circular-chart">
                                    <ng-container *ngIf="!day.isFuture && day.totalActivities > 0">
                                        <path
                                            *ngIf="day.teams[0]"
                                            class="circle-bg"
                                            [attr.stroke]="day.teams[0]?.color"
                                            d="M18 2.0845
                                              a 15.9155 15.9155 0 0 1 0 31.831
                                              a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                        <ng-container
                                            *ngFor="let team of day.teams | slice: 1; let index = index"
                                        >
                                            <path
                                                class="circle"
                                                [attr.stroke]="team.color"
                                                [attr.stroke-dasharray]="[
                                                    getDasharrayForTeam(day, index),
                                                    '100'
                                                ]"
                                                d="M18 2.0845
                                              a 15.9155 15.9155 0 0 1 0 31.831
                                              a 15.9155 15.9155 0 0 1 0 -31.831"
                                            />
                                        </ng-container>
                                    </ng-container>
                                    <circle
                                        id="Oval"
                                        cx="18"
                                        cy="18"
                                        r="8"
                                        [attr.fill]="day.teams[0]?.color"
                                    ></circle>
                                </svg>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="stats">
            <app-stats-facts3
                [value]="totalDistanceCurrentMonth | distance"
                [label]="'Common.TotalDistance' | translate"
                unit="KM"
            ></app-stats-facts3>
            <div class="stats__per-team" *ngIf="teams.length <= 3">
                <ng-container *ngFor="let team of teams">
                    <span [ngStyle]="{ backgroundColor: team.color }" class="team__color"></span>
                    <span class="team__distance">
                        {{ team.monthDistance | distance | number: '1.0-2' }}
                    </span>
                    <small>KM</small>
                </ng-container>
            </div>
            <app-stats-facts3
                [value]="totalAmountOfActivitiesForMonth"
                [label]="'Common.Activities' | translate"
            ></app-stats-facts3>
        </div>
    </section>
</div>
