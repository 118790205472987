import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { UserFE } from '../../core/interfaces/user.interface';
import { GuidedTourService } from '../../core/services/guided-tour.service';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Glide from '@glidejs/glide';

@Component({
    selector: 'app-guided-tour',
    templateUrl: './guided-tour.component.html',
    styleUrls: ['./guided-tour.component.scss'],
})
export class GuidedTourComponent implements OnInit {
    public user: UserFE;
    public slides: any[];
    public tourIsVisible: boolean;
    public unseenTourVersion: number;

    public glider: any;

    private sliderOptions = {
        type: 'slider',
        perView: 1,
        rewind: false,
    };

    public constructor(
        private userService: UserService,
        private guidedTourService: GuidedTourService,
    ) {}

    public ngOnInit() {
        this.userService.user.subscribe(result => {
            if (result) {
                this.user = result;
                this.openUnseenTour();
            }
        });
    }

    public openUnseenTour() {
        this.unseenTourVersion = this.guidedTourService.getUnseenTourVersion(
            this.user.lastSeenGuidedTour ?? 0,
        );

        if (this.unseenTourVersion) {
            // build slides
            this.guidedTourService.buildSlidesForTourVersion(this.unseenTourVersion).subscribe({
                next: (result: any[]) => {
                    this.slides = result;
                    const sliderOptions = this.sliderOptions;

                    // Glide needs minimal delay to work properly
                    setTimeout(() => {
                        this.glider = new Glide('.glide', sliderOptions).mount();
                    }, 100);
                    this.tourIsVisible = true;
                    this.guidedTourService.updateTourIsOpen(true);
                },
            });
        }
    }

    public updateTour($event: boolean) {
        this.tourIsVisible = $event;
        this.guidedTourService.updateTourIsOpen($event);
        this.userService.updateGuidedTourForUser(this.unseenTourVersion).subscribe(() => null);
    }
}
