import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    ViewChild,
} from '@angular/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Glide from '@glidejs/glide';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements AfterViewInit {
    @Input() public slidesS = 1;
    @Input() public slidesM = 2;
    @Input() public slidesL = 2;
    @Input() public init = true;
    @Input() public showArrows = false;

    public glider: any;
    public children: any[];
    public childrenLength: number;
    @ViewChild('slides', { static: true }) private slides: ElementRef;
    private sliderOptions = {
        type: 'slider',
        perView: 1,
        rewind: false,
        bound: true,
    };

    public constructor(
        @Inject('window') private window: Window,
        private element: ElementRef,
        private readonly changeDetector: ChangeDetectorRef,
    ) {}

    public ngAfterViewInit() {
        this.children = Array.from(this.slides.nativeElement.children);
        this.childrenLength = this.slides.nativeElement.children.length;
        this.initGlider();
        this.changeDetector.detectChanges();
    }

    public getAmountOfSliderItems(): number {
        if (this.window.innerWidth < 480) {
            return this.slidesS;
        } else if (this.window.innerWidth < 1024) {
            return this.slidesM;
        } else {
            return this.slidesL;
        }
    }

    public goToSlide(index: number): void {
        this.glider.go('=' + index);
    }

    public getDots() {
        return this.childrenLength
            ? Array.from(Array(this.childrenLength - this.getAmountOfSliderItems() + 1).keys())
            : [];
    }

    private initGlider() {
        const sliderOptions = {
            ...this.sliderOptions,
            rewind: this.showArrows, // if arrows are shown, rewind should be true
            perView: this.slidesL,
            breakpoints: {
                480: {
                    perView: this.slidesS,
                },
                1024: {
                    perView: this.slidesM,
                },
            },
        };

        this.glider = new Glide(this.element.nativeElement.querySelector('.glide'), sliderOptions);

        this.glider.on('resize', () => {
            this.glider.update({
                perView: this.getAmountOfSliderItems(),
            });
            this.goToSlide(0);
        });

        this.glider.mount();
    }
}
