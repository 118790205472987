import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { Auth } from '@aws-amplify/auth';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class JwtInterceptorService implements HttpInterceptor {
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(Auth.currentSession()).pipe(
            catchError(() => of(false)),
            switchMap((auth: any) => {
                if (!auth) {
                    return next.handle(request);
                }
                const jwt = auth.accessToken.jwtToken;
                const withAuthRequest = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
                return next.handle(withAuthRequest);
            }),
        );
    }
}
