<div class="guided-tour-slide">
    <img [src]="slide.image" alt="" />

    <div class="guided-tour-slide__content">
        <h3>{{ slide.headline }}</h3>
        <p [innerHTML]="slide.description"></p>
        <div class="guided-tour-slide__btn">
            <button class="button" *ngIf="isLast" (click)="close()">
                {{ 'GuidedTour.EndTour' | translate }}
            </button>
        </div>
    </div>
</div>
