import { Component, Inject, OnInit } from '@angular/core';
import { NotificationService } from './core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from './core/services/date.service';
import { AnalyticsService } from './core/services/analytics.service';
import { UserService } from './core/services/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public showCookieBanner = this.environment.production;

    public constructor(
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private dateService: DateService,
        private analyticsService: AnalyticsService,
        private userService: UserService,
        @Inject('env') private readonly environment: any,
        @Inject('storage') private readonly storage: any,
    ) {
        this.notificationService.lang$.subscribe((lang: string) => {
            this.translateService.use(lang);
            this.dateService.updateDateTranslations();
        });
    }

    public ngOnInit() {
        const consentIsGiven = this.storage.getItem('RR_CONSENT_GIVEN');
        if (consentIsGiven === '1') {
            this.showCookieBanner = false;
            this.acceptCookies();
        }

        this.analyticsService.trackingEnabled$.subscribe(result => {
            if (!consentIsGiven) {
                this.showCookieBanner = !result;
            }
        });
    }

    public acceptCookies() {
        this.showCookieBanner = false;

        this.analyticsService.startTracking();
        this.analyticsService.trackingEnabled$.next(true);
    }

    public hideNote() {
        this.showCookieBanner = false;
    }
}
