<div class="heroes">
    <div class="header">
        <ng-content></ng-content>
    </div>
    <ng-container *ngFor="let hero of heroUsers; let index = index">
        <div class="hero" [ngClass]="{ 'my-entry': userId === hero.userId }" *ngIf="showAll || index < 5">
            <p>{{ index + 1 }}</p>
            <p class="hero__name">{{ hero.name }}</p>
            <p *ngIf="sortKey === 'distance'">{{ hero.distance / 1000 | number: '1.2-2' }} km</p>
            <p *ngIf="sortKey === 'time'">
                {{ hero.time | time: 'short' }} {{ 'Common.HourAbbr' | translate }}
            </p>
        </div>
    </ng-container>
    <button
        (click)="toggleShowAll()"
        type="button"
        [ngClass]="{ 'rotate-arrow': showAll }"
        class="button button--text expand-button"
    >
        <svg viewBox="0 0 24 14" xmlns="http://www.w3.org/2000/svg">
            <path
                stroke="#000"
                d="m.5.5 11.5 13L23.5.5"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        {{ showAll ? 'weniger anzeigen' : 'alle anzeigen' }}
    </button>
</div>
