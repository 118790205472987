import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { ActivityFilterComponent } from './activity-filter/activity-filter.component';
import { ClubIntroComponent } from './club-intro/club-intro.component';
import { DonutComponent } from './donut/donut.component';
import { GlobalGoalComponent } from './global-goal/global-goal.component';
import { GuidedTourSlideComponent } from './guided-tour/guided-tour-slide/guided-tour-slide.component';
import { GuidedTourComponent } from './guided-tour/guided-tour.component';
import { HeroesComponent } from './heroes/heroes.component';
import { InvitationBannerComponent } from './invitation-banner/invitation-banner.component';
import { LegendComponent } from './legend/legend.component';
import { LoaderComponent } from './loader/loader.component';
import { ModalComponent } from './modal/modal.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ProcessIndicatorComponent } from './process-indicator/process-indicator.component';
import { SlideComponent } from './slider/slide/slide.component';
import { SliderComponent } from './slider/slider.component';
import { SocialFooterComponent } from './social-footer/social-footer.component';
import { StatsCategoryFactComponent } from './stats-category-fact/stats-category-fact.component';
import { StatsDistanceGraph1Component } from './stats-distance-graph1/stats-distance-graph1.component';
import { StatsFacts3Component } from './stats-facts3/stats-facts3.component';
import { StatsFacts4Component } from './stats-facts4/stats-facts4.component';
import { StatsMonthKmComponent } from './stats-month-km/stats-month-km.component';
import { StatsPulseComponent } from './stats-pulse/stats-pulse.component';
import { RankingRowComponent } from './stats-ranking/ranking-row/ranking-row.component';
import { StatsRankingComponent } from './stats-ranking/stats-ranking.component';
import { TileCalendarComponent } from './tile-calendar/tile-calendar.component';
import { TileFactsComponent } from './tile-facts/tile-facts.component';
import { TileMonthTeamStatsComponent } from './tile-month-team-stats/tile-month-team-stats.component';
import { StackedChartComponent } from './tile-teams/stacked-chart/stacked-chart.component';
import { TileTeamsComponent } from './tile-teams/tile-teams.component';
import { TileWeeklyTeamStatisticsComponent } from './tile-weekly-team-statistics/tile-weekly-team-statistics.component';
import { TotalFactsComponent } from './total-facts/total-facts.component';
import { ParticulateBannerComponent } from './particulate-banner/particulate-banner.component';
import { AllTimeTotalsComponent } from './all-time-totals/all-time-totals.component';
import { HowToExplanationComponent } from './how-to-explanation/how-to-explanation.component';
import { JoinTeaserComponent } from './join-teaser/join-teaser.component';
import { GlobalFaqComponent } from './global-faq/global-faq.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { TenantMenuComponent } from './menu/tenant-menu/tenant-menu.component';
import { SaasMenuComponent } from './menu/saas-menu/saas-menu.component';
import { FooterComponent } from './footer/footer.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { IconComponent } from './icon/icon.component';

@NgModule({
    declarations: [
        TileFactsComponent,
        TileCalendarComponent,
        TotalFactsComponent,
        StatsFacts3Component,
        StatsCategoryFactComponent,
        StatsPulseComponent,
        StatsMonthKmComponent,
        StatsRankingComponent,
        RankingRowComponent,
        GlobalGoalComponent,
        GuidedTourComponent,
        GuidedTourSlideComponent,
        ModalComponent,
        LoaderComponent,
        ProcessIndicatorComponent,
        StatsDistanceGraph1Component,
        StatsFacts4Component,
        ClubIntroComponent,
        HeroesComponent,
        SocialFooterComponent,
        DonutComponent,
        InvitationBannerComponent,
        TileTeamsComponent,
        StackedChartComponent,
        LegendComponent,
        SliderComponent,
        SlideComponent,
        NavigationComponent,
        ActivityFilterComponent,
        TileWeeklyTeamStatisticsComponent,
        TileMonthTeamStatsComponent,
        ParticulateBannerComponent,
        AllTimeTotalsComponent,
        HowToExplanationComponent,
        JoinTeaserComponent,
        GlobalFaqComponent,
        HeaderComponent,
        TenantMenuComponent,
        SaasMenuComponent,
        MenuComponent,
        FooterComponent,
        TestimonialComponent,
        IconComponent,
    ],
    exports: [
        TileFactsComponent,
        TileCalendarComponent,
        TotalFactsComponent,
        StatsFacts3Component,
        StatsCategoryFactComponent,
        StatsPulseComponent,
        StatsMonthKmComponent,
        StatsRankingComponent,
        RankingRowComponent,
        GlobalGoalComponent,
        GuidedTourComponent,
        ModalComponent,
        LoaderComponent,
        ProcessIndicatorComponent,
        StatsDistanceGraph1Component,
        StatsFacts4Component,
        ClubIntroComponent,
        HeroesComponent,
        SocialFooterComponent,
        DonutComponent,
        InvitationBannerComponent,
        TileTeamsComponent,
        LegendComponent,
        SliderComponent,
        SlideComponent,
        NavigationComponent,
        ActivityFilterComponent,
        TileWeeklyTeamStatisticsComponent,
        TileMonthTeamStatsComponent,
        ParticulateBannerComponent,
        AllTimeTotalsComponent,
        HowToExplanationComponent,
        JoinTeaserComponent,
        GlobalFaqComponent,
        HeaderComponent,
        TenantMenuComponent,
        SaasMenuComponent,
        FooterComponent,
        TestimonialComponent,
        IconComponent,
    ],
    imports: [CommonModule, CoreModule, RouterModule],
})
export class SharedModule {}
