<div class="ranking-row">
    <div class="ranking__name">
        <a [routerLink]="getClubLink(club.id)">{{ club.name }}</a>
        <div class="ranking__bar">
            <div class="bar" [ngStyle]="{ 'width.%': getWidthPercentage() || 0 }"></div>
        </div>
    </div>
    <div class="ranking__item ranking__total">
        {{ club.totalKm / 1000 | number: '1.2-2' }} km
        <span>{{ 'StatsRanking.Distance' | translate }}</span>
    </div>
    <div class="ranking__item ranking__team">
        {{ club.userCount }}
        <span>{{ 'StatsRanking.Teamsize' | translate }}</span>
    </div>
    <div class="ranking__item ranking__index">
        {{ club.totalKm / 1000 / club.userCount || 0 | number: '1.2-2' }}
        <span>{{ 'StatsRanking.ActivityIndex' | translate }}</span>
    </div>
    <div class="ranking__item ranking__donation">
        {{ club.totalDonation | number: '1.2-2' }} EUR
        <span>{{ 'StatsRanking.DonationAmount' | translate }}</span>
    </div>
</div>
