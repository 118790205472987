import { Component } from '@angular/core';
import { MenuComponent } from '../menu.component';
import { NotificationService } from '../../../core/services/notification.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-saas-menu',
    templateUrl: './saas-menu.component.html',
    styleUrls: ['./saas-menu.component.scss'],
})
export class SaasMenuComponent extends MenuComponent {
    public constructor(notificationService: NotificationService, router: Router) {
        super(notificationService, router);
    }
}
